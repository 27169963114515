/**
 *
 * PeriodSwitch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Assets
import { ReactComponent as BackArrow } from '../../images/shared/back-arrow.svg';
import { ReactComponent as NextArrow } from '../../images/shared/next-arrow.svg';

// Constants
import * as Colors from 'constants/colors';

// Style
const Wrapper = styled.div`
  width: 196px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Period = styled.span`
  text-align: center;
  user-select: none;
  font-weight: bold;
  font-size: 14.2px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${Colors.LIGHT_NAVY};
`;

const BackArrowIcon = styled(BackArrow)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: ${props => (props.disabled ? Colors.GREY : Colors.LIGHT_NAVY)};
`;

const NextArrowIcon = styled(NextArrow)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: ${props => (props.disabled ? Colors.GREY : Colors.LIGHT_NAVY)};
`;

function PeriodSwitch({ periods, selected, onChange }) {
  const backDisabled = selected === 0;
  const nextDisabled = selected === periods.length - 1;
  return (
    <Wrapper>
      <BackArrowIcon
        data-testid="back-arrow"
        disabled={backDisabled}
        onClick={() => !backDisabled && onChange(selected - 1)}
      />
      <Period data-testid="period">{periods[selected].label}</Period>
      <NextArrowIcon
        data-testid="next-arrow"
        disabled={nextDisabled}
        onClick={() => !nextDisabled && onChange(selected + 1)}
      />
    </Wrapper>
  );
}

PeriodSwitch.propTypes = {
  periods: PropTypes.array,
  selected: PropTypes.number,
  onChange: PropTypes.func,
};

PeriodSwitch.defaultProps = {
  selected: 0,
};

export default PeriodSwitch;
