import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import SubsectionTitle from 'components/SubsectionTitle';
import { ClipLoader } from 'react-spinners';
import Table from 'components/Table';
import Error from 'components/Error';

// Columns
import {
  emotionsTableColumns,
  contextTableColumns,
  activityTableColumns,
  unitTableColumns,
} from '../columns';

// Translations
import { injectIntl } from 'react-intl';
import messages from '../messages';

// APIs
import {
  fetchActivityTable,
  fetchContextTable,
  fetchEmotionTable,
  fetchUnitTable,
} from '../api';

// Constants
import periods from 'utils/periodGenerator';
import * as Colors from 'constants/colors';
import { ACTIVITY, CONTEXT, EMOTION_T0, EMOTION_T1, UNIT } from './tableTypes';
import * as Margins from 'constants/margins';

// Images
import { ReactComponent as Mood } from 'images/sections/mood.svg';
import { ReactComponent as MainMood } from 'images/sections/mainMood.svg';
import { ReactComponent as Context } from 'images/sections/context.svg';
import { ReactComponent as Activity } from 'images/sections/activity.svg';
import { ReactComponent as Unit } from 'images/sections/unit.svg';

// Style
const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 50px;
  margin-left: ${Margins.XXLARGE}px;
  display: flex;
  justify-content: center;
`;

const icon = css`
  width: 50px;
  height: 50px;
`;

const MoodIcon = styled(Mood)`
  ${icon};
`;

const MainMoodIcon = styled(MainMood)`
  ${icon};
`;

const ContextIcon = styled(Context)`
  ${icon};
`;

const ActivityIcon = styled(Activity)`
  ${icon};
`;

const UnitIcon = styled(Unit)`
  ${icon};
`;

const retrieveFetchByType = type => {
  switch (type) {
    case ACTIVITY:
      return fetchActivityTable;

    case CONTEXT:
      return fetchContextTable;

    case EMOTION_T0:
      return fetchEmotionTable;

    case EMOTION_T1:
      return fetchEmotionTable;

    case UNIT:
      return fetchUnitTable;

    default:
      return undefined;
  }
};

const retrieveMessagesByType = type => {
  switch (type) {
    case ACTIVITY:
      return {
        title: messages.activityTableTitle,
        subtitle: messages.activityTableSubtitle,
      };

    case CONTEXT:
      return {
        title: messages.contextTableTitle,
        subtitle: messages.contextTableSubtitle,
      };

    case EMOTION_T0:
      return {
        title: messages.emotionZeroTableTitle,
        subtitle: messages.emotionZeroTableSubtitle,
      };

    case EMOTION_T1:
      return {
        title: messages.emotionOneTableTitle,
        subtitle: messages.emotionOneTableSubtitle,
      };

    case UNIT:
      return {
        title: messages.unitTableTitle,
        subtitle: messages.unitTableSubtitle,
      };

    default:
      return {};
  }
};

const retrieveIconByType = type => {
  switch (type) {
    case ACTIVITY:
      return <ActivityIcon />;

    case CONTEXT:
      return <ContextIcon />;

    case EMOTION_T0:
      return <MainMoodIcon />;

    case EMOTION_T1:
      return <MoodIcon />;

    case UNIT:
      return <UnitIcon />;

    default:
      return {};
  }
};

function DynamicTable({ type, intl }) {
  const {
    unitId,
    period,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  } = useContext(AuthContext);

  const { start, end } = periods[period];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const getColumns = () => {
    switch (type) {
      case ACTIVITY:
        return activityTableColumns.map(col => {
          if (col.key === 'benchmark') {
            return {
              ...col,
              visible:
                !!benchmarkOrganizationUnitId ||
                !!benchmarkStart ||
                !!benchmarkEnd,
            };
          }
          return col;
        });

      case CONTEXT:
        return contextTableColumns.map(col => {
          if (col.key === 'benchmark') {
            return {
              ...col,
              visible:
                !!benchmarkOrganizationUnitId ||
                !!benchmarkStart ||
                !!benchmarkEnd,
            };
          }
          return col;
        });

      case EMOTION_T0:
        return emotionsTableColumns.map(col => {
          if (col.key === 'benchmark') {
            return {
              ...col,
              visible:
                !!benchmarkOrganizationUnitId ||
                !!benchmarkStart ||
                !!benchmarkEnd,
            };
          }
          return col;
        });

      case EMOTION_T1:
        return emotionsTableColumns.map(col => {
          if (col.key === 'benchmark') {
            return {
              ...col,
              visible:
                !!benchmarkOrganizationUnitId ||
                !!benchmarkStart ||
                !!benchmarkEnd,
            };
          }
          return col;
        });

      case UNIT:
        return unitTableColumns;

      default:
        return [];
    }
  };

  useEffect(() => {
    let canceled = false;
    const fetchData = async () => {
      setLoading(true);
      setData([]);
      try {
        const response = await retrieveFetchByType(type)(
          unitId,
          start,
          end,
          benchmarkOrganizationUnitId,
          benchmarkStart,
          benchmarkEnd,
        );
        const json = await response.json();
        if (!canceled) {
          setLoading(false);
          console.log('mood json', json);
          setData(json);
        }
      } catch {
        if (!canceled) {
          setLoading(false);
          setError(true);
        }
      }
    };

    fetchData();

    return () => {
      canceled = true;
    };
  }, [
    unitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  ]);

  const renderLoading = () => {
    if (loading) {
      return <ClipLoader size={20} color={Colors.LIGHT_NAVY} />;
    }
    return null;
  };

  const getDataFromEmotionType = () =>
    type === EMOTION_T0
      ? data.filter(d => d.type === 0)
      : data.filter(d => d.type === 1);

  const renderTable = () => {
    if (!loading && !error && data) {
      return (
        <Table
          columns={getColumns()}
          data={
            type !== EMOTION_T0 && type !== EMOTION_T1
              ? data
              : getDataFromEmotionType()
          }
          borders
        />
      );
    }
    return null;
  };

  const renderError = () => {
    if (!loading && error) {
      return <Error />;
    }
    return null;
  };

  const typedMessages = retrieveMessagesByType(type);

  return (
    <Wrapper style={type === UNIT ? { backgroundColor: 'grey' } : {}}>
      <SubsectionTitle
        icon={retrieveIconByType(type)}
        title={intl.formatMessage(typedMessages.title)}
        body={intl.formatMessage(typedMessages.subtitle)}
      />

      <Content>
        {renderLoading()}
        {renderTable()}
        {renderError()}
      </Content>
    </Wrapper>
  );
}

DynamicTable.propTypes = {
  type: PropTypes.oneOf([ACTIVITY, CONTEXT, EMOTION_T0, EMOTION_T1, UNIT]),
  intl: PropTypes.object,
};

export default injectIntl(DynamicTable);
