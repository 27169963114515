/**
 *
 * Input
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.div``;

const Label = styled.div`
  margin-bottom: ${Margins.SMALL}px;
  font-weight: bold;
  font-size: 12.2px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${Colors.BLACK};
`;

const InputWrapper = styled.div`
  border-radius: ${Margins.REGULAR}px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: white;
  ${props =>
    !props.noBorders &&
    `
      border: 1px solid ${props.error ? 'red' : Colors.LIGHT_GREY_BLUE};
    `};
`;

const InputText = styled.input`
  width: 100%;
  border: none;
  padding: ${Margins.REGULAR}px;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: ${props => (props.error ? 'red' : Colors.BLACK)};
  outline: none;
  cursor: ${props => (props.readOnly ? 'pointer' : 'auto')};
`;

const IconWrapper = styled.div`
  margin: ${Margins.REGULAR}px ${Margins.REGULAR}px ${Margins.REGULAR}px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Input({ className, label, icon, noBorders, ...props }) {
  return (
    <Wrapper className={className} data-testid="input-wrapper">
      {label && <Label data-testid="label">{label}</Label>}
      <InputWrapper
        error={props.error}
        noBorders={noBorders}
        data-testid="input-text-wrapper"
      >
        <InputText {...props} data-testid="input-text" />
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </InputWrapper>
    </Wrapper>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.object,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  noBorders: PropTypes.bool,
};

export default Input;
