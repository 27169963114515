import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Translations
import { injectIntl } from 'react-intl';
import messages from './messages';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

import { ReactComponent as Tick } from '../../images/shared/tick.svg';
import * as Paths from 'constants/paths';
import { useHistory } from 'react-router-dom';
import { getUsers } from 'utils/storage';

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PrimaryText = styled.p`
  width: 100%;
  font-size: 24.38px;
  text-align: left;
`;

const SecondaryText = styled.a`
  margin-top: 20px;
  font-size: 16px;
  text-align: left;
  color: #cdcdcd;
`;

const TickIcon = styled(Tick)`
  margin-top: 10px;
  width: 60px;
`;

const NextButton = styled.div`
  margin-top: 20px;
  align-self: center;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  ${props => !props.disabled && 'cursor: pointer;'}
  padding: ${Margins.MEDIUM}px;
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.BATTLESHIP_GREY};
  border-radius: 12px;
  margin-top: 20px;
  border: 1px solid ${Colors.BATTLESHIP_GREY};

  ${props =>
    !props.disabled
      ? `
  &:hover {
    border: 1px solid white;
    background: linear-gradient(
      120deg,
      ${Colors.PURPLE} 1%,
      ${Colors.CYAN} 90%
    );
    color: #ffffff;
  }
  `
      : ''}
`;

const ImportSuccessfully = ({ intl }) => {
  const history = useHistory();

  const invitedUsers = getUsers();

  const nextButtonClick = () => {
    history.push(Paths.ACCOUNTS);
  };
  return (
    <div
      style={{
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <Container>
        <PrimaryText>{intl.formatMessage(messages.title)}</PrimaryText>
        <PrimaryText>{intl.formatMessage(messages.subtitle)}</PrimaryText>
        <PrimaryText style={{ textDecoration: 'underline', fontSize: 16 }}>
          {intl.formatMessage(messages.data)}
        </PrimaryText>
        <SecondaryText>
          {invitedUsers.filter(i => i.isValid).length}
          {intl.formatMessage(messages.invitedOn)}
          {invitedUsers.length}
          {intl.formatMessage(messages.uploaded)}
        </SecondaryText>
      </Container>
      <TickIcon />

      <NextButton onClick={() => nextButtonClick()}>
        {intl.formatMessage(messages.nextButton)}
      </NextButton>
    </div>
  );
};

ImportSuccessfully.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(ImportSuccessfully);
