export const TEXT = 1; // order by label property
export const GROUP = 2; // order by count property
export const TREND = 3; // order by trend property
export const DISPERSION = 4; // order by dispersion property
export const BOOLEAN_BAR = 5; // order by value property
export const ENERGY_BAR = 6; // order by partial property
export const PROGRESS_BAR = 7; // order by value of values[0] property
export const BENCHMARK = 8; // order by value property
export const LEGEND = 9;
export const ACTION = 10;
export const CATEGORY = 11;

export const USER = 100; // order by count
export const SELECT = 101;
