export const format = number => {
  const formatted = number.toString().replace('.', ',');
  return formatted.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const truncate = num => {
  if (num % 1 === 0) {
    return num;
  }

  if ((num * 10) % 1 === 0) {
    return num.toFixed(1);
  }

  return num.toFixed(2);
};
