/**
 *
 * Table
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortid from 'shortid';

// Components
import Cell from './Cell';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';
import * as Columns from './columns';

import checkboxThemeShape from 'components/Checkbox/checkboxTheme';

// Assets
import { ReactComponent as SortIco } from 'images/table/sort.svg';
import { ReactComponent as SortIcoAsc } from 'images/table/sort-asc.svg';
import { ReactComponent as SortIcoDesc } from 'images/table/sort-desc.svg';

// Utils
import { getSortedByType } from './tableUtils/sort';

// Style
const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  height: 32px;
  border-bottom: 1px solid ${Colors.GREY};
`;

const Column = styled.div`
  padding: ${Margins.REGULAR}px;
  display: flex;
  align-items: center;
  ${props => !props.overflow && 'overflow: hidden'};
  font-weight: bold;
  font-size: 12.2px;
  line-height: ${Margins.LARGE}px;
  letter-spacing: 0.4px;
  color: ${Colors.BATTLESHIP_GREY};
  flex: ${props => (props.span > 0 ? props.span : '0 1 auto')};
  cursor: pointer;
  margin-right: 16px;

  .header-label {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .icon {
    width: 18px;
    height: 18px;
    opacity: 0;
    margin-left: 2px;
    transition: all 0.2s;
  }

  &:hover {
    .icon {
      opacity: 1;
    }
  }
`;

const Body = styled.div``;

const Row = styled.div`
  height: 52px;
  display: flex;
  border-bottom: ${props =>
    props.borders ? `1px solid ${Colors.GREY}` : 'none'};

  :hover {
    background-color: ${Colors.LIGHT_NAVY_10};
  }
`;

const ShowMore = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: ${Margins.MEDIUM}px;
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.BATTLESHIP_GREY};
  border-radius: 12px;
  margin-top: 20px;
  border: 1px solid ${Colors.BATTLESHIP_GREY};

  &:hover {
    border: 1px solid white;
    background: linear-gradient(
      120deg,
      ${Colors.PURPLE} 1%,
      ${Colors.CYAN} 90%
    );
    color: #ffffff;
  }
`;

const ASC = 'asc';
const DESC = 'desc';

function Table({
  columns,
  data,
  className,
  borders,
  checkboxTheme,
  onSelect,
  selectStatus,
  headerActions,
  limit,
  showHeader,
  paginated,
}) {
  // State
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [expandedId, setExpandedId] = useState(undefined);
  const [sortedColumn, setSortedColumn] = useState(undefined);
  const [tableData, setTableData] = useState([]);

  const CHART_COLORS = [
    Colors.DONUTS_50,
    Colors.DONUTS_30_49,
    Colors.DONUTS_10_29,
    Colors.DONUTS_10,
    Colors.DONUTS_0,
  ];

  useEffect(() => {
    const sortedTableData = [...tableData];
    const result = getSortedByType(
      sortedTableData,
      sortedColumn && sortedColumn.type,
      sortedColumn && sortedColumn.value,
      sortedColumn && sortedColumn.key,
    );
    console.log('set Table data', result);
    setTableData(result);
  }, [sortedColumn]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const renderLabelHeaderCell = (label, index) => (
    <>
      <span className="header-label">{label}</span>
      {!sortedColumn && <SortIco className="icon" />}
      {sortedColumn && sortedColumn.key !== index && (
        <SortIco className="icon" />
      )}
      {sortedColumn &&
        sortedColumn.key === index &&
        sortedColumn.value === ASC && <SortIcoAsc className="icon" />}
      {sortedColumn &&
        sortedColumn.key === index &&
        sortedColumn.value === DESC && <SortIcoDesc className="icon" />}
    </>
  );

  const expandRow = rowId => {
    setExpandedId(expandedId && rowId === expandedId ? undefined : rowId);
  };

  const updateValueOfSortedColumn = (key, type) => {
    let newSortedColumn = { ...sortedColumn };
    if (sortedColumn && key === sortedColumn.key) {
      newSortedColumn.value = sortedColumn.value === ASC ? DESC : ASC;
    } else {
      newSortedColumn = {
        key,
        value: ASC,
        type,
      };
    }
    setSortedColumn(newSortedColumn);
  };

  const renderHeader = () => {
    if (showHeader) {
      return (
        <Header data-testid="Table-header">
          {columns.map(
            (column, index) =>
              column.visible && (
                <Column
                  data-testid="Table-column"
                  overflow={
                    column.type === Columns.SELECT ? headerActions : undefined
                  }
                  key={column.key}
                  span={column.span}
                  title={column.label}
                  onClick={() => updateValueOfSortedColumn(index, column.type)}
                >
                  {renderLabelHeaderCell(column.label, index)}
                </Column>
              ),
          )}
        </Header>
      );
    }
    return null;
  };

  const renderCells = (row, cells) => (
    <>
      {cells.map((cell, index) => {
        const column = columns[index];
        console.log('column', column);
        return renderCell(row, column, cell);
      })}
    </>
  );

  const renderCell = (row, column, cell) => {
    if (cell && column.visible) {
      return (
        <Cell
          checkboxTheme={checkboxTheme}
          onSelect={() => onSelect(row.id)}
          selectStatus={selectStatus ? selectStatus(row.id) : false}
          key={cell.id || shortid.generate()}
          span={column.span}
          data={cell}
          expanded={expandedId === row.id}
          onExpand={() => expandRow(row.id)}
        />
      );
    }
    return null;
  };

  const renderBody = () => {
    if (tableData !== undefined) {
      return (
        <Body>
          {tableData.map((row, i) => {
            console.log('row', row);
            if (paginated && i > currentLimit - 1) {
              return null;
            }
            const cells = row.cells.map(cell => {
              if ('color' in cell) {
                return { ...cell, color: CHART_COLORS[i] };
              }
              return cell;
            });
            let classNameV1 = '';
            if (row.quality || row.quality === 0) {
              if (row.quality < 0) {
                classNameV1 = 'negative-mood';
              } else {
                classNameV1 = 'positive-mood';
              }
              console.log(`classNameV1 => ${classNameV1}`);
            }
            console.log('cells', cells);
            return (
              <React.Fragment key={row.id || shortid.generate()}>
                <Row
                  data-testid="Table-row"
                  className={['table-row', classNameV1]}
                  borders={borders}
                >
                  {renderCells(row, cells)}
                </Row>
                {renderExtraRows(row)}
              </React.Fragment>
            );
          })}
        </Body>
      );
    }
    return undefined;
  };

  const renderExtraRows = row => {
    if (row.id === expandedId && row.extra) {
      return (
        <>
          {row.extra.map(extraRow => (
            <Row className="table-row" borders={borders} key={extraRow.id}>
              {renderCells(row, extraRow.data)}
            </Row>
          ))}
        </>
      );
    }
    return null;
  };

  const handleCollapse = () => {
    if (currentLimit === data.length) {
      return setCurrentLimit(limit);
    }
    return setCurrentLimit(data.length);
  };

  const renderShowMore = () => {
    if (paginated && data.length > limit) {
      const message = data.length <= currentLimit ? 'Show less' : 'Show more';
      return <ShowMore onClick={handleCollapse}>{message}</ShowMore>;
    }
    return null;
  };

  return (
    <Wrapper className={className}>
      {renderHeader()}
      {renderBody()}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        {renderShowMore()}
      </div>
    </Wrapper>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  className: PropTypes.string,
  borders: PropTypes.bool,
  checkboxTheme: PropTypes.shape(checkboxThemeShape),
  onSelect: PropTypes.func,
  selectStatus: PropTypes.func,
  headerActions: PropTypes.element,
  limit: PropTypes.number,
  showHeader: PropTypes.bool,
  paginated: PropTypes.bool,
};

Table.defaultProps = {
  columns: [],
  data: [],
  limit: 3,
  showHeader: true,
  paginated: true,
};

export default Table;
