import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortid from 'shortid';

// Components
import SubsectionTitle from 'components/SubsectionTitle';
import { ClipLoader } from 'react-spinners';
import Error from 'components/Error';
import SectionKpi from 'components/SectionKpi';

// Translations
import { injectIntl } from 'react-intl';
import messages from '../messages';

// APIs
import { fetchKpis } from '../api';

// Images
import { ReactComponent as KpisIco } from 'images/sections/team.svg';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex: 1;
  margin-left: ${Margins.XXXXXXLARGE}px;
  justify-content: center;
`;

const KpisIcon = styled(KpisIco)`
  width: 50px;
  height: 50px;
`;

function Kpis({ intl, start, end, unitId, hidePercentage }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    let canceled = false;
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      setData([]);
      try {
        const response = await fetchKpis(unitId, start, end);
        const json = await response.json();
        if (!canceled) {
          setData(json);
        }
      } catch {
        if (!canceled) {
          setError(true);
        }
      } finally {
        if (!canceled) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      canceled = true;
    };
  }, [unitId, start, end]);

  const renderLoading = () => {
    if (loading) {
      return <ClipLoader size={20} color={Colors.LIGHT_NAVY} />;
    }
    return null;
  };

  const renderKpis = () => {
    if (!loading && !error && data && data.length) {
      return (
        <>
          {data.map((kpi, i) => (
            <SectionKpi
              key={shortid.generate()}
              tiny={i > 0 && true}
              type={kpi.type}
              hidePercentage={hidePercentage}
              {...kpi}
            />
          ))}
        </>
      );
    }
    return null;
  };

  const renderError = () => {
    if (error) {
      return <Error />;
    }
    return null;
  };

  return (
    <Wrapper>
      <SubsectionTitle
        icon={<KpisIcon />}
        title={intl.formatMessage(messages.kpisTitle)}
        body={intl.formatMessage(messages.kpisSubtitle)}
      />

      <Content>
        {renderLoading()}
        {renderKpis()}
        {renderError()}
      </Content>
    </Wrapper>
  );
}

Kpis.propTypes = {
  intl: PropTypes.object,
  start: PropTypes.string,
  end: PropTypes.string,
  unitId: PropTypes.number,
  hidePercentage: PropTypes.bool,
};

export default injectIntl(Kpis);
