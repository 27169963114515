import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import FloatingInput from 'components/FloatingInput';
import { Title, Subtitle, Paragraph, Link } from '../commonStyles';
import { ClipLoader } from 'react-spinners';

// Constants
import * as Margins from 'constants/margins';
import * as Colors from 'constants/colors';

// Translations
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';

// Utils
import { IsMailValid } from 'utils/input';

// Styles
const InviteForm = styled.form`
  width: 100%;
`;

const SubmitButton = styled.button`
  border: 1px solid #ffffff;
  padding: ${Margins.LARGE}px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14.2px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: 1px;
  text-align: center;
  color: ${Colors.LIGHT_NAVY};
  margin: ${Margins.LARGE}px auto;

  :hover {
    opacity: 0.9;
  }

  :focus {
    outline: none;
  }

  :disabled {
    color: ${Colors.LIGHT_NAVY};
    background-color: #ffffff;
    opacity: 0.5;
    cursor: default;
  }
`;

const Disclaimer = styled.div`
  font-family: 'Muli-Bold';
  color: #ffffff;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
  max-width: 240px;
  letter-spacing: 0.4px;
  margin-top: ${Margins.MEDIUM}px;
`;

const ErrorContainer = styled.div`
  background-color: ${Colors.ERROR_RED};
  border-radius: 4px;
  height: 56px;
  color: #fff;
  width: 100%;
  padding: ${Margins.LARGE}px;
  margin: ${Margins.REGULAR}px auto;
  transition: 0.2s ease-out opacity;
  opacity: ${props => (props.visible ? 1 : 0)};
`;

const InvitePage = ({
  email,
  onEmailChange,
  coworkerName,
  error,
  loading,
  handleSubmit,
}) => {
  const intl = useIntl();

  const getFormStatus = () => {
    if (!email || !IsMailValid(email)) return false;
    return true;
  };

  const renderParagraphs = () => (
    <>
      <Title>
        {coworkerName}
        <FormattedMessage {...messages.title} />
      </Title>

      <Subtitle>
        <FormattedMessage {...messages.subtitle} />
      </Subtitle>

      <Paragraph>
        <FormattedMessage {...messages.verifyText} />
      </Paragraph>
    </>
  );

  const renderInviteForm = () => (
    <InviteForm onSubmit={e => handleSubmit(e)}>
      <FloatingInput
        id="email"
        type="email"
        placeholder="email"
        value={email}
        onChange={e => onEmailChange(e.target.value)}
        error={!!error}
      />
      <SubmitButton type="submit" disabled={!getFormStatus() || loading}>
        {loading ? (
          <ClipLoader size={20} color={Colors.LIGHT_NAVY} />
        ) : (
          <FormattedMessage {...messages.verifyAddress} />
        )}
      </SubmitButton>
    </InviteForm>
  );

  const renderDisclaimer = () => (
    <Disclaimer>
      <FormattedMessage
        {...messages.disclaimer}
        values={{
          privacyLink: (
            <Link
              href={`https://www.feedbackloop.eu/${intl.locale}/privacy-policy/`}
              target="_blank"
            >
              <FormattedMessage {...messages.privacyPolicy} />
            </Link>
          ),
        }}
      />
    </Disclaimer>
  );

  const renderError = () => {
    if (!error) return null;
    return <ErrorContainer visible={error}>{error}</ErrorContainer>;
  };

  return (
    <>
      {renderParagraphs()}
      {renderInviteForm()}
      {renderError()}
      {renderDisclaimer()}
    </>
  );
};

InvitePage.propTypes = {
  email: PropTypes.string,
  onEmailChange: PropTypes.func,
  coworkerName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default InvitePage;
