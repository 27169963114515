import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  CommonWrapper,
  FirstRow,
  Title,
  Subtitle as Sub,
  SecondRow,
} from './CommonStyle';

function Subtitle({ title, subtitle }) {
  return (
    <CommonWrapper data-testid="SectionKpi-Subtitle-wrapper">
      <FirstRow>
        <Title>{title}</Title>
      </FirstRow>
      <SecondRow>
        <Sub>{subtitle}</Sub>
      </SecondRow>
    </CommonWrapper>
  );
}

Subtitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Subtitle;
