// Utils
import { Auth } from 'aws-amplify';
import { getTenant } from 'utils/storage';

async function getDefaultHeaders() {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const tenantObj = getTenant();
    const tenantId = tenantObj && tenantObj.id ? tenantObj.id : '';
    return {
      Authorization: `Bearer ${token}`,
      'X-Tenant': tenantId || '',
    };
  } catch (error) {
    return {};
  }
}

export async function apiPostTextCSV(url, file) {
  const headers = await getDefaultHeaders();

  const formdata = new FormData();
  formdata.append('file', file, file.name);

  return fetch(url, {
    method: 'POST',
    headers,
    body: formdata,
  });
}

export async function apiGet(url) {
  const headers = await getDefaultHeaders();
  return fetch(url, {
    method: 'GET',
    headers,
  });
}

export async function apiPost(url, body) {
  const headers = await getDefaultHeaders();
  headers['Content-Type'] = 'application/json';
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });
}

export async function apiPut(url, body) {
  const headers = await getDefaultHeaders();
  headers['Content-Type'] = 'application/json';
  return fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  });
}

export async function apiDelete(url) {
  const headers = await getDefaultHeaders();
  return fetch(url, {
    method: 'DELETE',
    headers,
  });
}

export function addQueryStringParams({
  url,
  organizationUnitId,
  start,
  end,
  suggested,
  separateTeams,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
  userIds,
  disable,
}) {
  // Add ? at the end if not exists
  let urlCopy = url.endsWith('?') ? url : `${url}?`;

  if (organizationUnitId) {
    urlCopy += `organizationUnitId=${organizationUnitId}&`;
  }

  if (start) {
    urlCopy += `start=${start}&`;
  }

  if (end) {
    urlCopy += `end=${end}&`;
  }

  if (suggested) {
    urlCopy += `suggestions=${suggested}&`;
  }

  if (separateTeams) {
    urlCopy += `separateTeams=${separateTeams}&`;
  }

  if (benchmarkOrganizationUnitId) {
    urlCopy += `benchmarkOrganizationUnitId=${benchmarkOrganizationUnitId}&`;
  }

  if (benchmarkStart) {
    urlCopy += `benchmarkStart=${benchmarkStart}&`;
  }

  if (benchmarkEnd) {
    urlCopy += `benchmarkEnd=${benchmarkEnd}&`;
  }

  if (disable) {
    urlCopy += `disable=${disable}&`;
  }

  if (userIds) {
    urlCopy += getQueryStringOfList(userIds);
  }

  // Return without trailing &
  return urlCopy.slice(0, -1);
}

const getQueryStringOfList = usersList => {
  let list = '';
  usersList.forEach(userId => {
    list = `${list}userIds=${userId}&`;
  });
  return list;
};
