import messages from './messages';

import { ORGANIZATION } from 'constants/tabs';

export default [
  {
    key: ORGANIZATION,
    name: messages.organizationTab,
  },
  // {
  //   key: GROUPS,
  //   name: messages.groupsTab,
  // },
  // {
  //   key: HEATMAP,
  //   name: messages.heatmapTab,
  // },
];
