import { apiGet, addQueryStringParams } from 'utils/request';
import HOST from 'utils/globals';

export const fetchHeaderKpis = (organizationUnitId, start, end) => {
  const url = addQueryStringParams({
    url: `${HOST}/competenceCoverage/header?`,
    organizationUnitId,
    start,
    end,
  });
  return apiGet(url);
};

export const fetchKpis = (
  organizationUnitId,
  start,
  end,
  suggested,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/competenceCoverage/kpi?`,
    organizationUnitId,
    start,
    end,
    suggested,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchBubbles = (
  organizationUnitId,
  start,
  end,
  suggested,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/competenceCoverage/bubbles?`,
    organizationUnitId,
    start,
    end,
    suggested,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchCategoriesTable = (
  organizationUnitId,
  start,
  end,
  suggested,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/competenceCoverage/table/category?`,
    organizationUnitId,
    start,
    end,
    suggested,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchBehaviorTable = (
  organizationUnitId,
  start,
  end,
  suggested,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/competenceCoverage/table/behavior?`,
    organizationUnitId,
    start,
    end,
    suggested,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchUnitsTable = (
  organizationUnitId,
  start,
  end,
  suggested,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/competenceCoverage/table/organizationUnit?`,
    organizationUnitId,
    start,
    end,
    suggested,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchGroupsBubbles = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/competenceCoverage/group/bubbles?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchGroupsTable = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/competenceCoverage/group/table?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchHeatmap = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/competenceCoverage/heatmap?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};
