/**
 *
 * FacetNavigation
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Context
import { FacetProvider } from './context';

// Components
import Column from 'components/FacetNavigation/Column';

// Constants
import Areas from 'constants/areas';
import { getUserdata } from 'utils/storage';

// Style
const Wrapper = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

function FacetNavigation({
  className,
  data,
  selected,
  onSelect,
  flexible,
  extraRow,
  onExtraRowClick,
  columnWidth,
  showLabels,
}) {
  const setCurrentLevel = (level, key) => {
    // Select current level and reset all the followings
    const nextSelected = selected.slice(0, level);
    nextSelected[level] = key;
    onSelect(nextSelected);
  };

  const shared = {
    areas: Areas,
    onSelect: setCurrentLevel,
    selected,
    extraRow,
    onExtraRowClick,
    flexible,
    columnWidth,
    showLabels,
  };

  const getData = uncheckedData => {
    const userdata = getUserdata();
    if (userdata.isAdmin === 1) {
      return uncheckedData;
    }

    if (uncheckedData.filter(d => d.isActive).length > 0) {
      return uncheckedData.filter(d => d.isActive);
      /* eslint-disable-next-line */
    } else {
      return uncheckedData.map(d => getData(d.children))[0];
    }
  };

  return (
    <FacetProvider value={shared}>
      <Wrapper className={className} data-testid="facetnavigation-wrapper">
        <Column level={1} data={getData(data)} />
      </Wrapper>
    </FacetProvider>
  );
}

FacetNavigation.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  selected: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  flexible: PropTypes.bool,
  extraRow: PropTypes.bool,
  onExtraRowClick: PropTypes.func,
  columnWidth: PropTypes.string,
  showLabels: PropTypes.bool,
};

FacetNavigation.defaultProps = {
  data: [],
  extraRow: true,
  columnWidth: '350px',
  showLabels: true,
};

export default FacetNavigation;
