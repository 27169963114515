import React, { useContext } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import { CommonWrapper, ShareIcon } from './styles';
import AreaSelector from 'containers/AreaSelector';
// import BenchmarkSelector from 'containers/BenchmarkSelector';
import Kpi from 'components/Kpi';
import HeaderIcon from 'components/HeaderIcon';

// Translations
import messages from '../messages';

// Utils
import { format } from 'utils/numbers';
import pdfExport from 'utils/pdfExport';

// Constants
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled(CommonWrapper)`
  justify-content: space-between;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Kpis = styled.div`
  display: flex;
  margin-left: ${Margins.XXLARGE}px;
`;

const IconsWrapper = styled.div`
  display: flex;
`;

function CommonHeader() {
  const { kpis = [], location } = useContext(AuthContext);
  return (
    <Wrapper>
      <InfoWrapper>
        <AreaSelector />

        <Kpis>
          {kpis.map(kpi => (
            <Kpi
              key={shortid.generate()}
              value={format(kpi.value)}
              label={kpi.title}
            />
          ))}
        </Kpis>
        {/* <BenchmarkSelector /> */}
      </InfoWrapper>
      <IconsWrapper>
        <HeaderIcon
          icon={<ShareIcon />}
          label={messages.share}
          onClick={() => pdfExport(location.pathname)}
        />
      </IconsWrapper>
    </Wrapper>
  );
}

export default CommonHeader;
