import React from 'react';
import styled from 'styled-components';

// Translations
import messages from './messages';

// Assets
// import { ReactComponent as Brilliance } from 'images/sidebar/brilliance.svg';
import { ReactComponent as Mood } from 'images/sidebar/mood.svg';
import { ReactComponent as Skills } from 'images/sidebar/skills.svg';
// import { ReactComponent as TeamCard } from 'images/sidebar/teamcard.svg';
import { ReactComponent as Analytics } from 'images/sidebar/analytics.svg';
import { ReactComponent as Users } from 'images/sidebar/users.svg';
// import { ReactComponent as Organization } from 'images/sidebar/organization.svg';

// Constants
import * as Paths from 'constants/paths';

// Style
// const BrillianceIcon = styled(Brilliance)`
//   width: 24px;
//   height: 24px;
// `;

const MoodIcon = styled(Mood)`
  width: 24px;
  height: 24px;
`;

const SkillsIcon = styled(Skills)`
  width: 24px;
  height: 24px;
`;

// const TeamCardIcon = styled(TeamCard)`
//   width: 24px;
//   height: 24px;
// `;

const AnalyticsIcon = styled(Analytics)`
  width: 24px;
  height: 24px;
`;

const UsersIcon = styled(Users)`
  width: 24px;
  height: 24px;
`;

// const OrganizationIcon = styled(Organization)`
//   width: 24px;
//   height: 24px;
// `;

export default [
  {
    key: 'company',
    title: messages.company,
    data: [
      {
        key: 'accounts',
        route: Paths.ACCOUNTS,
        title: messages.accounts,
        icon: <UsersIcon />,
      },
      // {
      //   key: 'users',
      //   route: Paths.USERS,
      //   title: messages.users,
      //   icon: <UsersIcon />,
      // },
      // {
      //   key: 'organization',
      //   route: Paths.ORGANIZATION,
      //   title: messages.organization,
      //   icon: <OrganizationIcon />,
      // },
    ],
  },
  {
    key: 'data',
    title: messages.data,
    data: [
      // {
      //   key: 'brilliance',
      //   route: Paths.BRILLIANCE,
      //   title: messages.brilliance,
      //   icon: <BrillianceIcon />,
      // },
      {
        key: 'analytics',
        route: Paths.ANALYTICS,
        title: messages.analytics,
        icon: <AnalyticsIcon />,
      },
      {
        key: 'mood',
        route: Paths.MOOD,
        title: messages.mood,
        icon: <MoodIcon />,
      },
      {
        key: 'skills',
        route: Paths.SKILLS,
        title: messages.skills,
        icon: <SkillsIcon />,
      },
      // {
      //   key: 'teamcard',
      //   route: Paths.TEAMCARD,
      //   title: messages.teamcard,
      //   icon: <TeamCardIcon />,
      // },
    ],
  },
];
