/**
 *
 * DonutChart
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

// Components
import Table from 'components/Table';

// Constants
import * as Colors from 'constants/colors';

// Style
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  & .amcharts-Label {
    letter-spacing: 0.4px;
  }

  & .amcharts-AmChartsLogo-group {
    visibility: hidden;
  }
`;

const Legend = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CHART_COLORS = [
  am4core.color(Colors.DONUTS_50),
  am4core.color(Colors.DONUTS_30_49),
  am4core.color(Colors.DONUTS_10_29),
  am4core.color(Colors.DONUTS_10),
  am4core.color(Colors.DONUTS_0),
];

export default class DonutChart extends Component {
  componentDidMount() {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themesAnimated);
    am4core.options.autoSetClassName = true;
    // Themes end

    const chart = am4core.create('donutchartdiv', am4charts.PieChart);
    chart.data = this.props.data.pieChart;

    // Innner radius
    chart.innerRadius = am4core.percent(43);
    chart.hideCredits = true;

    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.colors.list = CHART_COLORS;
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'label';
    pieSeries.slices.template.stroke = am4core.color('#fff');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;

    // Initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    // Inner text
    let label = pieSeries.createChild(am4core.Label);
    label.text = Math.round(this.props.data.average);
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'middle';
    label.fontSize = 34.5;
    label.y = -20;
    label.fill = Colors.BATTLESHIP_GREY;

    label = pieSeries.createChild(am4core.Label);
    label.text = 'Average';
    label.horizontalCenter = 'middle';
    label.fontSize = 12.2;
    label.fontFamily = 'Muli-bold';
    label.y = 3;
    label.fill = Colors.BATTLESHIP_GREY;
    label = pieSeries.createChild(am4core.Label);
    label.text = 'point';
    label.horizontalCenter = 'middle';
    label.fontSize = 12.2;
    label.fontFamily = 'Muli-bold';
    label.y = 20;
    label.fill = Colors.BATTLESHIP_GREY;

    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <Container>
        <div
          id="donutchartdiv"
          style={{ width: '100%', maxWidth: '500px', height: '300px' }}
        />
        {this.props.legendData && (
          <Legend>
            <Table
              data={this.props.data.table}
              columns={this.props.legendData}
              showHeader={false}
              borders
              limit={5}
            />
          </Legend>
        )}
      </Container>
    );
  }
}

DonutChart.propTypes = {
  data: PropTypes.object,
  legendData: PropTypes.array,
};
