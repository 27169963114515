import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import ReactTooltip from 'react-tooltip';

// Colors
import { BLACK, LIGHT_GREY_BLUE } from 'constants/colors';

// Style
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${LIGHT_GREY_BLUE};
`;

const Label = styled.div`
  font-family: 'Muli-Bold';
  line-height: 16px;
  font-size: 12.2px;
  z-index: 0;
  color: ${BLACK};
`;

const Tooltip = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PositiveEnergy = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  opacity: ${props => props.opacity};
`;

const TooltipWrapper = styled(ReactTooltip)`
  &.custom-theme {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid black;
    opacity: 0.8;
  }
`;

const ContentClick = styled.div`
  width: 100%;
  height: 100%;
`;

const TooltipContent = styled.div`
  background-color: rgba(38, 38, 38, 0.7);
`;

const TooltipTitle = styled.div`
  font-size: 14.2px;
  font-family: 'Muli-Bold';
  letter-spacing: 0.2px;
  line-height: 20px;
`;

const tooltipStyle = {
  pointerEvents: 'auto',
  overflowY: 'auto',
};

const afterShow = event => {
  if (event.target) {
    ReactTooltip.hide();
  }
  ReactTooltip.show(event.target);
};

function HeatmapCell({
  label,
  backgroundColor,
  name,
  value,
  className,
  tooltip,
  tooltipId,
}) {
  const onInputClick = e => {
    e.stopPropagation();
  };

  const renderTooltipContent = () => (
    <TooltipContent>
      <TooltipTitle>
        {name} ({value})
      </TooltipTitle>
    </TooltipContent>
  );

  const renderTooltip = () => {
    if (tooltip) {
      return (
        <>
          <TooltipWrapper
            id={tooltipId}
            place="top"
            effect="solid"
            className="custom-theme"
            globalEventOff="click"
            afterShow={afterShow}
          >
            <ContentClick style={tooltipStyle} onClick={onInputClick}>
              {renderTooltipContent()}
            </ContentClick>
          </TooltipWrapper>
        </>
      );
    }
    return null;
  };

  return (
    <Wrapper className={className} data-testid="HeatmapTable-Cell">
      {renderTooltip()}

      <PositiveEnergy
        opacity={value / 100}
        backgroundColor={backgroundColor}
        data-testid="HeatmapTable-Cell-Energy"
      />

      {label && <Label>{label}</Label>}

      {tooltip && (
        <Tooltip
          data-tip
          data-for={tooltipId}
          data-event="click"
          data-testid="Cell-tooltip-button"
        />
      )}
    </Wrapper>
  );
}

HeatmapCell.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.bool,
  tooltipId: PropTypes.string,
  backgroundColor: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
};

HeatmapCell.defaultProps = {
  label: '',
  tooltip: false,
  value: 0,
};

export default HeatmapCell;
