import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import OverlappingDots from 'components/OverlappingDots';

// Constants
import * as Margins from 'constants/margins';
import { BATTLESHIP_GREY } from 'constants/colors';

// Utils
import { truncate } from 'utils/numbers';

// Style
const Wrapper = styled.div`
  display: flex;
`;

const Text = styled.span`
  margin-left: ${Margins.REGULAR}px;
  font-size: 12px;
  line-height: ${Margins.LARGE}px;
  letter-spacing: 0.11px;
  color: ${BATTLESHIP_GREY};
`;

function Dispersion({ value }) {
  return (
    <Wrapper>
      <OverlappingDots value={value} />
      <Text>{truncate(value)}%</Text>
    </Wrapper>
  );
}

Dispersion.propTypes = {
  value: PropTypes.number,
};

export default Dispersion;
