/*
 * Header Messages
 *
 * This contains all the text for the Header container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Header';

export default defineMessages({
  share: {
    id: 'global.actions.share',
    defaultMessage: 'Condividi',
  },
  add: {
    id: 'global.actions.add',
    defaultMessage: 'Aggiungi',
  },
  import: {
    id: 'global.actions.import',
    defaultMessage: 'Importa',
  },
  export: {
    id: 'global.actions.export',
    defaultMessage: 'Esporta',
  },
});
