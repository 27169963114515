/**
 *
 * @param {number} value It's the absolute value
 * @param {array} axis It's the array of values of the axis
 * @param {number} size Chart's height or width
 *
 */
export function getRelativeValue(value, axis, size = 400) {
  // Remove first value since its always 0
  const filteredAxis = axis.filter((_, i) => i > 0);

  const stepPercentage = 100 / (axis.length - 1);
  const stepSize = (size * stepPercentage) / 100;

  let offset = 0;
  let delta = 0;
  let found = false;

  for (let i = 0; i <= filteredAxis.length && !found; i += 1) {
    const step = filteredAxis[i];

    if (value >= step) {
      offset += stepSize;
    } else {
      const stepsDelta = filteredAxis[i] - (filteredAxis[i - 1] || 0);
      const relativeDelta = value - (filteredAxis[i - 1] || 0);
      const deltaPerc = (relativeDelta / stepsDelta) * 100;
      delta = (deltaPerc * stepSize) / 100;
      found = true;
    }
  }

  return Math.round(offset + delta);
}
