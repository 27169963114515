/**
 *
 * Analytics
 *
 */

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import { Helmet } from 'react-helmet';
import OriginalSectionTitle from 'containers/SectionTitle';
import Kpis from './components/Kpis';
import Engagement from './components/Engagement';
import ActiveUsers from './components/ActiveUsers';

// Translations
import { injectIntl } from 'react-intl';
import messages from './messages';

// Data
import periods from 'utils/periodGenerator';

// Constants
import * as Margins from 'constants/margins';
import License from './components/License';
import { fetchHeaderKpis } from 'containers/Mood/api';
import WeeklyActiveUsers from './components/WeeklyActiveUsers';
import MonthlyActiveUsers from './components/MonthlyActiveUsers';

// Style
const SectionTitle = styled(OriginalSectionTitle)`
  .content {
    padding-bottom: ${Margins.MEDIUM}px;
    justify-content: flex-end;
  }
`;

const Content = styled.div`
  padding: 40px ${Margins.XXLARGE}px;
  height: calc(100% - 188px);
  overflow-x: hidden;
  overflow-y: auto;
`;

export function Analytics({ intl }) {
  // Context
  const { setKpis, unitId, period } = useContext(AuthContext);

  useEffect(() => {
    const fetchHeaderData = async () => {
      const { start, end } = periods[period];

      try {
        const response = await fetchHeaderKpis(unitId, start, end);
        const data = await response.json();
        setKpis(data);
      } catch {
        setKpis([]);
      }
    };
    fetchHeaderData();
  }, [unitId, period]);

  return (
    <>
      <Helmet>
        <title>Analytics</title>
        <meta name="description" content="Description of Analytics" />
      </Helmet>

      <SectionTitle
        title={intl.formatMessage(messages.title)}
        hideTabs
        periods={periods}
      />

      <Content>
        <Kpis unitId={unitId} {...periods[period]} hidePercentage />
        <Engagement unitId={unitId} {...periods[period]} />
        <License unitId={unitId} {...periods[period]} />
        <ActiveUsers unitId={unitId} {...periods[period]} />
        <WeeklyActiveUsers unitId={unitId} {...periods[period]} />
        <MonthlyActiveUsers unitId={unitId} {...periods[period]} />
      </Content>
    </>
  );
}

Analytics.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(Analytics);
