import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Images
import { ReactComponent as PeopleIc } from 'images/shared/people.svg';

// Constants
import * as Margins from 'constants/margins';
import { BLACK, BATTLESHIP_GREY } from 'constants/colors';

// Style
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 14.2px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${BLACK};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Count = styled.div`
  display: flex;
  font-size: 12px;
  line-height: ${Margins.LARGE}px;
  letter-spacing: 0.11px;
  color: ${BATTLESHIP_GREY};
`;

const PeopleIcon = styled(PeopleIc)`
  width: 16px;
  height: 16px;
  color: ${BATTLESHIP_GREY};
  margin-left: ${Margins.SMALL}px;
`;

function Group({ label, count }) {
  return (
    <Wrapper>
      <Info>
        {label && (
          <Name data-testid="Table-group-label" title={label}>
            {label}
          </Name>
        )}
      </Info>

      <Count>
        <span>{count}</span>
        <PeopleIcon />
      </Count>
    </Wrapper>
  );
}

Group.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number,
};

export default Group;
