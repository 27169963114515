import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';

// Context
import Context from '../context';

// Style
const Row = styled.div`
  display: flex;
  flex: 1;
`;

const Sector = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #babac2;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1px;
`;

function Sectors() {
  const { sectors } = React.useContext(Context);

  if (sectors.length > 0) {
    return (
      <>
        {sectors.map((row, rIndex) => (
          <Row key={shortid.generate()} first={rIndex === 0}>
            {row.map((sector, sIndex) => (
              <Sector key={shortid.generate()} first={sIndex === 0}>
                {sector && sector.name}
              </Sector>
            ))}
          </Row>
        ))}
      </>
    );
  }
  return null;
}

export default Sectors;
