/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import template from './template.csv';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';
import { ReactComponent as Data } from '../../images/modal/data.svg';
import { ReactComponent as Download } from '../../images/modal/download.svg';
import { ReactComponent as Export } from '../../images/modal/export.svg';

// Translations
import { injectIntl } from 'react-intl';
import messages from './messages';

// Constants
import styled from 'styled-components';
import info from '../../images/shared/info.png';

const Container = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PrimaryText = styled.p`
  width: 100%;
  font-size: 24.38px;
  text-align: left;
`;

const SecondaryText = styled.p`
  font-size: 16px;
  text-align: left;
  color: #cdcdcd;
`;

const Link = styled.a`
  font-size: 16px;
  text-align: left;
  color: #000000;
  underline-color: #000000;
  text-decoration: underline;
`;

const NextButton = styled.a`
  text-decoration: none;
  align-self: center;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: ${Margins.MEDIUM}px;
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.BATTLESHIP_GREY};
  border-radius: 12px;
  margin-top: 20px;
  border: 1px solid ${Colors.BATTLESHIP_GREY};

  &:hover {
    border: 1px solid white;
    background: linear-gradient(
      120deg,
      ${Colors.PURPLE} 1%,
      ${Colors.CYAN} 90%
    );
    color: #ffffff;
  }
`;

const Tooltip = styled.div`
  -webkit-box-shadow: 1px 5px 12px 1px rgba(204, 49, 255, 0.51);
  box-shadow: 1px 5px 12px 1px rgba(204, 49, 255, 0.51);

  border-radius: 10px;

  padding: 10px;
`;

const Accounts = ({ intl }) => {
  const [tooltip, setTooltip] = useState(false);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Container>
        <PrimaryText>{intl.formatMessage(messages.title)}</PrimaryText>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <PrimaryText>{intl.formatMessage(messages.subtitle)}</PrimaryText>
          <img
            src={info}
            alt="img"
            onClick={() => setTooltip(!tooltip)}
            onKeyDown={() => setTooltip(!tooltip)}
          />
        </div>
        {tooltip ? (
          <Tooltip
            style={{
              width: 300,
              marginLeft: 10,
              background: 'white',
              position: 'absolute',
              top: 150,
              right: 100,
            }}
          >
            {intl.formatMessage(messages.tableHeader)}
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginTop: 10,
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontFamily: 'Muli-Bold',
                }}
              >
                <Download />
                {intl.formatMessage(messages.tableDownload)}
              </div>
              <div style={{ marginLeft: 24 }}>
                {intl.formatMessage(messages.tableDownloadDesc)}
              </div>
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginTop: 10,
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontFamily: 'Muli-Bold',
                }}
              >
                <Data />
                {intl.formatMessage(messages.tableData)}
              </div>
              <div style={{ marginLeft: 24 }}>
                {intl.formatMessage(messages.tableDataDesc)}
              </div>
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginTop: 10,
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontFamily: 'Muli-Bold',
                }}
              >
                <Export />
                {intl.formatMessage(messages.tableExport)}
              </div>
              <div style={{ marginLeft: 24 }}>
                {intl.formatMessage(messages.tableExportDesc)}
              </div>
            </div>
            <div style={{ fontFamily: 'Muli-Bold', marginTop: 20 }}>
              {intl.formatMessage(messages.compileCSV)}
            </div>
            {intl.formatMessage(messages.compileCSVDesc)}
            <ul style={{ listStyleType: 'none' }}>
              <li>- email,</li>
              <li>- team,</li>
              <li>- birthDate,</li>
              <li>- hireDate</li>
              <li>- isTeamLeader</li>
              <li>- Language (ISO 639-1)</li>
            </ul>
            <button
              style={{
                border: 0,
                borderRadius: 8,
                backgroundColor: '#cc31ff',
                color: 'white',
                alignSelf: 'center',
                width: 280,
                height: 40,
              }}
              onClick={() => setTooltip(!tooltip)}
            >
              {intl.formatMessage(messages.okButton)}
            </button>
          </Tooltip>
        ) : (
          <div style={{ width: 300, marginLeft: 10 }}></div>
        )}
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <SecondaryText>
            {intl.formatMessage(messages.description)}
          </SecondaryText>
          <Link href={template} download="template.csv">
            {intl.formatMessage(messages.template)}
          </Link>
        </div>
        <NextButton href="/csv">{intl.formatMessage(messages.next)}</NextButton>
      </Container>
    </div>
  );
};

Accounts.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(Accounts);
