import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import {
  CommonWrapper,
  FirstRow as OriginalFirstRow,
  Title as OriginalTitle,
  SecondRow,
} from './CommonStyle';
// import TrendKPI from 'components/TrendKPI';
import OriginalBenchmark from 'components/Benchmark';

// Constants
import { DARK_SLATE_BLUE } from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Title = styled(OriginalTitle)`
  font-size: ${props => (props.tiny ? '16px' : '34.5px')};
  line-height: ${props => (props.tiny ? '1.25' : 'normal')};
  letter-spacing: ${props => (props.tiny ? '0.3' : '0.25')}px;
  color: ${DARK_SLATE_BLUE};

  ${props =>
    props.tiny &&
    `
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 20px;
  `}
`;

const Label = styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: ${props => (props.tiny ? '1.31' : '20px')};
  letter-spacing: ${props => (props.tiny ? '0.4' : '0.3')}px;
  color: ${DARK_SLATE_BLUE};

  ${props =>
    props.tiny &&
    `
    margin-left: 0;
    height: 16px;
    font-size: 12.2px;
    font-stretch: normal;
    font-style: normal;
    margin-top: ${Margins.SMALL}px;
  `}
`;

const FirstRow = styled(OriginalFirstRow)`
  ${props =>
    props.tiny &&
    `
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const Benchmark = styled(OriginalBenchmark)`
  margin-left: 32px;
`;

function Overview({
  value,
  title,
  // trend,
  tiny,
  benchmark,
  label,
  hidePercentage,
}) {
  return (
    <CommonWrapper data-testid="SectionKpi-Overview-wrapper">
      <FirstRow tiny={tiny}>
        <Title tiny={tiny}>
          {value}
          {!hidePercentage && `%`}
        </Title>
        <Label tiny={tiny}>{title}</Label>
      </FirstRow>
      <SecondRow>
        {/* <TrendKPI trend={trend > 0 ? 'up' : 'down'} percentage={trend} /> */}
        {typeof benchmark === 'number' && (
          <Benchmark value={benchmark} label={label} />
        )}
      </SecondRow>
    </CommonWrapper>
  );
}

Overview.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  // trend: PropTypes.number,
  tiny: PropTypes.bool,
  benchmark: PropTypes.number,
  label: PropTypes.string,
  hidePercentage: PropTypes.bool,
};

export default Overview;
