/**
 *
 * HeaderIcon
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Translations
import { useIntl } from 'react-intl';

// Constants
import { BLACK } from 'constants/colors';

// Style
const Wrapper = styled.div`
  width: 65px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

const Label = styled.span`
  font-size: 10px;
  line-height: 10px;
  color: ${BLACK};
  margin-top: 6px;
`;

function HeaderIcon({ icon, label, onClick }) {
  const intl = useIntl();

  return (
    <Wrapper onClick={onClick} title={intl.formatMessage(label)}>
      {icon}
      <Label>{intl.formatMessage(label)}</Label>
    </Wrapper>
  );
}

HeaderIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default HeaderIcon;
