import React, { useContext } from 'react';

// Context
import Context from '../context';

// Styled components
import styled from 'styled-components';
import { getColors } from '../bubbleColors';
import { WIDTH } from '../constants';
import * as Colors from '../../../constants/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  width: ${WIDTH}px;
`;

const Content = styled.div`
  margin-top: 5px;
  width: ${WIDTH / 2}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ColoredCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #222222;
  background: ${props => props.color};
`;

const LegendText = styled.div`
  background-color: ${props => props.backgroundColor}
  margin-left: 10px;
  font-size: 14px;
  padding: 5px;
  border-radius: 20px;
`;

const Legend = () => {
  const { data } = useContext(Context);
  return (
    <Container>
      {data.map(d => (
        <Content key={d.title}>
          <ColoredCircle color={getColors(d.color)} />
          <LegendText
            backgroundColor={d.benchmark ? Colors.PALE_PEACH : 'transparent'}
          >
            {d.title}
          </LegendText>
        </Content>
      ))}
    </Container>
  );
};

export default Legend;
