import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortid from 'shortid';

// Components
import OriginalCell from './Cell';
import OriginalPeople from './People';
import AvatarOriginal from 'components/Avatar';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Assets
import { ReactComponent as SortIcon } from 'images/table/sort.svg';

// Utils
import { truncate } from 'utils/numbers';

// Style
const Wrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  height: 32px;
  border-bottom: 1px solid ${Colors.GREY};
`;

const HeaderTitle = styled.div`
  min-width: 270px;
  margin-right: 16px;
`;

const HeaderCells = styled.div`
  display: flex;
  overflow-x: hidden;
`;

const Cell = styled.div`
  width: ${props => props.width || 90}px;
  min-width: ${props => props.width || 90}px;
  height: 100%;
  font-family: 'Muli-Bold';
  font-size: 12.2px;
  line-height: 16px;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GlobalHeaderCell = styled(Cell)`
  color: ${Colors.BATTLESHIP_GREY};
  margin-right: 12px;
`;

const HeaderCell = styled(Cell)`
  color: ${Colors.BATTLESHIP_GREY};
  cursor: pointer;
  margin-right: 1px;

  .icon {
    min-width: 18px;
    width: 18px;
    height: 18px;
    opacity: 0;
    transition: all 0.2s;
  }

  &:hover {
    .icon {
      opacity: 1;
    }
  }
`;

const Body = styled.div`
  display: flex;
`;

const FixedColumn = styled.div``;

const BodyContent = styled.div`
  overflow: auto;
`;

const Row = styled.div`
  height: 40px;
  display: flex;
  color: ${Colors.BLACK};
  font-size: 12.2px;
  align-items: center;

  &:first-child {
    margin-top: ${Margins.REGULAR}px;
  }

  &:not(:first-child) {
    margin-top: 1px;
  }
`;

const Avatar = styled(AvatarOriginal)`
  margin-right: ${Margins.REGULAR}px;
`;

const TitleContainer = styled.div`
  width: 270px;
  min-width: 270px;
  display: flex;
  align-items: center;
  margin-right: ${Margins.MEDIUM}px;
`;

const Title = styled.div`
  align-items: center;
  font-size: 14.2px;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-family: 'Muli-Bold';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Benchmark = styled.div`
  min-width: 18px;
  min-height: 18px;
  margin-left: ${Margins.REGULAR}px;
  background-color: ${Colors.PALE_PEACH};
  border-radius: 100%;
`;

const People = styled(OriginalPeople)`
  margin-left: auto;
  min-width: 64px;
`;

const GlobalBodyCell = styled(OriginalCell)`
  width: 90px;
  min-width: 90px;
  margin-right: 12px;
`;

const BodyCell = styled(OriginalCell)`
  width: ${props => props.width || 90}px;
  min-width: ${props => props.width || 90}px;
  margin-right: 1px;
`;

function HeatmapTable({ data, cellWidth, energyColor }) {
  // Refs
  const headerScrollbar = useRef(null);

  // State
  const [mainDataRow, setMainDataRow] = useState(0);

  // Effects
  useEffect(() => {
    setMainDataRow(getLongerRow());
  }, [data]);

  const handleBodyScroll = e => {
    if (headerScrollbar.current) {
      headerScrollbar.current.scrollLeft = e.target.scrollLeft;
    }
  };

  const renderHeader = () => {
    if (data.length > 0) {
      const headers = data[mainDataRow].grid.map(item => item.label);

      return (
        <Header data-testid="HeatmapTable-header">
          <HeaderTitle data-testid="HeatmapTable-headerTitle" />

          <HeaderCells ref={headerScrollbar}>
            <GlobalHeaderCell data-testid="HeatmapTable-header-globalCell">
              {headers[0]}
            </GlobalHeaderCell>

            {headers
              .filter((_, i) => i > 0)
              .map(header => (
                <HeaderCell
                  data-testid="HeatmapTable-header-cell"
                  key={shortid.generate()}
                  width={cellWidth}
                >
                  {header}
                  <SortIcon className="icon" />
                </HeaderCell>
              ))}
          </HeaderCells>
        </Header>
      );
    }
    return null;
  };

  const renderFixedColumn = () => (
    <FixedColumn data-testid="HeatmapTable-fixedColumn">
      {data.map(row => {
        const { label, color, count, benchmark } = row.cells[0];

        return (
          <Row
            key={shortid.generate()}
            data-testid="HeatmapTable-fixedColumn-row"
          >
            <TitleContainer>
              <Avatar
                className="custom-avatar"
                maxInitials={2}
                name={label}
                color={color}
                round
                size={22}
                textSizeRatio={1.5}
              />
              <Title>{label}</Title>
              {benchmark && <Benchmark />}
              <People people={count} />
            </TitleContainer>
          </Row>
        );
      })}
    </FixedColumn>
  );

  const getLongerRow = () => {
    let longerRowIndex = 0;
    data.forEach((row, i) => {
      if (row.grid.length > data[longerRowIndex].grid.length) {
        longerRowIndex = i;
      }
    });
    return longerRowIndex;
  };

  const getCellsCount = () => data[mainDataRow].grid.length;

  const renderGlobalBodyCell = row => {
    const hasCells = row.grid.length > 0;
    return (
      <GlobalBodyCell
        value={hasCells ? row.grid[0].value : 0}
        label={hasCells ? `${truncate(row.grid[0].value)}%` : '0%'}
        backgroundColor={energyColor}
        className="global-body-cell"
      />
    );
  };

  const renderBodyCells = (row, rowIndex) => {
    const fillingCells = [];
    for (let fillStep = 0; fillStep < getCellsCount() - 1; fillStep += 1) {
      const colIndex = fillStep + 1;
      fillingCells.push(
        <BodyCell
          key={shortid.generate()}
          width={cellWidth}
          backgroundColor={energyColor}
          name={data[mainDataRow].grid[colIndex].label}
          value={row.grid[colIndex] && truncate(row.grid[colIndex].value)}
          tooltip
          tooltipId={`click_${rowIndex}_${colIndex}`}
        />,
      );
    }
    return fillingCells;
  };

  const renderBody = () => (
    <BodyContent
      data-testid="HeatmapTable-bodyContent"
      onScroll={handleBodyScroll}
    >
      {data.map((row, rowIndex) => (
        <Row
          data-testid="HeatmapTable-bodyContent-row"
          key={shortid.generate()}
        >
          {renderGlobalBodyCell(row)}

          {renderBodyCells(row, rowIndex)}
        </Row>
      ))}
    </BodyContent>
  );

  return (
    data &&
    data.length > 0 && (
      <Wrapper data-testid="HeatmapTable-wrapper">
        {renderHeader()}
        <Body>
          {renderFixedColumn()}
          {renderBody()}
        </Body>
      </Wrapper>
    )
  );
}

HeatmapTable.propTypes = {
  data: PropTypes.array,
  cellWidth: PropTypes.number,
  energyColor: PropTypes.string,
};

HeatmapTable.defaultProps = {
  data: [],
  cellWidth: 136,
  energyColor: Colors.AQUA_MARINE,
};

export default HeatmapTable;
