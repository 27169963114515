/**
 *
 * SimpleBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import * as Margins from 'constants/margins';
import { LIGHT_BLUE_GREY, AQUA_MARINE } from 'constants/colors';

// Style
const Wrapper = styled.div`
  width: ${props => props.width}%;
  height: ${props => props.height}px;
  background-color: ${LIGHT_BLUE_GREY};
  border-radius: ${Margins.REGULAR}px;
  overflow: hidden;
  position: relative;
`;

const BarWrapper = styled.div`
  height: 100%;
  background-color: white;
  width: ${props => props.width}%;
  border-radius: ${Margins.REGULAR}px;
`;

const Bar = styled.div`
  height: 100%;
  background-color: ${AQUA_MARINE};
  width: 100%;
  border-radius: ${Margins.REGULAR}px;
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  color: white;
  font-size: 12px;
  line-height: 1.33;
  user-select: none;
`;

const Value = styled(Label)`
  position: absolute;
  top: ${props => props.top}px;
  left: calc(${props => `${props.left}% + ${Margins.REGULAR}px`});
`;

function SimpleBar({ width, height, showLabels, value }) {
  const top = height === 16 ? 1 : -1;
  const left = 100 - value;

  return (
    <Wrapper width={width} height={height} data-testid="SimpleBar-wrapper">
      <BarWrapper width={value} data-testid="SimpleBar-barWrapper">
        <Bar data-testid="SimpleBar-bar">
          {showLabels && <Label>{value}</Label>}
        </Bar>
      </BarWrapper>
      {showLabels && (
        <Value top={top} left={value}>
          {left}
        </Value>
      )}
    </Wrapper>
  );
}

SimpleBar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  showLabels: PropTypes.bool,
  value: PropTypes.number,
};

SimpleBar.defaultProps = {
  width: 100,
  height: 12,
  showLabels: true,
};

export default SimpleBar;
