export const BUSINESS_UNIT = 0;
export const DEPARTMENTS = 1;
export const DIVISIONS = 2;
export const UNITS = 3;

export default [
  {
    key: BUSINESS_UNIT,
    label: 'Business Unit',
  },
  {
    key: DEPARTMENTS,
    label: 'Dipartimento',
  },
  {
    key: DIVISIONS,
    label: 'Division',
  },
  {
    key: UNITS,
    label: 'Unità',
  },
];
