import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Importuccessfully';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Grazie per aver invitato gli utenti in Yumi!',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage:
      'Grazie per aver invitato gli utenti in Yumi, ti confermiamo che sono stati inviati correttamente!',
  },
  data: {
    id: `${scope}.data`,
    defaultMessage:
      'Ti invieremo una email di conferma con un riassunto degli inviti spediti. Noi non tratterremo nessuno di questi dati!',
  },
  nextButton: {
    id: `${scope}.nextButton`,
    defaultMessage: 'Torna indietro',
  },
  invitedOn: {
    id: `${scope}.invitedOn`,
    defaultMessage: ' invitati su ',
  },
  uploaded: {
    id: `${scope}.uploaded`,
    defaultMessage: ' caricati',
  },
});
