import * as Columns from 'components/Table/columns';

export const emotionsTableColumns = [
  {
    key: 'mood',
    label: 'Mood-factors',
    span: 1,
    type: Columns.TEXT,
    visible: true,
  },
  {
    key: 'division',
    label: 'Percentage',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'quantity',
    label: 'Quantità',
    span: 1,
    type: Columns.BOOLEAN_BAR,
    visible: false,
  },
  {
    key: 'benchmark',
    label: 'Benchmark',
    span: 1,
    type: Columns.BENCHMARK,
    visible: true,
  },
];

export const contextTableColumns = [
  {
    key: 'context',
    label: 'Contesto',
    span: 1,
    type: Columns.TEXT,
    visible: true,
  },
  {
    key: 'positivemoods',
    label: 'Mood positivi',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'distribution',
    label: 'Distribuzione',
    span: 1,
    type: Columns.ENERGY_BAR,
    visible: true,
  },
  {
    key: 'benchmark',
    label: 'Benchmark',
    span: 1,
    type: Columns.BENCHMARK,
    visible: true,
  },
];

export const activityTableColumns = [
  {
    key: 'activity',
    label: 'Attività',
    span: 1,
    type: Columns.TEXT,
    visible: true,
  },
  {
    key: 'positivemoods',
    label: 'Mood positivi',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'distribution',
    label: 'Distribuzione',
    span: 1,
    type: Columns.ENERGY_BAR,
    visible: true,
  },
  {
    key: 'benchmark',
    label: 'Benchmark',
    span: 1,
    type: Columns.BENCHMARK,
    visible: true,
  },
];

export const unitTableColumns = [
  {
    key: 'unit',
    label: 'Unità',
    span: 1,
    type: Columns.GROUP,
    visible: true,
  },
  {
    key: 'positivemoods',
    label: 'Energia positiva e trend',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'manager',
    label: 'Manager',
    span: 1,
    type: Columns.TEXT,
    visible: true,
  },
  {
    key: 'dispersion',
    label: 'Dispersione',
    span: 1,
    type: Columns.DISPERSION,
    visible: true,
  },
];

export const peopleColumns = [
  {
    key: 'group',
    label: 'Gruppo',
    span: 1,
    type: Columns.GROUP,
    visible: true,
  },
  {
    key: 'brilliance',
    label: 'Brilliance',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'dispersion',
    label: 'Dispersion',
    span: 1,
    type: Columns.DISPERSION,
    visible: true,
  },
];
