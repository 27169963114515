import { defineMessages } from 'react-intl';

export const scope = 'app.components.SectionKpi';

export default defineMessages({
  over: {
    id: `${scope}.over`,
    defaultMessage: 'Above benchmark',
  },
  under: {
    id: `${scope}.under`,
    defaultMessage: 'Under benchmark',
  },
  dispersion: {
    id: `${scope}.dispersion`,
    defaultMessage: 'Dispersione',
  },
});
