import React from 'react';
import PropTypes from 'prop-types';

// Components
import Overview from './Overview';
import Subtitle from './Subtitle';
import Dispersion from './Dispersion';
import Bar from './Bar';

// Translations
import { injectIntl } from 'react-intl';
import messages from './messages';

// Constants
import * as Types from './types';

function SectionKpi({ intl, type, ...props }) {
  switch (type) {
    case Types.OVERVIEW: {
      const label = intl.formatMessage(
        props.benchmark > 0 ? messages.over : messages.under,
      );

      return <Overview {...props} label={label} />;
    }

    case Types.SUBTITLE:
      return <Subtitle {...props} />;

    case Types.DISPERSION:
      return <Dispersion {...props} />;

    case Types.PROGRESS:
      return <Bar height={16} roundedBars {...props} />;

    default:
      return null;
  }
}

SectionKpi.propTypes = {
  intl: PropTypes.object,
  type: PropTypes.number,
  value: PropTypes.number,
  title: PropTypes.string,
  trend: PropTypes.number,
  benchmark: PropTypes.number,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  dispersion: PropTypes.number,
};

export default injectIntl(SectionKpi);
