/**
 *
 * Header
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

// Components
import { CommonWrapper } from './components/styles';
import CommonHeader from './components/CommonHeader';
import UsersHeader from './components/UsersHeader';
import OrganizationHeader from './components/OrganizationHeader';

// Constants
import * as Paths from 'constants/paths';

export function Header({ pathname }) {
  switch (pathname) {
    case Paths.BRILLIANCE:
    case Paths.MOOD:
    case Paths.SKILLS:
    case Paths.TEAMCARD:
    case Paths.ANALYTICS:
      return <CommonHeader pathname={pathname} />;

    case Paths.USERS:
      return <UsersHeader pathname={pathname} />;

    case Paths.ORGANIZATION:
      return <OrganizationHeader pathname={pathname} />;

    case Paths.USER_TABLE:
    case Paths.ADD_CSV:
    case Paths.ACCOUNTS:
    case Paths.IMPORT_SUCCESS:
      return <> </>;

    default:
      return <CommonWrapper />;
  }
}

Header.propTypes = {
  pathname: PropTypes.oneOf(Object.values(Paths)),
};

export default Header;
