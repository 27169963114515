/*
 * Sidebar Messages
 *
 * This contains all the text for the Sidebar container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Sidebar';

export default defineMessages({
  data: {
    id: `${scope}.data`,
    defaultMessage: 'Dati',
  },
  brilliance: {
    id: `${scope}.brilliance`,
    defaultMessage: 'Brilliance',
  },
  mood: {
    id: `${scope}.mood`,
    defaultMessage: 'Energy',
  },
  skills: {
    id: `${scope}.skills`,
    defaultMessage: 'Skills',
  },
  teamcard: {
    id: `${scope}.teamcard`,
    defaultMessage: 'Team Card',
  },
  analytics: {
    id: `${scope}.analytics`,
    defaultMessage: 'Analytics',
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: 'Azienda',
  },
  accounts: {
    id: `${scope}.accounts`,
    defaultMessage: 'Gestione inviti',
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: 'Utenti',
  },
  organization: {
    id: `${scope}.organization`,
    defaultMessage: 'Organizzazione',
  },
});
