import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import SubsectionTitle from 'components/SubsectionTitle';
import { ClipLoader } from 'react-spinners';
import Error from 'components/Error';

// Translations
import { injectIntl } from 'react-intl';
import messages from '../messages';

// APIs
import { fetchLicense } from '../api';

// Images
import { ReactComponent as ExchangeIco } from 'images/sections/exchange.svg';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 50px;
  margin-left: ${Margins.XXLARGE}px;
  display: flex;
  justify-content: center;
`;

const ExchangeIcon = styled(ExchangeIco)`
  width: 50px;
  height: 50px;
`;

const Funnel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const FunnelItem = styled.div`
  padding: 0px;
  margin: 0px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-size: 24px;
  color: white;
  font-family: Muli-SemiBold;
`;

const PercentageText = styled.div`
  height: 20px;
  font-size: 16px;
  color: white;
  font-family: Muli-Regular;
`;

const SectionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding-top: 20px;
`;

const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 150px;
  height: ${props => props.size}px;
  margin-left: 10px;
  margin-right: 10px;
  transform: perspective(300px) rotateY(10deg) scale(1.1, 1.1);
  :hover {
    transform: perspective(300px) rotateY(10deg) scale(1.1, 1.1)
      translate(0, -4px);
    -webkit-box-shadow: 0px 5px 10px -6px #000000;
    box-shadow: 0px 5px 10px -6px #000000;
  }
`;

const CHART_COLORS = [
  Colors.LIGHT_GREY_BLUE,
  Colors.DONUTS_10,
  Colors.DONUTS_30_49,
  Colors.DONUTS_50,
];

function License({ intl, unitId, start, end }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    let canceled = false;
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      setData([]);
      try {
        const response = await fetchLicense(unitId, start, end);
        const json = await response.json();
        if (!canceled) {
          setData(json);
        }
      } catch {
        if (!canceled) {
          setError(true);
        }
      } finally {
        if (!canceled) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      canceled = true;
    };
  }, [unitId, start, end]);

  const renderLoading = () => {
    if (loading) {
      return <ClipLoader size={20} color={Colors.LIGHT_NAVY} />;
    }
    return null;
  };

  const renderLicense = () => {
    if (!loading && !error && data && data.length) {
      return (
        <Funnel>
          {data.map((license, index) => (
            <div key={`key-${license.title}-${license.value}`}>
              <FunnelItem>
                <Item
                  style={{ backgroundColor: CHART_COLORS[index] }}
                  size={150 - 15 * index}
                >
                  <PercentageText>
                    {license.percentage !== undefined
                      ? `${license.percentage}%`
                      : undefined}
                  </PercentageText>
                  <Text>{license.value}</Text>
                </Item>
              </FunnelItem>
              <SectionTitle>{license.title}</SectionTitle>
            </div>
          ))}
        </Funnel>
      );
    }
    return null;
  };

  const renderError = () => {
    if (error) {
      return <Error />;
    }
    return null;
  };

  return (
    <Wrapper>
      <SubsectionTitle
        icon={<ExchangeIcon />}
        title={intl.formatMessage(messages.pipelineEngagementTitle)}
        body={intl.formatMessage(messages.pipelineEngagementSubtitle)}
      />

      <Content>
        {renderLoading()}
        {renderLicense()}
        {renderError()}
      </Content>
    </Wrapper>
  );
}

License.propTypes = {
  intl: PropTypes.object,
  start: PropTypes.string,
  end: PropTypes.string,
  unitId: PropTypes.number,
};

export default injectIntl(License);
