import { defineMessages } from 'react-intl';

export const scope = 'app.containers.UserTable';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Il file è stato elaborato!',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage:
      'Di seguito troverai la lista dei caricamenti andati a buon fine e di quelli non riusciti',
  },
  nextButton: {
    id: `${scope}.nextButton`,
    defaultMessage: 'Spedisci inviti',
  },
  reload: {
    id: `${scope}.reload`,
    defaultMessage: 'Vuoi ricaricare il documento? Clicca qui',
  },
  disclaimer: {
    id: `${scope}.disclaimer`,
    defaultMessage:
      'Cliccando SPEDISCI INVITI inviterai solo gli utenti che hai caricato correttamente.{br}Gli eventuali utenti con lo sfondo rosso sono quelli che verrano cancellati',
  },
  youSure: {
    id: `${scope}.youSure`,
    defaultMessage:
      'Sei sicuro di voler spedire questi inviti? Questa operazione non è reversibile',
  },
  notSure: {
    id: `${scope}.notSure`,
    defaultMessage: 'Chiudi',
  },
  imSure: {
    id: `${scope}.imSure`,
    defaultMessage: 'Continua',
  },
  uploadedDone: {
    id: `${scope}.uploadedDone`,
    defaultMessage: 'caricamenti riusciti',
  },
  brokenUpload: {
    id: `${scope}.brokenUpload`,
    defaultMessage: 'caricamenti non riusciti',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Sì',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  team: {
    id: `${scope}.team`,
    defaultMessage: 'Team',
  },
  birthdate: {
    id: `${scope}.birthdate`,
    defaultMessage: 'Data di nascita',
  },
  hiringDate: {
    id: `${scope}.hiringDate`,
    defaultMessage: 'Assunzione',
  },
  teamLeader: {
    id: `${scope}.teamLeader`,
    defaultMessage: 'Team Leader',
  },
  language: {
    id: `${scope}.language`,
    defaultMessage: 'Lingua',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Stato',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country',
  },
  labels: {
    id: `${scope}.labels`,
    defaultMessage: 'Labels',
  },
});
