import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Context
import LocaleContext from './context';

// Translations
import { IntlProvider } from 'react-intl';
import enMessages from 'translations/en.json';

// Constants
import { IT, EN } from './constants';

const messages = {
  [EN]: enMessages,
};

function LocaleProvider({ children }) {
  const [locale, setLocale] = useState(IT);

  const changeLocale = loc => {
    setLocale(loc);
    moment.locale(loc);
  };

  const shared = {
    locale,
    changeLocale,
  };

  return (
    <IntlProvider
      defaultLocale={IT}
      locale={locale}
      messages={messages[locale]}
    >
      <LocaleContext.Provider value={shared}>{children}</LocaleContext.Provider>
    </IntlProvider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.object,
};

export default LocaleProvider;
