/**
 *
 * BubbleChart
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Context
import Context from './context';

// Components
import AxisLabels from './components/AxisLabels';
import Grid from './components/Grid';
import Sectors from './components/Sectors';
import Bubbles from './components/Bubbles';

// Constants
import { GREY } from 'constants/colors';
import { WIDTH, HEIGHT } from './constants';
import Legend from './components/Legend';

// Style
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${GREY};
`;

const WrapperContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function BubbleChart(props) {
  const shared = {
    xAxis: props.xAxis,
    showXLabels: props.showXLabels,
    gridXStep: props.gridXStep,
    yAxis: props.yAxis,
    showYLabels: props.showYLabels,
    gridYStep: props.gridYStep,
    sectors: props.sectors,
    data: props.data,
  };

  return (
    <Context.Provider value={shared}>
      <WrapperContainer>
        <Wrapper>
          <AxisLabels />
          <Grid />
          <Sectors />
          <Bubbles />
        </Wrapper>
        <Legend />
      </WrapperContainer>
    </Context.Provider>
  );
}

BubbleChart.propTypes = {
  xAxis: PropTypes.array,
  showXLabels: PropTypes.bool,
  gridXStep: PropTypes.number,
  yAxis: PropTypes.array,
  showYLabels: PropTypes.bool,
  gridYStep: PropTypes.number,
  sectors: PropTypes.array,
  data: PropTypes.array,
};

BubbleChart.defaultProps = {
  xAxis: [],
  showXLabels: false,
  gridXStep: 0,
  yAxis: [],
  showYLabels: false,
  gridYStep: 0,
  sectors: [],
  data: [],
};

export default BubbleChart;
