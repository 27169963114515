import React from 'react';
import ReactDOM from 'react-dom';
import 'sanitize.css/sanitize.css';

import { BrowserRouter } from 'react-router-dom';
import LocaleProvider from './containers/LocaleProvider';
import AmplifyProvider from './containers/AmplifyProvider';
import App from './containers/App';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <LocaleProvider>
      <AmplifyProvider>
        <App />
      </AmplifyProvider>
    </LocaleProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
