import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Constants
import { BLACK } from 'constants/colors';
import * as Margins from 'constants/margins';

// Assets
import { ReactComponent as WarningIco } from 'images/shared/warning.svg';

// Style
const Text = styled.span`
  font-weight: bold;
  font-size: 14.2px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${BLACK};
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WarningIcon = styled(WarningIco)`
  width: 18px;
  height: 18px;
  margin-left: ${Margins.REGULAR}px;
`;

function BoldText({ label, warning }) {
  return (
    <>
      <Text>{label}</Text>
      {warning && <WarningIcon />}
    </>
  );
}

BoldText.propTypes = {
  label: PropTypes.string,
  warning: PropTypes.bool,
};

export default BoldText;
