import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import template from '../Accounts/template.csv';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Translations
import { injectIntl } from 'react-intl';
import messages from '../Accounts/messages';

import { ReactComponent as Folders } from '../../images/shared/folders.svg';
import { ReactComponent as Tick } from '../../images/shared/tick.svg';
import { ReactComponent as Data } from '../../images/modal/data.svg';
import { ReactComponent as Download } from '../../images/modal/download.svg';
import { ReactComponent as Export } from '../../images/modal/export.svg';

import info from '../../images/shared/info.png';

// Constants
import styled from 'styled-components';
import { checkCsv } from './api';
import { useHistory } from 'react-router-dom';
import * as Paths from 'constants/paths';
import { setUsers } from 'utils/storage';

const Container = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PrimaryText = styled.p`
  width: 100%;
  font-size: 24.38px;
  text-align: left;
`;

const SecondaryText = styled.p`
  font-size: 16px;
  text-align: left;
  color: #cdcdcd;
`;

const Link = styled.a`
  font-size: 16px;
  text-align: left;
  color: #000000;
  underline-color: #000000;
  text-decoration: underline;
`;

const NextButton = styled.div`
  align-self: center;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  ${props => !props.disabled && 'cursor: pointer;'}
  padding: ${Margins.MEDIUM}px;
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.BATTLESHIP_GREY};
  border-radius: 12px;
  margin-top: 20px;
  border: 1px solid ${Colors.BATTLESHIP_GREY};

  ${props =>
    !props.disabled
      ? `
  &:hover {
    border: 1px solid white;
    background: linear-gradient(
      120deg,
      ${Colors.PURPLE} 1%,
      ${Colors.CYAN} 90%
    );
    color: #ffffff;
  }
  `
      : ''}
`;

const Loader = styled.div`
  background-color: #e5e5e5;
  width: 610px;
  height: 330px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const Border = styled.div`
  width: 570px;
  height: 290px;
  border-width: 2px;
  border-color: ${props => props.borderColor};
  border-style: dashed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const FoldersIcon = styled(Folders)`
  align-self: center;
`;

const TickIcon = styled(Tick)`
  height: 30px;
  width: 30px;
`;

const Tooltip = styled.div`
  -webkit-box-shadow: 1px 5px 12px 1px rgba(204, 49, 255, 0.51);
  box-shadow: 1px 5px 12px 1px rgba(204, 49, 255, 0.51);

  border-radius: 10px;

  padding: 10px;
`;

const AddFile = ({ intl }) => {
  const dragRef = createRef();
  const fileUploader = createRef();

  const history = useHistory();

  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState(undefined);

  const [tooltip, setTooltip] = useState(false);

  const onDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const onDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const onDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files[0].type === 'text/csv') {
        setFile(e.dataTransfer.files[0]);
      }
      e.dataTransfer.clearData();
    }
  };

  const onFileChange = event => {
    setFile(event.target.files[0]);
  };

  const handleClick = () => {
    fileUploader.current.click();
  };

  const handleSubmit = async () => {
    const response = await checkCsv(file);
    const data = await response.json();
    setUsers(data);
    history.push(Paths.USER_TABLE);
  };

  useEffect(() => {
    const dragAndDrop = dragRef.current;

    dragAndDrop.addEventListener('dragenter', onDragEnter);
    dragAndDrop.addEventListener('dragleave', onDragLeave);
    dragAndDrop.addEventListener('dragover', onDragOver);
    dragAndDrop.addEventListener('drop', onDrop);

    return function cleanup() {
      dragAndDrop.removeEventListener('dragenter', onDragEnter);
      dragAndDrop.removeEventListener('dragleave', onDragLeave);
      dragAndDrop.removeEventListener('dragover', onDragOver);
      dragAndDrop.removeEventListener('drop', onDrop);
    };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Container>
        <PrimaryText>{intl.formatMessage(messages.title)}</PrimaryText>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <PrimaryText>{intl.formatMessage(messages.subtitle)}</PrimaryText>
          {/* eslint-disable */}
          <img
            src={info}
            alt="img"
            onClick={() => setTooltip(!tooltip)}
            onKeyDown={() => setTooltip(!tooltip)}
          />
        </div>
        {tooltip ? (
          <Tooltip
            style={{
              width: 300,
              marginLeft: 10,
              background: 'white',
              position: 'absolute',
              top: 150,
              right: 100,
            }}
          >
            {intl.formatMessage(messages.tableHeader)}
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginTop: 10,
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontFamily: 'Muli-Bold',
                }}
              >
                <Download />
                {intl.formatMessage(messages.tableDownload)}
              </div>
              <div style={{ marginLeft: 24 }}>
                {intl.formatMessage(messages.tableDownloadDesc)}
              </div>
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginTop: 10,
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontFamily: 'Muli-Bold',
                }}
              >
                <Data />
                {intl.formatMessage(messages.tableData)}
              </div>
              <div style={{ marginLeft: 24 }}>
                {intl.formatMessage(messages.tableDataDesc)}
              </div>
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginTop: 10,
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontFamily: 'Muli-Bold',
                }}
              >
                <Export />
                {intl.formatMessage(messages.tableExport)}
              </div>
              <div style={{ marginLeft: 24 }}>
                {intl.formatMessage(messages.tableExportDesc)}
              </div>
            </div>
            <div style={{ fontFamily: 'Muli-Bold', marginTop: 20 }}>
              {intl.formatMessage(messages.compileCSV)}
            </div>
            {intl.formatMessage(messages.compileCSVDesc)}
            <ul style={{ listStyleType: 'none' }}>
              <li>- email,</li>
              <li>- team,</li>
              <li>- birthDate,</li>
              <li>- hireDate</li>
              <li>- isTeamLeader</li>
            </ul>
            <button
              style={{
                border: 0,
                borderRadius: 8,
                backgroundColor: '#cc31ff',
                color: 'white',
                alignSelf: 'center',
                width: 280,
                height: 40,
              }}
              onClick={() => setTooltip(!tooltip)}
            >
              {intl.formatMessage(messages.okButton)}
            </button>
          </Tooltip>
        ) : (
          <div style={{ width: 300, marginLeft: 10 }}></div>
        )}

        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <SecondaryText>
            {intl.formatMessage(messages.description)}
          </SecondaryText>
          <Link href={template} download="template.csv">
            {intl.formatMessage(messages.template)}
          </Link>
        </div>
        <Loader>
          <Border
            borderColor={dragging ? '#979797' : '#cdcdcd'}
            ref={dragRef}
            onClick={handleClick}
          >
            {file === undefined ? (
              <>
                <FoldersIcon />
                <SecondaryText
                  style={{
                    width: '70%',
                    textAlign: 'center',
                    height: 60,
                    color: '#000000',
                  }}
                >
                  {intl.formatMessage(messages.howTo)}
                </SecondaryText>
              </>
            ) : (
              <>
                <FoldersIcon />
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                  }}
                >
                  <TickIcon />
                  <SecondaryText
                    style={{
                      marginLeft: 10,
                      textAlign: 'center',
                      color: '#cc31ff',
                    }}
                  >
                    {intl.formatMessage(messages.uploadCorrect)}
                  </SecondaryText>
                </div>

                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <SecondaryText
                    style={{
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      color: '#000000',
                      margin: 0,
                      marginBottom: 10,
                      padding: 0,
                    }}
                  >
                    {file.name}
                  </SecondaryText>
                </div>
                <SecondaryText
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 14,
                    color: '#000000',
                    margin: 0,
                    marginBottom: 10,
                    padding: 0,
                    underlineColor: '#000000',
                    textDecoration: 'underline',
                  }}
                >
                  {intl.formatMessage(messages.reupload)}
                </SecondaryText>
              </>
            )}
          </Border>
        </Loader>
        <input
          type="file"
          id="file"
          ref={fileUploader}
          style={{ display: 'none' }}
          accept=".csv"
          onChange={onFileChange}
        />
        {file === undefined ? (
          <NextButton onClick={handleClick}>
            {intl.formatMessage(messages.upload)}
          </NextButton>
        ) : (
          <NextButton onClick={handleSubmit}>
            {intl.formatMessage(messages.nextButton)}
          </NextButton>
        )}
      </Container>
    </div>
  );
};

AddFile.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(AddFile);
