export const PALE_PEACH = '#ffeab0';
export const SAPPHIRE = '#2449a6';
export const MARIGOLD = '#ffc400';
export const CORAL_PINK = '#fd6b71';
export const ERROR_RED = '#ef2c34';
export const BLACK = '#262626';
export const LIGHT_NAVY = '#122e76';
export const LIGHT_NAVY_10 = 'rgba(18, 46, 118, 0.1)';
export const PALE_ORANGE = '#ffa05b';
export const AQUA_MARINE = '#4aceae';
export const COOL_BLUE = '#3fa8cf';
export const BATTLESHIP_GREY = '#69707d';
export const LIGHT_GREY_BLUE = '#b2b2bd';
export const LIGHT_BLUE_GREY = '#ceced4';
export const DARK_90 = 'rgba(31, 31, 38, 0.9)';
export const LIGHT_GREY = '#f7f7f7';
export const GREY = '#e5e5e5';
export const DARK_SLATE_BLUE = '#1B1759';
export const PURPLE = '#CC31FF';
export const CYAN = '#19f2c8';

export const GRADIENT_NOT_COLLAPSED = 'rgb(100, 130, 220)';

export const DONUTS_50 = '#19f4ab';
export const DONUTS_30_49 = '#61d2ff';
export const DONUTS_10_29 = '#8e9df6';
export const DONUTS_10 = '#ff88e3';
export const DONUTS_0 = '#cc31ff';
