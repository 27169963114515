import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';

// Context
import Context from '../context';

// Constants
import { GREY } from 'constants/colors';
import { WIDTH, HEIGHT } from '../constants';

// Utils
import { getRelativeValue } from '../utils';

// Style
const AxisGrid = styled.div`
  position: absolute;
  background-color: ${GREY};
`;

const XAxisGrid = styled(AxisGrid)`
  width: 1px;
  top: 0;
  bottom: 0;
  left: ${props => props.left}px;
`;

const YAxisGrid = styled(AxisGrid)`
  height: 1px;
  left: 0;
  right: 0;
  bottom: ${props => props.bottom}px;
`;

function Grid() {
  const { xAxis, gridXStep, yAxis, gridYStep } = React.useContext(Context);

  const renderXGrid = () => {
    if (gridXStep) {
      const min = xAxis[0] + gridXStep;
      const max = xAxis[xAxis.length - 1];

      const steps = [];
      for (let step = min; step < max; step += gridXStep) {
        steps.push(getRelativeValue(step, xAxis, WIDTH));
      }

      return (
        <>
          {steps.map(step => (
            <XAxisGrid key={shortid.generate()} left={step} />
          ))}
        </>
      );
    }

    return null;
  };

  const renderYGrid = () => {
    if (gridYStep) {
      const min = yAxis[0] + gridYStep;
      const max = yAxis[yAxis.length - 1];

      const steps = [];
      for (let step = min; step < max; step += gridYStep) {
        steps.push(getRelativeValue(step, yAxis, HEIGHT));
      }

      return (
        <>
          {steps.map(step => (
            <YAxisGrid key={shortid.generate()} bottom={step} />
          ))}
        </>
      );
    }

    return null;
  };

  return (
    <>
      {renderXGrid()}
      {renderYGrid()}
    </>
  );
}

export default Grid;
