import html2canvas from 'html2canvas';
import * as JsPDF from 'jspdf';

const getFileNameForPDF = pathname => {
  const path = pathname.substring(1);
  // da decidere i nomi per ogni sezione
  return `${path}_CanvasJS_Charts.pdf`;
};

const takeScreenshot = async pathname => {
  const target = document.getElementById('screenshot-target');
  target.style.overflowY = 'visible';
  target.style.height = 'auto';

  const canvasWidth = target.offsetWidth;
  const canvasHeight = target.offsetHeight;

  const canvas = await html2canvas(target, {
    onclone: clone => {
      const t = clone.querySelectorAll('[data-testid=card-wrapper]');
      t.forEach(card => {
        const newCard = card;
        newCard.style.boxShadow = 'none';
        newCard.style.border = '2px solid grey';
        return newCard;
      });
    },
  });

  const dataURL = canvas.toDataURL();

  const pdf = new JsPDF({
    orientation: canvasWidth > canvasHeight ? 'l' : 'p',
    unit: 'px',
    format: [canvasWidth, canvasHeight],
  });

  const imageRatio = target.offsetWidth / target.offsetHeight;

  const pdfWidth = pdf.internal.pageSize.getWidth();

  pdf.addImage(dataURL, 'JPG', 0, 0, pdfWidth, pdfWidth / imageRatio);
  pdf.save(getFileNameForPDF(pathname));

  target.style.overflowY = '';
  target.style.height = '';
};

export default takeScreenshot;
