import React from 'react';
import PropTypes from 'prop-types';

// Styled components
import styled from 'styled-components';
import { WIDTH } from './constants';
import { getColors } from './multiLineColors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10%;
  width: ${WIDTH}px;
`;

const Content = styled.div`
  margin-top: 5px;
  width: ${WIDTH / 2}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ColoredCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #222222;
  background: ${props => props.color};
`;

const LegendText = styled.div`
  background-color: ${props => props.backgroundColor}
  margin-left: 10px;
  font-size: 14px;
  padding: 5px;
  border-radius: 20px;
`;

const Legend = props => {
  const { data } = props;
  return (
    <Container>
      {data.map(d => (
        <Content key={d.title}>
          <ColoredCircle color={getColors(d.color)} />
          <LegendText backgroundColor="transparent">{d.title}</LegendText>
        </Content>
      ))}
    </Container>
  );
};

Legend.propTypes = {
  data: PropTypes.array,
};

export default Legend;
