/*
 * Analytics Messages
 *
 * This contains all the text for the Analytics container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Analytics';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Analytics',
  },
  kpisTitle: {
    id: `${scope}.kpisTitle`,
    defaultMessage: 'Activity snapshot',
  },
  kpisSubtitle: {
    id: `${scope}.kpisSubtitle`,
    defaultMessage:
      'I dati salienti nel periodo che hai selezionato: quanti utenti sono stati iscritti, quanti “Review your day”, quanti comportamenti osservati, quanti post nella sezione Tips sono stati fatti. Per ciascuno puoi vedere il trend e la variazione percentuale rispetto al periodo precedente.',
  },
  engagementTitle: {
    id: `${scope}.engagementTitle`,
    defaultMessage: 'Engagement',
  },
  engagementSubtitle: {
    id: `${scope}.engagementSubtitle`,
    defaultMessage:
      'La torta rappresenta la distribuzione degli utenti in base al loro livello di ingaggio. Più gli utenti interagiscono con Yumi e creano contenuti più accumulano punti.',
  },
  activeUsersTitle: {
    id: `${scope}.activeUsersTitle`,
    defaultMessage: 'DAILY active users %',
  },
  activeUsersSubtitle: {
    id: `${scope}.activeUsersSubtitle`,
    defaultMessage:
      'Andamento nel tempo della percentuale di utenti attivi giornalmente, rispetto al totale degli utenti che hanno fatto accesso a Yumi.{br}{br}Gli utenti attivi sono coloro che hanno prodotto almeno un contenuto al giorno in app.',
  },
  weeklyActiveUsersTitle: {
    id: `${scope}.weeklyActiveUsersTitle`,
    defaultMessage: '7 DAYS active users %',
  },
  weeklyActiveUsersSubtitle: {
    id: `${scope}.weeklyActiveUsersSubtitle`,
    defaultMessage:
      'Andamento nel tempo della percentuale di utenti calcolata nei 7 giorni precedenti. Rispetto al totale degli utenti che hanno fatto accesso a Yumi.{br}{br}Gli utenti attivi sono coloro che hanno prodotto almeno un contenuto in app in quei 7 giorni.',
  },
  monthlyActiveUsersTitle: {
    id: `${scope}.monthlyActiveUsersTitle`,
    defaultMessage: '28 DAYS active users %',
  },
  monthlyActiveUsersSubtitle: {
    id: `${scope}.monthlyActiveUsersSubtitle`,
    defaultMessage:
      'Andamento nel tempo della percentuale di utenti calcolata nei 28 giorni precedenti. Rispetto al totale degli utenti che hanno fatto accesso a Yumi.{br}{br}Gli utenti attivi sono coloro che hanno prodotto almeno un contenuto in app nei 28 giorni.',
  },
  pipelineEngagementTitle: {
    id: `${scope}.pipelineEngagementTitle`,
    defaultMessage: 'Pipeline di ingaggio',
  },
  pipelineEngagementSubtitle: {
    id: `${scope}.pipelineEngagementSubtitle`,
    defaultMessage:
      'I "registrati" rappresentano il numero di persone che si sono registrate nel database, mentre "installati" rappresenta gli utenti che hanno fatto accesso all\'app almeno una volta confermando i termini di servizio. Poi, gli "attivi" sono quegli utenti che hanno fatto almeno un\'azione all\'interno dell\'app nel periodo di riferimento ed infine i "contributor" sono quelli che hanno totalizzato un numero di interazioni pari o superiore a 500',
  },
});
