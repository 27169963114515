import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

// Components
import { CommonWrapper, FirstRow, SecondRow, Subtitle } from './CommonStyle';
import AdvancedBar from 'components/AdvancedBar';

// Translations
import { injectIntl } from 'react-intl';
// import messages from './messages';

// Style

function Bar({ subtitle, progressBar, height, roundedBars }) {
  return (
    <CommonWrapper data-testid="SectionKpi-Bar-wrapper">
      <FirstRow>
        <AdvancedBar
          height={height}
          roundedBars={roundedBars}
          bars={progressBar}
        />
      </FirstRow>
      <SecondRow>
        <Subtitle>{subtitle}</Subtitle>
      </SecondRow>
    </CommonWrapper>
  );
}

Bar.propTypes = {
  subtitle: PropTypes.string,
  progressBar: PropTypes.array,
  height: PropTypes.number,
  roundedBars: PropTypes.bool,
};

export default injectIntl(Bar);
