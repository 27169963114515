import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortid from 'shortid';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import {
  CommonWrapper,
  SearchIcon,
  PlusIcon,
  ImportIcon,
  ExportIcon,
} from './styles';

import OriginalCard from 'components/Card';
import OriginalInput from 'components/Input';
import Kpi from 'components/Kpi';
import HeaderIcon from 'components/HeaderIcon';

// Translations
import messages from '../messages';

// Utils
import { format } from 'utils/numbers';

// Constants
import * as Margins from 'constants/margins';

// Style

const Wrapper = styled(CommonWrapper)`
  justify-content: space-between;
`;

const InfoWrapper = styled.form`
  display: flex;
  align-items: center;
`;

const Card = styled(OriginalCard)`
  width: 270px;
`;

const Input = styled(OriginalInput)`
  border: none;
`;

const Kpis = styled.div`
  display: flex;
  margin-left: ${Margins.XXLARGE}px;
`;

const IconsWrapper = styled.div`
  display: flex;
`;

function UsersHeader({ kpis, pathname }) {
  const authContext = useContext(AuthContext);

  const [query, setQuery] = useState('');

  const handleQueryChange = e => {
    setQuery(e.target.value);
  };

  const searchSubmit = async e => {
    e.preventDefault();
    authContext.setQuery(query);
    authContext.setLastUpdate(new Date().getTime());
  };

  return (
    <Wrapper>
      <InfoWrapper onSubmit={searchSubmit}>
        <Card>
          <Input
            placeholder="Cerca utenti"
            value={query}
            onChange={handleQueryChange}
            noBorders
            icon={<SearchIcon onClick={searchSubmit} />}
          />
        </Card>

        <Kpis>
          {kpis.map(kpi => (
            <Kpi
              key={shortid.generate()}
              value={format(kpi.value)}
              label={kpi.label}
              noBorders
              noHorizontalPadding
            />
          ))}
        </Kpis>

        <input type="submit" style={{ display: 'none' }} />
      </InfoWrapper>

      <IconsWrapper>
        <HeaderIcon
          icon={<PlusIcon />}
          label={messages.add}
          onClick={() => console.log('create user')}
        />
        <HeaderIcon
          icon={<ImportIcon />}
          label={messages.import}
          onClick={() => console.log('import', pathname)}
        />
        <HeaderIcon
          icon={<ExportIcon />}
          label={messages.export}
          onClick={() => console.log('export', pathname)}
        />
      </IconsWrapper>
    </Wrapper>
  );
}

UsersHeader.propTypes = {
  kpis: PropTypes.array,
  pathname: PropTypes.string,
};

UsersHeader.defaultProps = {
  kpis: [],
};

export default UsersHeader;
