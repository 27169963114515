/**
 *
 * Kpi
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import { GREY, BLACK } from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.div`
  ${props =>
    !props.noBorders &&
    `
      border-right: 1px solid ${GREY};
    `};
  padding: ${Margins.SMALL}px
    ${props => (props.noHorizontalPadding ? 0 : Margins.REGULAR)}px;
  min-width: 82px;
  max-width: 106px;
  height: 54px;
  user-select: none;
`;

const Value = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: ${BLACK};
`;

const Label = styled.div`
  font-size: 10px;
  line-height: 10px;
  color: ${BLACK};
  margin-top: 6px;
`;

function Kpi({ className, value, label, ...props }) {
  return (
    <Wrapper className={className} {...props} data-testid="kpi-wrapper">
      <Value data-testid="kpi-value">{value}</Value>
      <Label data-testid="kpi-label">{label}</Label>
    </Wrapper>
  );
}

Kpi.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  noHorizontalPadding: PropTypes.bool,
  noBorders: PropTypes.bool,
};

export default Kpi;
