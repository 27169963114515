/* Public Routes */
export const VIRAL_SHARING = '/invitation/:slug';
export const MAGIC_LINK = '/auth/:id';
export const LOGIN = '/login';

/* Authenticated Routes */
export const HOME = '/';
export const BRILLIANCE = '/brilliance';
export const MOOD = '/mood';
export const SKILLS = '/skills';
export const TEAMCARD = '/teamcard';
export const ANALYTICS = '/analytics';
export const USERS = '/users';
export const ACCOUNTS = '/accounts';
export const ADD_CSV = '/csv';
export const USER_TABLE = '/userTable';
export const IMPORT_SUCCESS = '/invitedSuccessfully';
export const ORGANIZATION = '/organization';
export const LOGOUT = '/logout';
