/**
 *
 * Skills
 *
 */

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import { Helmet } from 'react-helmet';
import SectionTitle from 'containers/SectionTitle';
import Observed from './Observed';
import Suggested from './Suggested';
import Groups from './Groups';
import Heatmap from './Heatmap';

// Translations
import { injectIntl } from 'react-intl';
import messages from './messages';

// Data
import tabs from './tabs';
import periods from 'utils/periodGenerator';

// APIs
import { fetchHeaderKpis } from './api';

// Constants
import * as Margins from 'constants/margins';
import { GROUPS, HEATMAP, OBSERVED, SUGGESTED } from 'constants/tabs';

// Style
const Content = styled.div`
  padding: 40px ${Margins.XXLARGE}px;
  height: calc(100% - 188px);
  overflow: auto;
`;

export function Skills({ intl }) {
  // Context
  const { setKpis, unitId, tab, period } = useContext(AuthContext);

  useEffect(() => {
    const fetchHeaderData = async () => {
      const { start, end } = periods[period];

      try {
        const response = await fetchHeaderKpis(unitId, start, end);
        const data = await response.json();
        setKpis(data);
      } catch {
        setKpis([]);
      }
    };
    fetchHeaderData();
  }, [unitId, period]);

  const renderObservedTab = () => {
    if (tab === OBSERVED) {
      return <Observed />;
    }
    return null;
  };

  const rednerSuggestedTab = () => {
    if (tab === SUGGESTED) {
      return <Suggested />;
    }
    return null;
  };

  const renderGroupsTab = () => {
    if (tab === GROUPS) {
      return <Groups />;
    }
    return null;
  };

  const renderHeatmapTab = () => {
    if (tab === HEATMAP) {
      return <Heatmap />;
    }
    return null;
  };

  return (
    <>
      <Helmet>
        <title>Skills</title>
        <meta name="description" content="Description of Skills" />
      </Helmet>

      <SectionTitle
        title={intl.formatMessage(messages.title)}
        tabs={tabs}
        periods={periods}
      />

      <Content>
        {renderObservedTab()}
        {rednerSuggestedTab()}
        {renderGroupsTab()}
        {renderHeatmapTab()}
      </Content>
    </>
  );
}

Skills.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(Skills);
