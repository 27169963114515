import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Assets
import { ReactComponent as OriginalErrorIcon } from 'images/shared/error.svg';

// Translations
import { FormattedMessage } from 'react-intl';
import messages from './messages';

// Constants
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Icon = styled(OriginalErrorIcon)`
  width: 64px;
  height: 64px;
`;

const Tip = styled.span`
  margin-top: ${Margins.REGULAR}px;
  width: 70%;
  text-align: center;
`;

function Error({ className, tip }) {
  return (
    <Wrapper className={className} data-testid="error-wrapper">
      <Icon data-testid="error-icon" />
      <Tip data-testid="error-tip">
        <FormattedMessage {...tip} />
      </Tip>
    </Wrapper>
  );
}

Error.propTypes = {
  className: PropTypes.string,
  tip: PropTypes.object,
};

Error.defaultProps = {
  tip: messages.tip,
};

export default Error;
