/*
 * Error Messages
 *
 * This contains all the text for the Error component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Error';

export default defineMessages({
  tip: {
    id: `${scope}.tip`,
    defaultMessage: 'Errore durante il caricamento dei dati.',
  },
  customTip: {
    id: `${scope}.customTip`,
    defaultMessage: 'test',
  },
});
