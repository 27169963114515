import React, { useContext, useState, useCallback } from 'react';

// Context
import Context from '../context';

// Components
import Bubble from './Bubble';

// Constants
import { WIDTH, HEIGHT } from '../constants';

// Utils
import { getRelativeValue } from '../utils';

const MIN_PERCENTAGE = 25;
const MAX_PERCENTAGE = 75;

function Bubbles() {
  const { data, yAxis, xAxis } = useContext(Context);

  const [hiddenBubbles, setHiddenBubbles] = useState([]);

  // Get minimum and maximum values
  const values = data.map(item => item.value);
  const min = values.length > 0 ? Math.min(...values) : 0;
  const max = values.length > 0 ? Math.max(...values) : 0;

  const getPercentage = value => {
    let percentage = 0;
    if (max - min > 0) {
      percentage =
        ((value - min) * (MAX_PERCENTAGE - MIN_PERCENTAGE)) / (max - min);
    }

    return MIN_PERCENTAGE + percentage;
  };

  const getMainBubble = useCallback(
    index => {
      const mainBubble = data[index];
      const mainSize = getPercentage(mainBubble.value);
      const mainCenterX = getRelativeValue(mainBubble.x, xAxis, WIDTH);
      const mainCenterY = getRelativeValue(mainBubble.y, yAxis, HEIGHT);

      return {
        ...data[index],
        size: mainSize,
        centerX: mainCenterX,
        xLimit: {
          from: mainCenterX - mainSize / 2,
          to: mainCenterX + mainSize / 2,
        },
        yLimit: {
          from: mainCenterY - mainSize / 2,
          to: mainCenterY + mainSize / 2,
        },
      };
    },
    [data],
  );

  const splitBubbles = index => {
    const mainBubble = getMainBubble(index);

    // Get all hidden bubbles behind this
    const bubbles = data
      .filter((bubble, i) => {
        // Throw away all NON hidden bubbles
        const bubbleSize = getPercentage(bubble.value);
        const isSmaller = bubbleSize <= mainBubble.size;

        const bubbleCenterX = getRelativeValue(bubble.x, xAxis, WIDTH);
        const isInsideX =
          bubbleCenterX > mainBubble.xLimit.from &&
          bubbleCenterX < mainBubble.xLimit.to;

        const bubbleCenterY = getRelativeValue(bubble.y, yAxis, HEIGHT);
        const isInsideY =
          bubbleCenterY > mainBubble.yLimit.from &&
          bubbleCenterY < mainBubble.yLimit.to;

        return i !== index && isSmaller && isInsideX && isInsideY;
      })
      .map(bubble => {
        // Store size info inside bubble
        const bubbleSize = getPercentage(bubble.value);

        return {
          ...bubble,
          size: bubbleSize,
        };
      })
      .map((bubble, i, filteredBubbles) => {
        // Calculate bubble's left position based on main bubble's values and prev bubbles size
        const prevBubbleCenterX = filteredBubbles
          .filter((_, j) => j < i)
          .reduce(
            (total, current) => total + current.size,
            mainBubble.centerX + mainBubble.size / 2,
          );

        return {
          ...bubble,
          left: prevBubbleCenterX + bubble.size / 2,
          zIndex: data.length,
        };
      });

    setHiddenBubbles(bubbles);
  };

  return (
    <>
      {data.map(({ value, y, x, ...bubble }, index) => {
        const hiddenBubble = hiddenBubbles.find(
          hidden => hidden.title === bubble.title,
        );

        const left = hiddenBubble
          ? hiddenBubble.left
          : getRelativeValue(x, xAxis, WIDTH);

        const handleEnter = hiddenBubble
          ? () => {}
          : () => splitBubbles(index, !!hiddenBubble);

        return (
          <Bubble
            key={bubble.title}
            size={getPercentage(value)}
            bottom={getRelativeValue(y, yAxis, HEIGHT)}
            left={left}
            value={value}
            zIndex={hiddenBubble ? hiddenBubble.zIndex : index}
            {...bubble}
            handleEnter={handleEnter}
          />
        );
      })}
    </>
  );
}

export default Bubbles;
