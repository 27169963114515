import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Colors from '../../constants/colors';

const PathContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const PathText = styled.div`
  font-family: Muli-SemiBold;
  font-size: 14px;
  color: ${Colors.PURPLE};
`;

const Path = props => {
  const { data, selected, onPathPress } = props;

  const setCurrentLevel = (level, key) => {
    // Select current level and reset all the followings
    const nextSelected = selected.slice(0, level);
    nextSelected[level] = key;
    onPathPress(nextSelected);
  };

  const getSelectedData = (array, level) => {
    let result = [];
    array.forEach(element => {
      if (selected.includes(element.id)) {
        result = [...result, { ...element, level }];
      }
      if (element.children.length > 0) {
        result = [...result, ...getSelectedData(element.children, level + 1)];
      }
    });
    return result;
  };

  return (
    <PathContainer>
      {getSelectedData(data, 0).map((item, index) => (
        <PathText
          onClick={() => setCurrentLevel(item.level, item.id)}
          key={item.name}
        >
          {item.name}
          <span style={{ marginLeft: 5, marginRight: 5 }}>
            {index < selected.length - 1 ? '>' : ''}
          </span>
        </PathText>
      ))}
    </PathContainer>
  );
};

Path.propTypes = {
  onPathPress: PropTypes.func,
  selected: PropTypes.array,
  data: PropTypes.array,
};

export default Path;
