export const getUserdata = () => JSON.parse(localStorage.getItem('userdata'));

export const setUserdata = userdata => {
  localStorage.setItem('userdata', JSON.stringify(userdata));
};

export const deleteUserdata = () => {
  localStorage.removeItem('userdata');
};

export const getTenant = () => JSON.parse(localStorage.getItem('tenant'));

export const setTenant = tenant => {
  localStorage.setItem('tenant', JSON.stringify(tenant));
};

export const deleteTenant = () => {
  localStorage.removeItem('tenant');
};

export const setOrganizationUnit = unitId => {
  localStorage.setItem('organizationUnit', JSON.stringify(unitId));
};

export const getOrganizationUnit = () =>
  JSON.parse(localStorage.getItem('organizationUnit'));

export const deleteOrganizationUnit = () =>
  localStorage.removeItem('organizationUnit');

export const setBenchmark = benchmark => {
  localStorage.setItem('benchmark', JSON.stringify(benchmark));
};

export const getBenchmark = () => JSON.parse(localStorage.getItem('benchmark'));

export const deleteBenchmark = () => localStorage.removeItem('benchmark');

export const setUsers = data => {
  localStorage.setItem('invitedUsers', JSON.stringify(data));
};

export const getUsers = () => JSON.parse(localStorage.getItem('invitedUsers'));
