import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import BoldText from 'components/Table/Cell/BoldText';

// Constants
import * as Margins from 'constants/margins';

// Style
const ColorCircle = styled.div`
  background-color: ${props => props.color};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 100%;
  margin-left: ${Margins.MEDIUM}px;
  margin-right: ${Margins.XLARGE}px;
`;

function Legend({ color, size, label }) {
  return (
    <>
      <ColorCircle data-testid="Table-color" color={color} size={size} />
      <BoldText label={label} />
    </>
  );
}

Legend.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
  label: PropTypes.string.isRequired,
};

Legend.defaultProps = {
  size: 12,
};

export default Legend;
