import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Constants
import * as Margins from 'constants/margins';
import { BATTLESHIP_GREY } from 'constants/colors';

// Style
const Text = styled.span`
  font-size: 12px;
  line-height: ${Margins.LARGE}px;
  letter-spacing: 0.11px;
  color: ${BATTLESHIP_GREY};
`;

const TextWrapper = styled.div`
  font-size: 12px;
  line-height: ${Margins.LARGE}px;
  letter-spacing: 0.11px;
  color: ${BATTLESHIP_GREY};
  display: inline;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 32px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &:hover .tooltip-wrapper {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipWrapper = styled.div`
  position: absolute;
  visibility: hidden;
  top: 40px;
`;

const Tooltip = styled.span`
  display: block;
  z-index: 2;
  position: relative;
  right: 20%;
  width: 140px;
  height: auto;
  color: #000000;
  background: #ffffff;
  line-height: 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 4px 3px 10px #757575;
  padding: 5px;
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-bottom: 8px solid #ffffff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`;

function SimpleText({ label, enablePopover }) {
  if (!enablePopover) {
    return <Text>{label}</Text>;
  }
  return (
    <TextWrapper className="has-tooltip">
      {label.substring(0, 50)}
      <TooltipWrapper className="tooltip-wrapper">
        <Tooltip className="tooltip">{label}</Tooltip>
      </TooltipWrapper>
    </TextWrapper>
  );
}

SimpleText.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enablePopover: PropTypes.bool,
};

export default SimpleText;
