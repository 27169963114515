import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
// import TrendKPI from 'components/TrendKPI';

// Constants
import { BLACK } from 'constants/colors';

// Style
const Label = styled.span`
  font-size: 14.2px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${BLACK};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Trend({ label }) {
  return (
    <>
      <Label>{label}</Label>
      {/* <TrendKPI trend={trend > 0 ? 'up' : 'down'} percentage={trend} /> */}
    </>
  );
}

Trend.propTypes = {
  label: PropTypes.string,
  // trend: PropTypes.number,
};

export default Trend;
