import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import SubsectionTitle from 'components/SubsectionTitle';
import { ClipLoader } from 'react-spinners';
import OriginalTable from 'components/Table';
import Error from 'components/Error';

// Columns
import { peopleColumns } from '../columns';

// Translations
import { injectIntl } from 'react-intl';
import messages from '../messages';

// APIs
import { fetchGroupsTable } from '../api';

// Images
import { ReactComponent as People } from 'images/shared/people.svg';

// Constants
import periods from 'utils/periodGenerator';
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const icon = css`
  width: 50px;
  height: 50px;
  color: ${Colors.BATTLESHIP_GREY};
`;

const PeopleIcon = styled(People)`
  ${icon};
`;

const Content = styled.div`
  flex: 1;
  margin-left: ${Margins.XXLARGE}px;
  display: flex;
  justify-content: center;
`;

function Table({ intl, limit }) {
  const {
    unitId,
    period,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  } = useContext(AuthContext);

  const { start, end } = periods[period];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    let canceled = false;
    const fetchData = async () => {
      setLoading(true);
      setData([]);
      try {
        const response = await fetchGroupsTable(
          unitId,
          start,
          end,
          benchmarkOrganizationUnitId,
          benchmarkStart,
          benchmarkEnd,
        );
        const json = await response.json();
        if (!canceled) {
          setLoading(false);
          setData(json);
        }
      } catch {
        if (!canceled) {
          setLoading(false);
          setError(true);
        }
      }
    };

    fetchData();

    return () => {
      canceled = true;
    };
  }, [
    unitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  ]);

  const renderLoading = () => {
    if (loading) {
      return <ClipLoader size={20} color={Colors.LIGHT_NAVY} />;
    }
    return null;
  };

  const renderTable = () => {
    if (!loading && !error && data) {
      return (
        <OriginalTable
          columns={peopleColumns}
          data={data}
          borders
          limit={limit}
        />
      );
    }
    return null;
  };

  const renderError = () => {
    if (!loading && error) {
      return <Error />;
    }
    return null;
  };

  return (
    <Wrapper>
      <SubsectionTitle
        icon={<PeopleIcon />}
        title={intl.formatMessage(messages.peopleTableTitle)}
        body={intl.formatMessage(messages.peopleTableSubtitle)}
      />

      <Content>
        {renderLoading()}
        {renderTable()}
        {renderError()}
      </Content>
    </Wrapper>
  );
}

Table.propTypes = {
  intl: PropTypes.object,
  limit: PropTypes.number,
};

export default injectIntl(Table);
