/**
 *
 * Tabs
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Translations
import { FormattedMessage } from 'react-intl';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.section`
  height: 48px;
  display: flex;
`;

const Tab = styled.div`
  font-weight: bold;
  font-size: 14.2px;
  color: ${props =>
    props.active ? Colors.LIGHT_NAVY : Colors.LIGHT_GREY_BLUE};
  text-transform: uppercase;
  padding: 0 ${Margins.LARGE}px;
  line-height: 20px;
  letter-spacing: 1px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${props =>
    props.active ? Colors.LIGHT_NAVY : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  .label {
    white-space: nowrap;
  }

  &:hover {
    color: ${Colors.LIGHT_NAVY};
    border-bottom-color: ${Colors.LIGHT_NAVY};
  }
`;

function Tabs({ className, data, active, onChange }) {
  return (
    <Wrapper className={className}>
      {data.map(tab => (
        <Tab
          data-testid="tabs-tab"
          key={tab.key}
          active={active === tab.key}
          onClick={() => onChange(tab.key)}
        >
          <span className="label">
            <FormattedMessage {...tab.name} />
          </span>
        </Tab>
      ))}
    </Wrapper>
  );
}

Tabs.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  active: PropTypes.string,
  onChange: PropTypes.func,
};

export default Tabs;
