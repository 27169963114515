import { createGlobalStyle } from 'styled-components';

// Fonts
import muliExtraLight from 'fonts/Muli-ExtraLight.ttf';
import muliLight from 'fonts/Muli-Light.ttf';
import muliRegular from 'fonts/Muli-Regular.ttf';
import muliSemiBold from 'fonts/Muli-SemiBold.ttf';
import muliBold from 'fonts/Muli-Bold.ttf';
import muliExtraBold from 'fonts/Muli-ExtraBold.ttf';
import muliBlack from 'fonts/Muli-Black.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Muli-ExtraLight';
    src: url(${muliExtraLight}) format('truetype');
    font-weight: 200;
  }

  @font-face {
    font-family: 'Muli-Light';
    src: url(${muliLight}) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Muli-Regular';
    src: url(${muliRegular}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Muli-SemiBold';
    src: url(${muliSemiBold}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Muli-Bold';
    src: url(${muliBold}) format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Muli-ExtraBold';
    src: url(${muliExtraBold}) format('truetype');
    font-weight: 800;
  }

  @font-face {
    font-family: 'Muli-Black';
    src: url(${muliBlack}) format('truetype');
    font-weight: 900;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow: hidden;
  }

  body {
    font-family: 'Muli-Regular', sans-serif;
  }

  body.fontLoaded {
    font-family: 'Muli-Regular', sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
  }
  .positive-mood div span{
    color: #19f2c8 !important;
  }
  .negative-mood div span{
    color: #ca32fe !important;
  }
`;

export default GlobalStyle;
