/**
 *
 * Checkbox
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Assets
import { ReactComponent as OriginalCheckIcon } from 'images/shared/check.svg';

// Constants
import { BLACK } from 'constants/colors';
import * as Margins from 'constants/margins';
import * as CheckboxStatuses from 'components/Checkbox/selectStatuses';

import checkboxThemeShape from './checkboxTheme';

// Style
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const SquareWrapper = styled.div`
  padding: 3px;
`;

const Square = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${props => props.theme.inactiveColor};
  border-radius: 2px;
  ${props =>
    props.selectStatus === CheckboxStatuses.SINGLESELECTION &&
    `
      background-color: ${props.theme.fill};
      border: 2px solid ${props.theme.activeColor};
    `};
  ${props =>
    (props.selectStatus === CheckboxStatuses.PARTIALSELECTION ||
      props.selectStatus === CheckboxStatuses.COMPLETESELECTION) &&
    `
    background-color: ${props.theme.fill};
    border-color: ${props.theme.fill};
    `}
`;

const Label = styled.span`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: ${BLACK};
  margin-left: ${Margins.REGULAR}px;
  text-transform: capitalize;
  user-select: none;
`;

const CheckIcon = styled(OriginalCheckIcon)`
  width: 100%;
  height: 100%;
`;

const MinusIcon = styled.div`
  color: ${props => props.color};
`;

function Checkbox({
  selectStatus,
  onChange,
  label,
  className,
  checkboxTheme,
  hidden,
}) {
  return (
    <Wrapper
      data-testid="Checkbox-wrapper"
      onClick={onChange}
      hidden={hidden}
      className={className}
    >
      <SquareWrapper>
        <Square
          data-testid="Checkbox-square"
          selectStatus={selectStatus}
          theme={checkboxTheme}
        >
          {selectStatus === CheckboxStatuses.PARTIALSELECTION && (
            <MinusIcon
              data-testid="Checkbox-icon-minus"
              color={checkboxTheme.activeColor}
            >
              &#8722;
            </MinusIcon>
          )}
          {selectStatus === CheckboxStatuses.COMPLETESELECTION && (
            <CheckIcon fill={checkboxTheme.activeColor} />
          )}
          {selectStatus === CheckboxStatuses.SINGLESELECTION && (
            <CheckIcon fill={checkboxTheme.activeColor} />
          )}
        </Square>
      </SquareWrapper>
      {label && <Label>{label}</Label>}
    </Wrapper>
  );
}

Checkbox.propTypes = {
  selectStatus: PropTypes.oneOf([
    false,
    CheckboxStatuses.SINGLESELECTION,
    CheckboxStatuses.PARTIALSELECTION,
    CheckboxStatuses.COMPLETESELECTION,
  ]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  checkboxTheme: PropTypes.shape(checkboxThemeShape),
};

Checkbox.defaultProps = {
  checkboxTheme: {
    activeColor: BLACK,
    inactiveColor: BLACK,
    fill: 'transparent',
  },
};

export default Checkbox;
