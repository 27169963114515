export default {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_AWS_AUTH_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_SRP_AUTH',
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
    },
  },
};

export const oauth = {
  domain: 'feedbackloop-dev.auth.us-east-1.amazoncognito.com',
  scope: ['email', 'openid'],
  redirectSignIn:
    'https://feedbackloop-dev.s3.amazonaws.com/open/federatedSignIn',
  redirectSignOut:
    'https://feedbackloop-dev.s3.amazonaws.com/open/federatedSignOut',
  // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
  responseType: 'token',
};
