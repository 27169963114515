/**
 *
 * Avatar
 *
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import OriginalAvatar from 'react-avatar';

// Utils
import { getImageURI } from 'utils/image';

// Style
const Wrapper = styled(OriginalAvatar)`
  font-family: 'Muli-Regular', sans-serif;
`;

const S3Image = styled.img`
  min-width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => (props.round ? '50%' : '0')};
`;

const FakeImg = styled.div`
  min-width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => (props.round ? '50%' : '0')};
`;

function Avatar(props) {
  const [loading, setLoading] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getSrc = async () => {
      if (props.src) {
        setLoading(true);
        try {
          const presignedURL = await getImageURI(props.src);
          setAvatarSrc(presignedURL);
        } catch {
          setError(true);
        } finally {
          setLoading(false);
        }
      }
    };
    getSrc();
  }, []);

  if (error || !props.src) {
    return <Wrapper {...props} textSizeRatio={1.5} />;
  }

  if (loading) {
    return <FakeImg size={props.size} round={props.round} />;
  }

  return (
    <S3Image
      src={avatarSrc}
      size={props.size}
      round={props.round}
      onLoad={() => setLoading(false)}
      onError={() => setError(true)}
    />
  );
}

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number,
  name: PropTypes.string,
  round: PropTypes.bool,
};

Avatar.defaultProps = {
  size: 24,
  round: true,
};

export default Avatar;
