/**
 *
 * SectionTitle
 *
 */

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import OriginalTabs from 'components/Tabs';
import PeriodSwitch from 'components/PeriodSwitch';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Utils
import { hexToRGBA } from 'utils/colors';

// Style
const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 108px;
  padding: 0 ${Margins.XXLARGE}px;
  border-bottom: 1px solid ${hexToRGBA(Colors.BLACK, 0.12)};
`;

const Title = styled.h1`
  min-width: 270px;
  margin: 0 0 ${Margins.MEDIUM}px 0;
  color: ${props => props.color};
  font-weight: normal;
  font-size: 34.5px;
  letter-spacing: 0.25px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Tabs = styled(OriginalTabs)`
  margin-left: ${Margins.XXLARGE}px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export function SectionTitle({
  className,
  icon,
  title,
  color,
  hideTabs,
  tabs,
  hidePeriodSwitch,
  periods,
  children,
}) {
  // Context
  const authContext = useContext(AuthContext);

  // Preselect first tab and first period on mount
  useEffect(() => {
    if (!hideTabs && tabs.length) {
      authContext.setTab(tabs[0].key);
    }

    if (!hidePeriodSwitch && periods.length) {
      authContext.setPeriod(periods.length - 1);
    }
  }, []);

  const onTabChange = selectedTab => {
    authContext.setTab(selectedTab);
  };

  const onPeriodChange = selectedPeriod => {
    authContext.setPeriod(selectedPeriod);
  };

  return (
    <AuthContext.Consumer>
      {({ tab, period }) => (
        <Wrapper className={className}>
          {icon}
          <Title color={color}>{title}</Title>

          <Content className="content">
            {!hideTabs && (
              <Tabs data={tabs} active={tab} onChange={onTabChange} />
            )}

            {!hidePeriodSwitch && (
              <PeriodSwitch
                periods={periods}
                selected={period}
                onChange={onPeriodChange}
              />
            )}
          </Content>

          {children}
        </Wrapper>
      )}
    </AuthContext.Consumer>
  );
}

SectionTitle.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  color: PropTypes.string,
  hideTabs: PropTypes.bool,
  tabs: PropTypes.array,
  hidePeriodSwitch: PropTypes.bool,
  periods: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SectionTitle.defaultProps = {
  color: Colors.BATTLESHIP_GREY,
  hideTabs: false,
  tabs: [],
  hidePeriodSwitch: false,
  periods: [],
};

export default SectionTitle;
