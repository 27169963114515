import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import Avatar from 'components/Avatar';

// Constants
import * as Margins from 'constants/margins';
import { BLACK, BATTLESHIP_GREY } from 'constants/colors';

// Style
const Arrow = styled.span`
  font-size: 16px;
  margin-right: 8px;
  color: ${BATTLESHIP_GREY};
  cursor: pointer;
  user-select: none;
`;

const Info = styled.div`
  margin-left: 8px;
  overflow: hidden;
`;

const Truncate = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Name = styled(Truncate)`
  font-weight: bold;
  font-size: 14.2px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${BLACK};
`;

const Email = styled(Truncate)`
  font-size: 12px;
  line-height: ${Margins.LARGE}px;
  letter-spacing: 0.11px;
  color: ${BATTLESHIP_GREY};
`;

function User({ expanded, onExpand, avatar, displayName, email }) {
  const renderArrow = () => {
    if (expanded) {
      return <Arrow onClick={onExpand}>&#x25B4;</Arrow>;
    }
    return <Arrow onClick={onExpand}>&#x25BE;</Arrow>;
  };

  return (
    <>
      {renderArrow()}
      <Avatar src={avatar} name={displayName} round size={24} />
      <Info>
        {displayName && <Name title={displayName}>{displayName}</Name>}
        {email && <Email title={email}>{email}</Email>}
      </Info>
    </>
  );
}

User.propTypes = {
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  avatar: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
};

export default User;
