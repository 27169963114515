import React from 'react';

// Components
import Bubbles from './Bubbles';
import Table from './Table';

function Groups() {
  return (
    <>
      <Bubbles />
      <Table />
    </>
  );
}

export default Groups;
