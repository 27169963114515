import * as Columns from 'components/Table/columns';

const columns = [
  {
    key: 'points',
    label: 'Points',
    span: 1,
    type: Columns.LEGEND,
    visible: true,
  },
  {
    key: 'trend',
    label: 'Trend',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'benchmark',
    label: 'Benchmark',
    span: 1,
    type: Columns.TEXT,
    visible: false,
  },
];

export default columns;
