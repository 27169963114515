// Constants
import * as Colors from 'constants/colors';

export const simpleBar = [
  { color: Colors.AQUA_MARINE, zIndex: 2 },
  { color: Colors.LIGHT_BLUE_GREY, zIndex: 1 },
];

export const advancedBar = [
  { color: Colors.SAPPHIRE, zIndex: 3 },
  { color: Colors.COOL_BLUE, zIndex: 2 },
  { color: Colors.LIGHT_BLUE_GREY, zIndex: 1 },
];
