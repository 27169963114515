import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Context
import { FacetConsumer } from './context';
import Row from './Row';

// Assets
import { ReactComponent as NextArrowIcon } from 'images/shared/next-arrow.svg';
import { ReactComponent as PlusIcon } from 'images/shared/plus.svg';

// Constants
import * as Colors from 'constants/colors';

// Style
const Wrapper = styled.div`
  height: 100%;
  ${props =>
    props.flexible
      ? `
      flex: 1;
      max-width: 25%;
    `
      : `
      min-width: ${props.width};
    `};
`;

const Header = styled.header`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${Colors.BATTLESHIP_GREY};
  height: 32px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(38, 38, 38, 0.12);
`;

const Rows = styled.div`
  height: ${props => (props.visibleHeader ? 'calc(100% - 32px)' : '100%')};
`;

const Icon = styled(NextArrowIcon)`
  width: 18px;
  height: 18px;
`;

const CustomPlusIcon = styled(PlusIcon)`
  width: 18px;
  height: 18px;
`;

function Column({ level, data }) {
  const renderColumnHeader = (visibleHeader, area) => {
    if (visibleHeader && area) {
      return (
        <Header data-testid="facetnavigation-column-header">
          {area.label}
        </Header>
      );
    }
    return null;
  };

  const renderRow = (row, selected, onClick) => {
    const leaders = row.leaders
      ? row.leaders.map(leader => leader.displayName).join(', ')
      : '';

    return (
      <Row
        className={row.id === selected[level] ? 'selected' : ''}
        key={row.id}
        onClick={() => onClick(level, row.id)}
        name={row.name}
        leaders={leaders}
        icon={row.children.length > 0 ? <Icon /> : undefined}
        showAvatar={level > 0}
      />
    );
  };

  const renderExtraRow = (extraRow, lastlevel, onExtraRowClick, selected) => {
    if (extraRow && lastlevel) {
      return (
        <Row
          className="add-new"
          onClick={() => onExtraRowClick(selected)}
          name="Aggiungi nuovo"
          icon={<CustomPlusIcon />}
          showAvatar={false}
        />
      );
    }

    return null;
  };

  const renderChildren = (row, extraRow) => {
    // If extraRow, show children also if is empty
    // If NOT extraRow - show children ONLY if it's NOT empty
    if (
      row &&
      row.children &&
      (extraRow || (!extraRow && row.children.length > 0))
    ) {
      return <Column level={level + 1} data={row.children} />;
    }

    return null;
  };

  return (
    <FacetConsumer>
      {({
        areas = [],
        onSelect,
        selected = [],
        extraRow,
        onExtraRowClick,
        flexible,
        columnWidth,
        showLabels,
      } = {}) => {
        const area = areas[level];
        const selectedRow = data.find(row => row.id === selected[level]);
        const lastlevel = level === selected.length;
        const visibleHeader = showLabels && area.label;

        return (
          <>
            <Wrapper
              className="column"
              flexible={flexible}
              width={columnWidth}
              data-testid="facetnavigation-column"
            >
              {renderColumnHeader(visibleHeader, area)}

              <Rows visibleHeader={visibleHeader}>
                {data.map(row => renderRow(row, selected, onSelect))}
                {renderExtraRow(extraRow, lastlevel, onExtraRowClick, selected)}
              </Rows>
            </Wrapper>
            {renderChildren(selectedRow, extraRow)}
          </>
        );
      }}
    </FacetConsumer>
  );
}

Column.propTypes = {
  level: PropTypes.number,
  data: PropTypes.array,
};

Column.defaultProps = {
  level: 0,
  data: [],
};

export default Column;
