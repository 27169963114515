import React from 'react';

// Components
// import Kpis from './Kpis';
// import Bubbles from './Bubbles';
import Table from './Table';

// Constants
import * as Tables from '../tables';

function Organization() {
  return (
    <>
      {/* <Kpis /> */}
      {/* <Bubbles /> */}
      <Table type={Tables.CATEGORIES} />
      <Table type={Tables.BEHAVIOR} />
      {/* <Table type={Tables.UNITS} /> */}
    </>
  );
}

export default Organization;
