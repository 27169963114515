import moment from 'moment';

const MONTHS = 24;
const now = moment();
const data = [];

for (let i = 0; i < MONTHS; i += 1) {
  const period = now.clone().subtract(i, 'months');
  if (i === 0) {
    data.unshift({
      label: 'Last 8 weeks',
      start: period
        .clone()
        .subtract(8, 'weeks')
        .format('YYYY-MM-DD'),
      end: period.format('YYYY-MM-DD'),
    });
  } else {
    const label = period.startOf('month').format('MMMM YYYY');
    data.unshift({
      label: label.charAt(0).toUpperCase() + label.slice(1),
      start: period.startOf('month').format('YYYY-MM-DD'),
      end: period.endOf('month').format('YYYY-MM-DD'),
    });
  }
}

export default data;
