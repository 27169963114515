/**
 *
 * OverlappingDots
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import * as Colors from 'constants/colors';

// Style
const Wrapper = styled.div`
  width: ${props => props.size * 2}px;
  height: ${props => props.size}px;
  position: relative;
`;

const Dot = styled.div`
  position: absolute;
  top: 0;
  left: ${props => props.left}px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: ${Colors.COOL_BLUE};
  border-radius: 100%;
  mix-blend-mode: multiply;
`;

function formatValue(value) {
  if (value > 100) {
    return 100;
  }
  if (value < 0) {
    return 0;
  }
  return value;
}

function OverlappingDots({ value, size }) {
  const starting = size / 2;
  const formattedValue = formatValue(value);
  const delta = (starting * formattedValue) / 100;

  return (
    <Wrapper data-testid="dots-wrapper" size={size}>
      <Dot data-testid="dot" size={size} left={starting - delta} />
      <Dot data-testid="dot" size={size} left={starting + delta} />
    </Wrapper>
  );
}

OverlappingDots.propTypes = {
  value: PropTypes.number,
  size: PropTypes.number,
};

OverlappingDots.defaultProps = {
  size: 16,
};

export default OverlappingDots;
