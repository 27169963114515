import { apiGet } from 'utils/request';
import HOST from 'utils/globals';

const addParams = (url, organizationUnitId, start, end) => {
  // Add ? at the end if not exists
  let urlCopy = url.endsWith('?') ? url : `${url}?`;

  if (organizationUnitId) {
    urlCopy += `organizationUnitId=${organizationUnitId}&`;
  }

  if (start) {
    urlCopy += `start=${start}&`;
  }

  if (end) {
    urlCopy += `end=${end}&`;
  }

  // Return without trailing &
  return urlCopy.slice(0, -1);
};

export const fetchKpis = (organizationUnitId, start, end) => {
  const url = addParams(
    `${HOST}/yumiAnalytics/kpi?`,
    organizationUnitId,
    start,
    end,
  );
  return apiGet(url);
};

export const fetchEngagement = (organizationUnitId, start, end) => {
  const url = addParams(
    `${HOST}/yumiAnalytics/engagement?`,
    organizationUnitId,
    start,
    end,
  );
  return apiGet(url);
};

export const fetchActiveUsers = (organizationUnitId, start, end) => {
  const url = addParams(
    `${HOST}/yumiAnalytics/activeUsers?`,
    organizationUnitId,
    start,
    end,
  );
  return apiGet(url);
};

export const fetchWeeklyActiveUsers = (organizationUnitId, start, end) => {
  const url = addParams(
    `${HOST}/yumiAnalytics/weeklyActiveUsers?`,
    organizationUnitId,
    start,
    end,
  );
  return apiGet(url);
};

export const fetchMonthlyActiveUsers = (organizationUnitId, start, end) => {
  const url = addParams(
    `${HOST}/yumiAnalytics/monthlyActiveUsers?`,
    organizationUnitId,
    start,
    end,
  );
  return apiGet(url);
};

export const fetchLicense = (organizationUnitId, start, end) => {
  const url = addParams(
    `${HOST}/yumiAnalytics/licenses?`,
    organizationUnitId,
    start,
    end,
  );
  return apiGet(url);
};
