/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Translations
import { injectIntl } from 'react-intl';
import messages from './messages';

// Constants
import styled from 'styled-components';
import { getUsers } from 'utils/storage';
import moment from 'moment';

import * as Paths from 'constants/paths';
import shortid from 'shortid';
import { inviteUsers } from './api';
import { useHistory } from 'react-router-dom';
import { Oval } from 'components/Oval';

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PrimaryText = styled.p`
  width: 100%;
  font-size: 24.38px;
  text-align: left;
`;

const SecondaryText = styled.a`
  margin-top: 20px;
  font-size: 16px;
  text-align: left;
  color: #cdcdcd;
  text-decoration: underline;
  cursor: pointer;
`;

const Columns = styled.tr`
  height: 50px;
  background: linear-gradient(240deg, ${Colors.PURPLE} 0%, ${Colors.CYAN} 100%);
`;

const Column = styled.th`
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
`;

const Rows = styled.tr`
  height: 50px;
`;

const Content = styled.td`
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f4f4f4;
  color: ${props => (props.notValid ? '#cc31ff' : 'black')};
  font-size: 16px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
`;

const Button = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color}
  width: 300px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

`;

const NextButton = styled.div`
  margin-top: 20px;
  align-self: center;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  ${props => !props.disabled && 'cursor: pointer;'}
  padding: ${Margins.MEDIUM}px;
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.BATTLESHIP_GREY};
  border-radius: 12px;
  margin-top: 20px;
  border: 1px solid ${Colors.BATTLESHIP_GREY};

  ${props =>
    !props.disabled
      ? `
  &:hover {
    border: 1px solid white;
    background: linear-gradient(
      120deg,
      ${Colors.PURPLE} 1%,
      ${Colors.CYAN} 90%
    );
    color: #ffffff;
  }
  `
      : ''}
`;

function UsersToDeleteStyle(teamName) {
  if (teamName === 'Deleted-Users') {
    return { backgroundColor: 'red', color: 'white' }
  }
  return {};
}

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 30vh;
  background-color: white;
  position: absolute;
  top: 20vh;
  left: 35vw;
  z-index: 2000;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 50px 50px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 50px 50px rgba(0, 0, 0, 0.4);
`;

const UserTable = props => {
  const [getValid, setGetValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const { intl } = props;
  const invitedUsers = getUsers();

  const history = useHistory();

  const nextButtonClick = () => {
    setLoader(true);
    const userToSend = invitedUsers
      .filter(el => el.isValid)
      .map(el => ({
        email: el.email,
        hireDate: el.hireDate,
        birthDate: el.birthDate,
        teamName: el.teamName,
        language: el.languageForInvitationEmail,
        isTeamLeader: el.isTeamLeader,
        country: el.country,
        labels: el.labels
      }));

    inviteUsers(userToSend)
      .then(response => {
        console.log(response);
        setShowModal(false);
        history.push(Paths.IMPORT_SUCCESS);
      })
      .catch(err => console.error(err));
  };

  return (
    <div
      style={{
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <Container>
        <PrimaryText>{intl.formatMessage(messages.title)}</PrimaryText>
        <PrimaryText>{intl.formatMessage(messages.subtitle)}</PrimaryText>
      </Container>
      {invitedUsers !== undefined && (
        <>
          <Buttons>
            <Button
              onClick={() => setGetValid(true)}
              style={{ zIndex: getValid ? 10 : 0 }}
              backgroundColor={
                getValid ? Colors.DONUTS_50 : Colors.LIGHT_GREY_BLUE
              }
              color="#ffffff"
            >
              {invitedUsers.filter(el => el.isValid).length} {intl.formatMessage(messages.uploadedDone)}
            </Button>
            <Button
              style={{ marginLeft: -30, zIndex: !getValid ? 10 : 0 }}
              onClick={() => setGetValid(false)}
              backgroundColor={
                !getValid ? Colors.DONUTS_0 : Colors.LIGHT_GREY_BLUE
              }
              color="#ffffff"
            >
              {invitedUsers.filter(el => !el.isValid).length} {intl.formatMessage(messages.brokenUpload)}
            </Button>
          </Buttons>
          <table
            style={{
              width: '80%',
            }}
          >
            <thead>
              <Columns>
                <Column>{intl.formatMessage(messages.email)}</Column>
                <Column>{intl.formatMessage(messages.team)}</Column>
                <Column>{intl.formatMessage(messages.birthdate)}</Column>
                <Column>{intl.formatMessage(messages.hiringDate)}</Column>
                <Column>{intl.formatMessage(messages.teamLeader)}</Column>
                <Column>{intl.formatMessage(messages.language)}</Column>
                <Column>{intl.formatMessage(messages.status)}</Column>
                <Column>{intl.formatMessage(messages.country)}</Column>
                <Column>{intl.formatMessage(messages.labels)}</Column>
              </Columns>
            </thead>
            {invitedUsers
              .filter(el => el.isValid === getValid)
              .map(el => (
                <React.Fragment key={shortid.generate()}>
                  <tbody>
                    <div style={{ height: 5 }} />
                    <Rows>
                      <Content style={UsersToDeleteStyle(el.teamName)}>{el.email}</Content>
                      <Content style={UsersToDeleteStyle(el.teamName)}>{el.teamName}</Content>
                      <Content style={UsersToDeleteStyle(el.teamName)} notValid={el.birthDate === undefined}>
                        {el.birthDate !== undefined
                          ? moment(el.birthDate).format('DD-MM-YYYY')
                          : 'NOT VALID'}
                      </Content>
                      <Content style={UsersToDeleteStyle(el.teamName)} notValid={el.hireDate === undefined}>
                        {el.hireDate !== undefined
                          ? moment(el.hireDate).format('DD-MM-YYYY')
                          : 'NOT VALID'}
                      </Content>
                      <Content style={UsersToDeleteStyle(el.teamName)}>{!el.isTeamLeader ? `${intl.formatMessage(messages.no)}` : `${intl.formatMessage(messages.yes)}`}</Content>
                      <Content style={UsersToDeleteStyle(el.teamName)}>{el.languageForInvitationEmail}</Content>
                      <Content style={UsersToDeleteStyle(el.teamName)} notValid={!el.isValid}>
                        {el.isValid
                          ? 'OK'
                          : el.errors[0].text !== undefined &&
                          el.errors[0].text.toUpperCase()}
                      </Content>
                      <Content style={UsersToDeleteStyle(el.teamName)}>{el.country}</Content>
                      <Content style={UsersToDeleteStyle(el.teamName)}>{el.labels}</Content>
                    </Rows>
                  </tbody>
                </React.Fragment>
              ))}
          </table>
        </>
      )}
      {showModal && (
        <Modal>
          {' '}
          {!loader ? (
            <>
              <SecondaryText
                style={{
                  textDecoration: 'none',
                  cursor: 'default',
                  color: 'black',
                }}
              >
                {intl.formatMessage(messages.youSure)}
              </SecondaryText>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 50,
                }}
              >
                <button
                  style={{
                    border: 0,
                    borderRadius: 8,
                    backgroundColor: '#cc31ff',
                    color: 'white',
                    alignSelf: 'center',
                    width: 280,
                    height: 40,
                    fontWeight: 'bold',
                  }}
                  onClick={() => setShowModal(false)}
                >
                  {intl.formatMessage(messages.notSure).toUpperCase()}
                </button>
                <span style={{ width: 10 }} />
                <button
                  style={{
                    border: 0,
                    borderRadius: 8,
                    backgroundColor: '#cc31ff',
                    color: 'white',
                    alignSelf: 'center',
                    width: 280,
                    height: 40,
                    fontWeight: 'bold',
                  }}
                  onClick={() => nextButtonClick()}
                >
                  {intl.formatMessage(messages.imSure).toUpperCase()}
                </button>
              </div>
            </>
          ) : (
            <Oval color={'#cc31ff'} />
          )}
        </Modal>
      )}
      {getValid &&
        invitedUsers.filter(el => el.isValid === getValid).length > 0 ? (
        <>
          <SecondaryText style={{ textDecoration: 'none', cursor: 'default' }}>
            {intl.formatMessage(messages.disclaimer, {
              br: <br />,
            })}
          </SecondaryText>
          <NextButton onClick={() => setShowModal(true)}>
            {intl.formatMessage(messages.nextButton)}
          </NextButton>
        </>
      ) : (
        undefined
      )}
      <SecondaryText href={Paths.ADD_CSV}>
        {intl.formatMessage(messages.reload).toUpperCase()}
      </SecondaryText>
    </div>
  );
};

UserTable.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(UserTable);
