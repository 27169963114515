import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import { Helmet } from 'react-helmet';
import SectionTitle from 'containers/SectionTitle';
import Organization from './Organization';
import Groups from './Groups';
import Heatmap from './Heatmap';

// Translations
import { injectIntl } from 'react-intl';
// import { FormattedMessage } from 'react-intl';
import messages from './messages';

// Data
import tabs from './tabs';
import periods from 'utils/periodGenerator';

// APIs
import { fetchHeaderKpis } from './api';

// Constants
// import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';
import { ORGANIZATION, GROUPS, HEATMAP } from 'constants/tabs';

// Style
const Content = styled.section`
  padding: 40px ${Margins.XXLARGE}px;
  height: calc(100% - 188px);
  overflow: auto;
`;

export function Mood({ intl }) {
  const { setKpis, unitId, tab, period } = useContext(AuthContext);

  useEffect(() => {
    const fetchHeaderData = async () => {
      const { start, end } = periods[period];

      try {
        const response = await fetchHeaderKpis(unitId, start, end);
        const data = await response.json();
        setKpis(data);
      } catch {
        setKpis([]);
      }
    };
    fetchHeaderData();
  }, [unitId, period]);

  const renderOrganizationTab = () => {
    if (tab === ORGANIZATION) {
      return <Organization />;
    }
    return null;
  };

  const renderGroupsTab = () => {
    if (tab === GROUPS) {
      return <Groups />;
    }
    return null;
  };

  const renderHeatmapTab = () => {
    if (tab === HEATMAP) {
      return <Heatmap />;
    }
    return null;
  };

  return (
    <>
      <Helmet>
        <title>Mood</title>
        <meta name="description" content="Description of Mood" />
      </Helmet>

      <SectionTitle
        title={intl.formatMessage(messages.title)}
        tabs={tabs}
        periods={periods}
      />

      <Content>
        {renderOrganizationTab()}
        {renderGroupsTab()}
        {renderHeatmapTab()}
      </Content>
    </>
  );
}

Mood.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(Mood);
