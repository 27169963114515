/**
 *
 * Authenticated
 *
 */

import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// Context
import AppContext from 'containers/App/context';
import AuthContext from './context';

// Components
import Sidebar from 'containers/Sidebar';
import Header from 'containers/Header';

// Routes
import Accounts from 'containers/Accounts';
import AddFile from 'containers/AddFile';
import UserTable from 'containers/UserTable';
import ImportSuccessfully from 'containers/ImportSuccessfully';
import Mood from 'containers/Mood';
import Skills from 'containers/Skills';
// import TeamCard from 'containers/TeamCard';
import Analytics from 'containers/Analytics';
// import Users from 'containers/Users';
// import Organization from 'containers/Organization';
import NotFoundPage from 'containers/NotFoundPage';
import * as Paths from 'constants/paths';

// APIs
import { fetchOrganizationUnits } from './api';

// Utils
import {
  setOrganizationUnit,
  setBenchmark,
  getBenchmark,
  getUserdata,
  getTenant,
} from 'utils/storage';

// Constants
import periods from 'utils/periodGenerator';

// Style
const Content = styled.section`
  height: 100%;
  width: 100%;
`;

const Body = styled.div`
  height: 100%;
  overflow: auto;
`;

function Authenticated({ location }) {
  const appContext = useContext(AppContext);

  const [unitId, setUnitId] = useState();
  const [
    benchmarkOrganizationUnitId,
    setBenchmarkOrganizationUnitId,
  ] = useState();
  const [benchmarkStart, setBenchmarkStart] = useState();
  const [benchmarkEnd, setBenchmarkEnd] = useState();
  const [kpis, setKpis] = useState([]);
  const [tab, setTab] = useState(undefined);
  const [period, setPeriod] = useState(periods.length - 1);
  const [query, setQuery] = useState(''); // Input search from UsersHeader
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime()); // Toggle search from UsersHeader
  const tenant = getTenant();

  const getData = uncheckedData => {
    const userdata = getUserdata();
    if (userdata.isAdmin === 1) {
      return uncheckedData;
    }
    if (uncheckedData.filter(d => d.isActive).length > 0) {
      return uncheckedData.filter(d => d.isActive);
      /* eslint-disable-next-line */
    } else {
      return uncheckedData.map(d => getData(d.children))[0];
    }
  };

  const fetchHierarchy = async () => {
    try {
      const response = await fetchOrganizationUnits();
      const data = await response.json();
      setUnitId(getData(data)[0].id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchHierarchy();

    const benchmark = getBenchmark();
    if (benchmark && benchmark.id) {
      setBenchmarkOrganizationUnitId(benchmark.id);
    }
    if (benchmark && benchmark.start) {
      setBenchmarkStart(benchmark.start);
    }
    if (benchmark && benchmark.end) {
      setBenchmarkEnd(benchmark.end);
    }
  }, []);

  const shared = {
    unitId,
    setUnitId: id => {
      setUnitId(id);
      setOrganizationUnit(id);
    },
    benchmarkOrganizationUnitId,
    setBenchmarkOrganizationUnitId: id => {
      setBenchmarkOrganizationUnitId(id);
      setBenchmark({
        id,
      });
    },
    benchmarkStart,
    setBenchmarkStart,
    benchmarkEnd,
    setBenchmarkEnd,
    kpis,
    setKpis,
    tab,
    setTab,
    period,
    setPeriod,
    query,
    setQuery,
    lastUpdate,
    setLastUpdate,
    location,
  };

  if (appContext.isAuthorized) {
    return (
      <AuthContext.Provider value={shared}>
        <Sidebar />
        <Content>
          <Header pathname={location.pathname} />

          <Body id="screenshot-target">
            <Switch>
              <Redirect exact from={Paths.HOME} to={Paths.ANALYTICS} />
              {/* <Route path={Paths.BRILLIANCE} component={Brilliance} /> */}
              <Route path={Paths.MOOD} component={Mood} />
              {tenant.isSkillsEnabled && (
                <Route path={Paths.SKILLS} component={Skills} />
              )}
              {/* <Route path={Paths.SKILLS} component={Skills} /> */}
              {/* <Route path={Paths.TEAMCARD} component={TeamCard} /> */}
              <Route path={Paths.ANALYTICS} component={Analytics} />
              <Route path={Paths.ACCOUNTS} component={Accounts} />
              <Route path={Paths.ADD_CSV} component={AddFile} />
              <Route path={Paths.USER_TABLE} component={UserTable} />
              <Route
                path={Paths.IMPORT_SUCCESS}
                component={ImportSuccessfully}
              />
              {/* <Route path={Paths.USERS} component={Users} />
              <Route path={Paths.ORGANIZATION} component={Organization} /> */}
              <Route component={NotFoundPage} />
            </Switch>
          </Body>
        </Content>
      </AuthContext.Provider>
    );
  }

  return (
    <Redirect
      to={{
        pathname: Paths.LOGIN,
        state: {
          from: location.pathname,
        },
      }}
    />
  );
}

Authenticated.propTypes = {
  location: PropTypes.object,
};

export default Authenticated;
