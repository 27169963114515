/**
 *
 * AreaSelector
 *
 */

import React, { useContext, useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import { ClipLoader } from 'react-spinners';
import Avatar from 'components/Avatar';
import Card from 'components/Card';
import AreaSelectorModal from './AreaSelectorModal';

// Assets
import { ReactComponent as MenuIcon } from 'images/shared/menu.svg';

// APIs
import { fetchOrganizationUnit, fetchOrganizationUnits } from './api';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled(Card)`
  width: 360px;
  height: 40px;
  border: 1px solid ${Colors.AQUA_MARINE};
  padding: 0 8px 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &.loading {
    justify-content: center;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  margin-left: ${Margins.REGULAR}px;
  font-weight: bold;
  font-size: 14.2px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${Colors.BLACK};
`;

const Menu = styled(MenuIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

function AreaSelector() {
  const authContext = useContext(AuthContext);

  const [unitDetails, setUnitDetails] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hierarchy, setHierarchy] = useState(undefined);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchUnitData = async () => {
      try {
        const response = await fetchOrganizationUnit(authContext.unitId);
        const data = await response.json();
        setUnitDetails(data);
      } catch {
        setUnitDetails(undefined);
      }
    };

    if (authContext.unitId) {
      fetchUnitData();
    }
  }, [authContext.unitId]);

  const openModal = async () => {
    setIsModalOpen(true);
    setLoading(true);
    setError(false);

    try {
      const response = await fetchOrganizationUnits();
      const data = await response.json();
      setHierarchy(data);
    } catch {
      setHierarchy(undefined);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setHierarchy(undefined);
  };

  const submitModal = id => {
    setIsModalOpen(false);
    authContext.setUnitId(id);
  };

  return (
    <>
      <Wrapper data-testid="wrapper" className={unitDetails ? '' : 'loading'}>
        {unitDetails ? (
          <>
            <Info>
              <Avatar round size={24} name={unitDetails.name} />
              <Label title={unitDetails.name}>{unitDetails.name}</Label>
            </Info>
            <Menu data-testid="menu" onClick={openModal} />
          </>
        ) : (
          <ClipLoader size={20} color={Colors.LIGHT_NAVY} />
        )}
      </Wrapper>

      <AreaSelectorModal
        isOpen={isModalOpen}
        handleClose={closeModal}
        loading={loading}
        data={hierarchy || []}
        error={error}
        selectedUnit={authContext.unitId}
        onSubmit={submitModal}
      />
    </>
  );
}

export default AreaSelector;
