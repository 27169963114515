import { apiGet, addQueryStringParams } from 'utils/request';
import HOST from 'utils/globals';

export const fetchHeaderKpis = (organizationUnitId, start, end) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/header?`,
    organizationUnitId,
    start,
    end,
  });
  return apiGet(url);
};

export const fetchKpis = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/kpi?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchBubbles = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/bubbles?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchEmotionTable = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/table/emotion?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchContextTable = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/table/context?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchActivityTable = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/table/activity?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchUnitTable = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/table/organizationUnit?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchGroupsBubbles = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/group/bubbles?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchGroupsTable = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/group/table?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchHeatmap = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/heatmap?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};

export const fetchTrends = (
  organizationUnitId,
  start,
  end,
  benchmarkOrganizationUnitId,
  benchmarkStart,
  benchmarkEnd,
) => {
  const url = addQueryStringParams({
    url: `${HOST}/moodEnergy/trends?`,
    organizationUnitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  });
  return apiGet(url);
};
