/**
 *
 * Login
 *
 */

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

// Components
import { Helmet } from 'react-helmet';
import FloatingInput from 'components/FloatingInput';
import { ClipLoader } from 'react-spinners';

// Authentication
import { Auth } from 'aws-amplify';

// Translations
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

// Constants
import * as Colors from 'constants/colors';
import * as Paths from 'constants/paths';
import * as Margins from 'constants/margins';

// Assets
import { ReactComponent as LogoIcon } from '../../images/login/logo.svg';

// Context
import LocaleContext from 'containers/LocaleProvider/context';
import AppContext from 'containers/App/context';

// APIs
import { fetchUserProfile } from './api';

// Utils
import { IsMailValid } from 'utils/input';
import { setUserdata, setTenant } from 'utils/storage';

const Wrapper = styled.div`
  background: linear-gradient(120deg, ${Colors.PURPLE}, ${Colors.CYAN} 90%);
  background-color: ${Colors.LIGHT_NAVY};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled(LogoIcon)`
  width: 240px;
  height: 120px;
  margin-bottom: 0px;
`;

const LoginBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 311px;
  min-height: 558px;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubmitButton = styled.button`
  border: 2px solid #f5f5f5;
  padding: ${Margins.LARGE}px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  text-transform: uppercase;
  color: white;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14.2px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: 1px;
  text-align: center;
  color: #f5f5f5;
  margin: ${Margins.REGULAR}px auto;

  :hover {
    background-color: white;
    color: ${Colors.LIGHT_NAVY};
  }

  :focus {
    outline: none;
  }

  :disabled {
    color: ${Colors.LIGHT_NAVY};
    background-color: #ffffff;
    opacity: 0.5;
    cursor: default;
  }
`;

const UserPromptMessage = styled.div`
  text-align: center;
  color: #ffffff;
  line-height: 1.25;
  letter-spacing: 0.15px;
  width: 230px;
  margin: ${Margins.LARGE}px auto;
`;

const ErrorContainer = styled.div`
  background-color: ${Colors.ERROR_RED};
  border-radius: 4px;
  height: 56px;
  color: #fff;
  width: 100%;
  padding: ${Margins.LARGE}px;
  margin: ${Margins.REGULAR}px auto;
  transition: 0.2s ease-out opacity;
  opacity: ${props => (props.visible ? 1 : 0)};
`;

export function Login({ location, intl }) {
  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorFields, setErrorFields] = useState({
    username: false,
    password: false,
  });

  // Contexts
  const localeContext = useContext(LocaleContext);
  const appContext = useContext(AppContext);

  // Effects
  useEffect(() => {
    if (!errorFields.username && !errorFields.password) {
      setError(null);
    }
  }, [errorFields.username, errorFields.password]);

  const onSubmit = async e => {
    e.preventDefault();
    setError(null);
    setErrorFields({ username: false, password: false });
    const emailAddress = email ? email.trim().toLowerCase() : '';
    if (!IsMailValid(emailAddress)) {
      setError(intl.formatMessage(messages.invalidEmail));
      setErrorFields({ ...errorFields, username: true });
      return;
    }

    if (!password) {
      setError(intl.formatMessage(messages.emptyPassword));
      setErrorFields({ ...errorFields, password: true });
      return;
    }

    setLoading(true);
    try {
      // Login
      await Auth.signIn(emailAddress, password);

      // Fetch user profile data
      const response = await fetchUserProfile();
      const data = await response.json();
      // Set user language
      localeContext.changeLocale(data.user.language);

      // const tenantId = data.tenant ? data.tenant.id : 0;

      // Store userdata and tenant in localstorage
      const userdata = {
        email: data.user.email,
        displayName: data.user.displayName,
        image: data.user.image,
        language: data.user.language,
        isAdmin: data.user.isAdmin,
        isTeamLeader: data.user.isTeamLeader,
        isAuthorized: true,
      };
      // TODO
      if (data.user.isAdmin === 1 || data.user.isTeamLeader) {
        setUserdata(userdata);
        setTenant(data.tenant);
        setLoading(false);
        appContext.setIsAuth(true);
      } else {
        setError(intl.formatMessage(messages.notAuthorized));
        setLoading(false);
      }
    } catch (err) {
      let errorMessage;
      switch (err.code) {
        case 'UserNotFoundException':
        case 'NotAuthorizedException':
          errorMessage = intl.formatMessage(messages.notAuthorized);
          setErrorFields({ username: true, password: true });
          break;
        default:
          errorMessage = intl.formatMessage(messages.genericError);
          break;
      }
      setLoading(false);
      console.error(err);
      setError(errorMessage);
    }
  };

  const getRedirectRoute = () =>
    location.state ? location.state.from : Paths.HOME;

  if (appContext.isAuthorized) {
    return (
      <Redirect
        to={{
          pathname: getRedirectRoute(),
        }}
      />
    );
  }
  return (
    <Wrapper>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Description of Login" />
      </Helmet>
      <LoginBody>
        <Logo data-testid="login-logo" />
        <LoginForm data-testid="login-form" onSubmit={e => onSubmit(e)}>
          <UserPromptMessage data-testid="login-user-message">
            <FormattedMessage {...messages.userPromptMessage} />
          </UserPromptMessage>

          <FieldsWrapper>
            <FloatingInput
              id="email"
              type="text"
              placeholder={intl.formatMessage(messages.usernameLabel)}
              onChange={e => {
                setEmail(e.target.value);
                setErrorFields({ ...errorFields, username: false });
              }}
              value={email}
              error={errorFields.username}
              disabled={loading}
              backgroundColor="transparent"
            />
            <FloatingInput
              id="password"
              type="password"
              placeholder={intl.formatMessage(messages.passwordLabel)}
              onChange={e => {
                setPassword(e.target.value);
                setErrorFields({ ...errorFields, password: false });
              }}
              value={password}
              error={errorFields.password}
              disabled={loading}
              backgroundColor="transparent"
            />
          </FieldsWrapper>

          <SubmitButton
            data-testid="login-submit-button"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <div data-testid="login-loading-spinner">
                <ClipLoader size={20} color={Colors.LIGHT_NAVY} />
              </div>
            ) : (
              intl.formatMessage(messages.loginButton)
            )}
          </SubmitButton>
          <ErrorContainer data-testid="login-error-container" visible={error}>
            {error}
          </ErrorContainer>
        </LoginForm>
      </LoginBody>
    </Wrapper>
  );
}

Login.propTypes = {
  location: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(Login);
