/**
 *
 * People
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import * as Colors from 'constants/colors';
// import * as Margins from 'constants/margins';

// Assets
import { ReactComponent as PeopleIco } from 'images/shared/people.svg';

// Style
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Ico = styled(PeopleIco)`
  width: 18px;
  height: 18px;
  margin-left: 4px;
`;

function People({ className, people, width }) {
  return (
    <Wrapper data-testid="people-wrapper" className={className} width={width}>
      <span data-testid="people-count">{people}</span>
      <Ico
        data-testid="people-icon"
        style={{ color: Colors.BATTLESHIP_GREY }}
      />
    </Wrapper>
  );
}

People.propTypes = {
  people: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

People.defaultProps = {
  width: 50,
};

export default People;
