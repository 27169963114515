/*
 * Login Messages
 *
 * This contains all the text for the Login container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Login';

export default defineMessages({
  userPromptMessage: {
    id: `${scope}.userPromptMessage`,
    defaultMessage: 'Inserisci la tua mail del lavoro per proseguire',
  },
  usernameLabel: {
    id: `${scope}.usernameLabel`,
    defaultMessage: 'Nome utente',
  },
  passwordLabel: {
    id: `${scope}.passwordLabel`,
    defaultMessage: 'Password',
  },
  loginButton: {
    id: `${scope}.loginButton`,
    defaultMessage: 'Login',
  },
  notAuthorized: {
    id: `${scope}.notAuthorized`,
    defaultMessage: 'Utente o password non validi',
  },
  invalidEmail: {
    id: `${scope}.invalidEmail`,
    defaultMessage: 'Indirizzo email non valido',
  },
  emptyPassword: {
    id: `${scope}.emptyPassword`,
    defaultMessage: 'Inserire una password',
  },
  genericError: {
    id: `${scope}.genericError`,
    defaultMessage: 'Errore login',
  },
});
