/**
 *
 * Magic Link
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Helmet } from 'react-helmet';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';
import { APPSTORE_URL, PLAYSTORE_URL } from 'utils/globals';

// Translations
import { FormattedMessage } from 'react-intl';
import messages from './messages';

// Assets
import { ReactComponent as OriginalLogoIcon } from '../../images/login/logo.svg';
import PhoneIco from '../../images/shared/phone.png';
import AppStoreLogo from 'images/shared/appstore.png';
import PlayStoreLogo from 'images/shared/playstore.png';

// Styles
const Wrapper = styled.div`
  background: linear-gradient(120deg, ${Colors.PURPLE}, ${Colors.CYAN} 90%);
  background-color: ${Colors.LIGHT_NAVY};
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: 'Muli-Regular';
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
`;

const LogoIcon = styled(OriginalLogoIcon)`
  width: 240px;
  height: 120px;
  margin-bottom: 60px;
`;

const OpenWithApp = styled.div`
  display: flex;
  font-family: 'Muli-Regular';
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;

const PhoneIcon = styled.img`
  width: 32px;
  height: 62px;
  margin-right: ${Margins.LARGE}px;
`;

const OpenButton = styled.a`
  border: 1px solid #ffffff;
  padding: ${Margins.LARGE}px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14.2px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: 1px;
  text-align: center;
  color: ${Colors.LIGHT_NAVY};
  margin-bottom: ${Margins.LARGE}px;
  margin-top: 40px;
  text-decoration: none;

  span {
    color: ${Colors.LIGHT_NAVY};
  }

  :hover {
    opacity: 0.9;
  }

  :focus {
    outline: none;
  }

  :disabled {
    color: ${Colors.LIGHT_NAVY};
    background-color: #ffffff;
    opacity: 0.5;
    cursor: default;
  }

  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

const DownloadText = styled.div`
  margin-top: 90px;
`;

const DownloadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${Margins.LARGE}px;
`;

const DownloadLogo = styled.a`
  width: 48%;

  > img {
    width: 100%;
    height: auto;
  }
`;

const MagicLink = ({ history }) => {
  const { pathname } = history.location;
  const mobileUrl = `feedbackloopapp:/${pathname}`;

  return (
    <Wrapper>
      <Helmet>
        <title>Yumi</title>
        <meta name="description" content="Description of MagicLink" />
      </Helmet>
      <Content>
        <LogoIcon />
        <OpenWithApp>
          <PhoneIcon src={PhoneIco} alt="" />
          <FormattedMessage {...messages.openWithApp} />
        </OpenWithApp>
        <OpenButton href={mobileUrl}>
          <FormattedMessage {...messages.openButton} />
        </OpenButton>
        <DownloadText>
          <FormattedMessage {...messages.download} />
        </DownloadText>
        <DownloadWrapper>
          <DownloadLogo href={APPSTORE_URL}>
            <img src={AppStoreLogo} alt="" />
          </DownloadLogo>
          <DownloadLogo href={PLAYSTORE_URL}>
            <img src={PlayStoreLogo} alt="" />
          </DownloadLogo>
        </DownloadWrapper>
      </Content>
    </Wrapper>
  );
};

MagicLink.propTypes = {
  history: PropTypes.object,
};

export default MagicLink;
