import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import SubsectionTitle from 'components/SubsectionTitle';
import { ClipLoader } from 'react-spinners';
import Error from 'components/Error';
import LineChart from 'components/LineChart';

// Translations
import { injectIntl } from 'react-intl';
import messages from '../messages';

// APIs
import { fetchMonthlyActiveUsers } from '../api';

// Images
import { ReactComponent as ActiveUsersIco } from 'images/analytics/activeUsers.svg';

// Constants
import * as Colors from 'constants/colors';
import { generate } from 'shortid';

// Style
const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 50px;
  margin-left: 24px;
  display: flex;
  justify-content: center;
`;

const ActiveUsersIcon = styled(ActiveUsersIco)`
  width: 50px;
  height: 50px;
`;

function MonthlyActiveUsers({ intl, unitId, start, end }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    let canceled = false;
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      setData([]);
      try {
        const response = await fetchMonthlyActiveUsers(unitId, start, end);
        const json = await response.json();
        if (!canceled) {
          setData(json);
        }
      } catch {
        if (!canceled) {
          setError(true);
        }
      } finally {
        if (!canceled) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      canceled = true;
    };
  }, [unitId, start, end]);

  const renderLoading = () => {
    if (loading) {
      return <ClipLoader size={20} color={Colors.LIGHT_NAVY} />;
    }
    return null;
  };

  const renderLineChart = () => {
    if (data && data.length) {
      const UUID = generate();
      return (
        <LineChart
          data={data}
          xAxis={[25, 35, 45, 55, 65, 75]}
          showXLabels
          gridXStep={10}
          yAxis={[0, 100]}
          color={Colors.DONUTS_30_49}
          uuid={UUID}
        />
      );
    }
    return null;
  };

  const renderError = () => {
    if (error) {
      return <Error />;
    }
    return null;
  };

  return (
    <Wrapper>
      <SubsectionTitle
        icon={<ActiveUsersIcon />}
        title={intl.formatMessage(messages.monthlyActiveUsersTitle)}
        body={intl.formatMessage(messages.monthlyActiveUsersSubtitle, {
          br: <br />,
        })}
      />

      <Content>
        {renderLoading()}
        {renderLineChart()}
        {renderError()}
      </Content>
    </Wrapper>
  );
}

MonthlyActiveUsers.propTypes = {
  intl: PropTypes.object,
  unitId: PropTypes.number,
  start: PropTypes.string,
  end: PropTypes.string,
};

export default injectIntl(MonthlyActiveUsers);
