import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import Card from 'components/Card';

// Assets
import { ReactComponent as CloseIcon } from 'images/modal/close.svg';

// Utils
import { hexToRGBA } from 'utils/colors';

// Constants
import * as Margins from 'constants/margins';
import { LIGHT_GREY_BLUE } from 'constants/colors';

// Style
const Wrapper = styled.section`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${props => (props.isOpen ? 0 : '200%')};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  z-index: ${props => (props.isOpen ? 99 : -1)};
  transition: all 0.5s;
  background-color: ${hexToRGBA(LIGHT_GREY_BLUE, 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled(Card)`
  width: 32%;
  height: 400px;
  background-color: white;
  padding: 48px;
  overflow: hidden;
`;

const Close = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 1;
  position: absolute;
  top: ${Margins.XXLARGE}px;
  right: ${Margins.XXLARGE}px;

  :hover {
    opacity: 0.5;
  }
`;

function Modal({ className, isOpen, handleClose, children }) {
  return (
    <Wrapper className={className} isOpen={isOpen} data-testid="modal">
      <Content className="content" heavyShadow>
        <Close onClick={handleClose} />
        {children}
      </Content>
    </Wrapper>
  );
}

Modal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Modal.defaultProps = {
  isOpen: false,
};

export default Modal;
