import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import OriginalModal from 'components/Modal';
import { ClipLoader } from 'react-spinners';
import OriginalFacetNavigation from 'components/FacetNavigation';
import Error from 'components/Error';
import OriginalButton from 'components/Button';

// Translations
import { FormattedMessage } from 'react-intl';
import messages from './messages';

// Constants
import * as Colors from 'constants/colors';
import Path from './Path';

// Style
const Modal = styled(OriginalModal)`
  .content {
    width: 50%;
    height: 55%;
    display: flex;
    flex-direction: column;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${Colors.BLACK};
`;

const Content = styled.div`
  flex: 1;
  margin: 16px 0;
  border-radius: 8px;
  border: 1px solid ${Colors.GREY};
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth; // Not supported in IE, Edge and Safari
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FacetNavigation = styled(OriginalFacetNavigation)`
  > .column {
    min-width: unset;
    width: 30%;
    min-width: 30%;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled(OriginalButton)`
  &:not(:first-child) {
    margin-left: 8px;
  }
`;

const PathWrapper = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: flex-start;
  align-items: center;
`;

function AreaSelectionModal({
  isOpen,
  handleClose,
  loading,
  data,
  error,
  selectedUnit,
  onSubmit,
}) {
  const content = useRef(null);

  const [selectedItems, setSelectedItems] = useState([selectedUnit]);

  const getFacetWidth = div => (80 * div.offsetWidth) / 100;

  useEffect(() => {
    if (data && data.length) {
      // Scroll to the desired position
      setTimeout(() => {
        const FACET_WIDTH = getFacetWidth(content.current);
        content.current.scrollLeft = (selectedItems.length - 1) * FACET_WIDTH;
      }, 50);
    }
  }, [data]);

  const onSelect = selected => {
    const FACET_WIDTH = getFacetWidth(content.current);
    setTimeout(() => {
      content.current.scrollLeft = (selected.length - 1) * FACET_WIDTH;
    });
    setSelectedItems(selected);
  };

  const onClose = () => {
    setSelectedItems([selectedUnit]);
    handleClose();
  };

  const handleSubmit = () => {
    onSubmit(selectedItems[selectedItems.length - 1]);
  };

  const renderLoading = () => {
    if (loading) {
      return (
        <div data-testid="area-selector-loading">
          <ClipLoader size={20} color={Colors.LIGHT_NAVY} />
        </div>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (!loading && !error && data && data.length > 0) {
      return (
        <FacetNavigation
          data={data}
          selected={selectedItems}
          onSelect={onSelect}
          flexible={false}
          columnWidth="80%"
          showLabels={false}
          extraRow={false}
        />
      );
    }
    return null;
  };

  const renderError = () => {
    if (error) {
      return <Error />;
    }
    return null;
  };

  const renderActions = () => {
    if (!loading && !error) {
      return (
        <Actions>
          <Button text="Annulla" onClick={onClose} />
          <Button type="accent" text="Salva" onClick={handleSubmit} />
        </Actions>
      );
    }
    return null;
  };

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <Title>
        <FormattedMessage {...messages.modalTitle} />
      </Title>
      <PathWrapper>
        <Path
          data={data}
          selected={selectedItems}
          onPathPress={items => onSelect(items)}
        ></Path>
      </PathWrapper>
      <Content ref={content}>
        {renderLoading()}
        {renderContent()}
        {renderError()}
      </Content>

      {renderActions()}
    </Modal>
  );
}

AreaSelectionModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.array,
  error: PropTypes.bool,
  selectedUnit: PropTypes.number,
  onSubmit: PropTypes.func,
};

export default AreaSelectionModal;
