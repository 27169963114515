import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

// Context
import AppContext from './context';

// Components
import { Helmet } from 'react-helmet';

// Routes
import * as Paths from 'constants/paths';
import ViralSharing from 'containers/ViralSharing/';
import MagicLink from 'containers/MagicLink/';
import Login from 'containers/Login/';
import Authenticated from 'containers/Authenticated/';
import NotFoundPage from 'containers/NotFoundPage/';

// Utils
import { getUserdata } from 'utils/storage';

// Context
import LocaleContext from 'containers/LocaleProvider/context';

// Style
import GlobalStyle from './global-styles';

function App() {
  const localeContext = useContext(LocaleContext);

  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const userdata = getUserdata();
    if (userdata) {
      console.log(userdata);
      setIsAuthorized(userdata.isAuthorized);
      localeContext.changeLocale(userdata.language);
    }
  }, []);

  const shared = {
    isAuthorized,
    setIsAuth: isAuth => setIsAuthorized(isAuth),
  };

  return (
    <AppContext.Provider value={shared}>
      <Helmet titleTemplate="%s - Feedback Loop" />

      <Switch>
        {/* Public Routes */}
        <Route path={Paths.MAGIC_LINK} component={MagicLink} />
        <Route path={Paths.VIRAL_SHARING} component={ViralSharing} />
        <Route path={Paths.LOGIN} component={Login} />

        {/* Authenticated Routes */}
        <Route path={Paths.HOME} component={Authenticated} />

        {/* Shared */}
        <Route component={NotFoundPage} />
      </Switch>

      <GlobalStyle />
    </AppContext.Provider>
  );
}

export default App;
