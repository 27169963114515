import React from 'react';

// Components
import Bubbles from './Bubbles';
import Table from './Table';

// Constants
import * as Tables from '../tables';

function Groups() {
  return (
    <>
      <Bubbles />
      <Table type={Tables.CATEGORIES} />
    </>
  );
}

export default Groups;
