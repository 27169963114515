import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import SubsectionTitle from 'components/SubsectionTitle';
import { ClipLoader } from 'react-spinners';
import OriginalTable from 'components/Table';
import Error from 'components/Error';

// Columns
import { categoriesColumns, behaviorColumns, unitsColumns } from '../columns';

// Translations
import { injectIntl } from 'react-intl';
import messages from '../messages';

// APIs
import {
  fetchCategoriesTable,
  fetchBehaviorTable,
  fetchUnitsTable,
} from '../api';

// Images
import { ReactComponent as Mood } from 'images/sections/mood.svg';
import { ReactComponent as Behaviour } from 'images/sections/behaviour.svg';
import { ReactComponent as Unit } from 'images/sections/unit.svg';

// Constants
import periods from 'utils/periodGenerator';
import * as Colors from 'constants/colors';
import * as Tables from '../tables';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const icon = css`
  width: 50px;
  height: 50px;
`;

const MoodIcon = styled(Mood)`
  ${icon};
`;

const BehaviourIcon = styled(Behaviour)`
  ${icon};
`;

const UnitIcon = styled(Unit)`
  ${icon};
`;

const Content = styled.div`
  flex: 1;
  margin-top: 50px;
  margin-left: ${Margins.XXLARGE}px;
  display: flex;
  justify-content: center;
`;

function Table({ intl, type, limit }) {
  const {
    unitId,
    period,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  } = useContext(AuthContext);

  const { start, end } = periods[period];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const getColumns = columns =>
    columns.map(col => {
      if (col.key === 'benchmark') {
        return {
          ...col,
          visible:
            !!benchmarkOrganizationUnitId || !!benchmarkStart || !!benchmarkEnd,
        };
      }
      return col;
    });

  const tableOptions = {};
  switch (type) {
    case Tables.BEHAVIOR: {
      tableOptions.fetchData = fetchBehaviorTable;
      tableOptions.columns = getColumns(behaviorColumns);
      tableOptions.icon = <BehaviourIcon />;
      tableOptions.title = messages.behaviorTableTitle;
      tableOptions.subtitle = messages.behaviorTableSubtitle;
      break;
    }
    case Tables.UNITS:
      tableOptions.fetchData = fetchUnitsTable;
      tableOptions.columns = unitsColumns;
      tableOptions.icon = <UnitIcon />;
      tableOptions.title = messages.unitsTableTitle;
      tableOptions.subtitle = messages.unitsTableSubtitle;
      break;
    case Tables.CATEGORIES:
      tableOptions.fetchData = fetchCategoriesTable;
      tableOptions.columns = getColumns(categoriesColumns);
      tableOptions.icon = <MoodIcon />;
      tableOptions.title = messages.categoriesTableTitle;
      tableOptions.subtitle = messages.categoriesTableSubtitle;
      break;
    default:
  }

  useEffect(() => {
    let canceled = false;
    const fetchData = async () => {
      setLoading(true);
      setData([]);
      try {
        const response = await tableOptions.fetchData(
          unitId,
          start,
          end,
          benchmarkOrganizationUnitId,
          benchmarkStart,
          benchmarkEnd,
        );
        const json = await response.json();
        if (!canceled) {
          console.log('json', JSON.stringify(json));
          setLoading(false);
          setData(json);
        }
      } catch {
        if (!canceled) {
          setLoading(false);
          setError(true);
        }
      }
    };

    fetchData();

    return () => {
      canceled = true;
    };
  }, [
    unitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  ]);

  const renderLoading = () => {
    if (loading) {
      return <ClipLoader size={20} color={Colors.LIGHT_NAVY} />;
    }
    return null;
  };

  const renderTable = () => {
    if (!loading && !error && data) {
      return (
        <OriginalTable
          columns={tableOptions.columns}
          data={data}
          borders
          limit={limit}
        />
      );
    }
    return null;
  };

  const renderError = () => {
    if (!loading && error) {
      return <Error />;
    }
    return null;
  };

  return (
    <Wrapper>
      <SubsectionTitle
        icon={tableOptions.icon}
        title={intl.formatMessage(tableOptions.title)}
        body={intl.formatMessage(tableOptions.subtitle)}
      />

      <Content>
        {renderLoading()}
        {renderTable()}
        {renderError()}
      </Content>
    </Wrapper>
  );
}

Table.propTypes = {
  intl: PropTypes.object,
  type: PropTypes.oneOf([Tables.CATEGORIES, Tables.BEHAVIOR, Tables.UNITS]),
  limit: PropTypes.number,
};

export default injectIntl(Table);
