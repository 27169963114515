/**
 *
 * Sidebar
 *
 */

import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

// Context
import AppContext from 'containers/App/context';

// Authentication
import { Auth } from 'aws-amplify';

// Components
import Avatar from 'components/Avatar';
import { NavLink } from 'react-router-dom';

// Translations
import { FormattedMessage } from 'react-intl';

// Assets
import { ReactComponent as LogoutIcon } from 'images/sidebar/logout.svg';
import { ReactComponent as LogoIcon } from 'images/sidebar/logo.svg';
import { ReactComponent as BackArrowIcon } from 'images/shared/back-arrow.svg';

// Data
import sections from './sections';

// Utils
import {
  getUserdata,
  deleteUserdata,
  deleteTenant,
  deleteOrganizationUnit,
  deleteBenchmark,
  getTenant,
} from 'utils/storage';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.aside`
  width: ${props => (props.collapsed ? '64px' : '240px')};
  transition: width 0.2s;
  height: 100%;
  background-color: ${Colors.GRADIENT_NOT_COLLAPSED};
  ${props =>
    props.collapsed
      ? `background: linear-gradient(
    120deg,
    ${Colors.PURPLE},
    ${Colors.CYAN} 90%
  );`
      : `background: linear-gradient(
        120deg,
        ${Colors.PURPLE} 1%,
        ${Colors.CYAN} 90%
      );`}

  float: left;
  display: flex;
  flex-direction: column;
  user-select: none;
`;

const UserInfo = styled.div`
  padding: ${Margins.LARGE}px ${Margins.REGULAR}px 0 ${Margins.MEDIUM}px;
  ${props =>
    props.collapsed &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  `};
`;

const UserName = styled.span`
  font-weight: bold;
  font-size: 14.2px;
  line-height: 20px;
  letter-spacing: 0.2px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
`;

const Logout = styled(LogoutIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    fill: rgba(255, 255, 255, 1);
  }
`;

const NavWrapper = styled.div`
  margin-top: ${Margins.XXXXLARGE}px;
`;

const Title = styled.div`
  width: 100%;
  height: 32px;
  font-size: 12.2px;
  font-weight: bold;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.7);
  padding-left: ${Margins.MEDIUM}px;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
  border-bottom: ${props =>
    props.collapsed ? '1px solid rgba(255, 255, 255, 0.2)' : 0};

  span {
    opacity: ${props => (props.collapsed ? '0' : '1')};
  }
`;

const Routes = styled.nav``;

const Route = styled(NavLink)`
  width: 100%;
  height: 32px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: ${props => (props.collapsed ? 'center' : 'flex-start')};
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-decoration: none;
  padding-left: ${props => (props.collapsed ? 0 : Margins.MEDIUM)}px;
  transition: background-color 0.2s ease-in;

  :hover,
  &.active {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const RouteName = styled.span`
  margin-left: ${Margins.REGULAR}px;
`;

const Footer = styled.footer`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: ${props => (props.collapsed ? 'center' : 'space-between')};
  padding: 0 ${Margins.MEDIUM}px ${Margins.XXLARGE}px ${Margins.MEDIUM}px;
  ${props =>
    props.collapsed &&
    `
    padding-left: 0;
    padding-right: 0;
  `};
`;

const Logo = styled(LogoIcon)`
  width: 120px;
  height: 60px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 45px;
`;

const BackArrow = styled(BackArrowIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 0.7);
  transform: ${props =>
    props.collapsed ? 'rotateZ(180deg)' : 'rotateZ(0deg)'};
  transition: all 0.2s ease;

  :hover {
    color: rgba(255, 255, 255, 1);
  }
`;

export function Sidebar() {
  const appContext = useContext(AppContext);
  const [width, setWidth] = useState(window.screen.width);
  const [collapsed, setCollapsed] = useState(false);
  const [userdata, setUserdata] = useState({});
  const tenant = getTenant();

  useEffect(() => {
    setUserdata(getUserdata());
    setWidth(window.screen.width);
    setCollapsed(width < 1450);
  }, []);

  const logout = async () => {
    await Auth.signOut();
    setUserdata({});
    deleteUserdata();
    deleteTenant();
    deleteOrganizationUnit();
    deleteBenchmark();
    appContext.setIsAuth(false);
  };

  const renderUserInfo = () => {
    if (userdata.displayName) {
      return (
        <UserInfo collapsed={collapsed}>
          <Avatar name={userdata.displayName} round size={40} />
          <UserName>
            {!collapsed && <span>{userdata.displayName}</span>}
            <Logout onClick={logout} />
          </UserName>
        </UserInfo>
      );
    }
    return null;
  };

  const renderRoutes = routes => (
    <Routes>
      {routes.map(item => {
        console.log('');
        return item.key !== 'skills' || tenant.isSkillsEnabled ? (
          <Route
            key={item.key}
            to={item.route}
            collapsed={collapsed ? 'true' : undefined}
            activeClassName="active"
          >
            {item.icon}
            {!collapsed && (
              <RouteName>
                <FormattedMessage {...item.title} />
              </RouteName>
            )}
          </Route>
        ) : null;
      })}
    </Routes>
  );
  /*
    const renderRoutes1 = routes => (
      <Routes>
        {routes.map(item => (
          <Route
            key={item.key}
            to={item.route}
            collapsed={collapsed ? true : undefined}
            activeClassName="active"
          >
            {item.icon}
            {!collapsed && (
              <RouteName>
                <FormattedMessage {...item.title} />
              </RouteName>
            )}
          </Route>
        ))}
      </Routes>
    );
    */

  const renderSections = () => (
    <>
      {sections.map(section => (
        <NavWrapper key={section.key}>
          <Title collapsed={collapsed}>
            <FormattedMessage {...section.title} />
          </Title>

          {renderRoutes(section.data)}
        </NavWrapper>
      ))}
    </>
  );

  const renderFooter = () => (
    <Footer collapsed={collapsed}>
      {!collapsed && <Logo />}
      {width < 1300 ? (
        undefined
      ) : (
        <IconContainer>
          <BackArrow
            collapsed={collapsed ? true : undefined}
            onClick={() => setCollapsed(!collapsed)}
          />
        </IconContainer>
      )}
    </Footer>
  );

  return (
    <Wrapper collapsed={collapsed}>
      {renderUserInfo()}

      {renderSections()}

      {renderFooter()}
    </Wrapper>
  );
}

export default Sidebar;
