/**
 *
 * Benchmark
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import {
  PALE_PEACH,
  LIGHT_BLUE_GREY,
  BLACK,
  BATTLESHIP_GREY,
} from 'constants/colors';
import * as Margins from 'constants/margins';

// Utils
import { formatData } from './utils';

// Style
const Wrapper = styled.div`
  display: flex;
`;

const KPI = styled.div`
  height: ${Margins.LARGE}px;
  border-radius: ${Margins.LARGE}px;
  padding: ${Margins.SMALL}px ${Margins.SMALL}px;
  background-color: ${props => (props.positive ? PALE_PEACH : LIGHT_BLUE_GREY)};
  color: ${BLACK};
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.2px;
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.11px;
  margin-left: ${Margins.REGULAR}px;
  color: ${BATTLESHIP_GREY};
`;

function Benchmark({ className, value, label }) {
  const kpi = value ? formatData(value) : '0%';

  return (
    <Wrapper className={className}>
      <KPI positive={value >= 0} data-testid="benchmark-kpi">
        {kpi}
      </KPI>
      {label && <Label data-testid="benchmark-label">{label}</Label>}
    </Wrapper>
  );
}

Benchmark.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
};

export default Benchmark;
