import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AreaSelector';

export default defineMessages({
  modalTitle: {
    id: `${scope}.modalTitle`,
    defaultMessage: 'Seleziona Area Aziendale',
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Errore durante il caricamento dei dati.',
  },
});
