import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Helmet } from 'react-helmet';
import InvitePage from './components/InvitePage';
import InviteSuccess from './components/InviteSuccess';
import InviteFailed from './components/InviteFailed';

// Costants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// API
import { acceptInvite } from './api';

// Assets
import { ReactComponent as OriginalLogoIcon } from '../../images/login/logo.svg';

// Types
import { INVITE_ACCEPTED, INVITE_FAILED } from './components/inviteTypes';

// Translations
import { useIntl } from 'react-intl';
import messages from './messages';

// Styles
const Wrapper = styled.div`
  background-color: ${Colors.LIGHT_NAVY};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${Margins.XXXXLARGE}px;
  overflow-y: auto;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoIcon = styled(OriginalLogoIcon)`
  width: 110px;
  height: 110px;
`;

const Content = styled.div`
  color: #ffffff;
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
`;

const ViralSharing = ({ match }) => {
  const [email, setEmail] = useState('');
  const [inviteStatus, setInviteStatus] = useState(undefined);
  const [successData, setSuccessData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { slug } = match.params;

  const intl = useIntl();

  useEffect(() => {
    setError(null);
  }, [email]);

  const resetInvite = () => {
    setInviteStatus(undefined);
    setEmail('');
    setSuccessData(undefined);
    setLoading(false);
    setError(null);
  };

  const extractFromSlug = desiredData => {
    const namePortions = slug.split('_');
    const invitationId = namePortions.pop();
    const name = namePortions.join(' ');
    if (desiredData === 'id') return invitationId;
    if (desiredData === 'name') return name;
    return null;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setError(false);
    setLoading(true);

    const res = await acceptInvite({
      invitationId: extractFromSlug('id'),
      email,
    });
    const resData = await res.json();
    switch (res.status) {
      case 200:
        setSuccessData(resData);
        setInviteStatus(INVITE_ACCEPTED);
        break;

      case 404:
        // User or invite id not found in FeedbackLoop
        if (resData.errorMessage === 'INVITE_NOT_FOUND') {
          setError(intl.formatMessage(messages.inviteNotFoundError));
        } else if (resData.errorMessage === 'USER_NOT_FOUND') {
          setInviteStatus(INVITE_FAILED);
        }
        break;

      case 409:
        // Conflict: User alredy confirmed
        setError(intl.formatMessage(messages.conflictError));
        break;

      default:
        setError(intl.formatMessage(messages.genericError));
    }
    setLoading(false);
  };

  const renderInvitePage = () => {
    switch (inviteStatus) {
      case INVITE_ACCEPTED:
        return <InviteSuccess email={email} successData={successData} />;
      case INVITE_FAILED:
        return <InviteFailed email={email} handleReset={resetInvite} />;
      default:
        return (
          <InvitePage
            email={email}
            invitationId={+extractFromSlug('id')}
            onEmailChange={setEmail}
            coworkerName={extractFromSlug('name')}
            changeInviteStatus={setInviteStatus}
            handleRetry={resetInvite}
            loading={loading}
            error={error}
            handleSubmit={handleSubmit}
          />
        );
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Viral Sharing</title>
        <meta name="description" content="Description of Viral Sharing" />
      </Helmet>
      <LogoWrapper>
        <LogoIcon />
      </LogoWrapper>

      <Content>{renderInvitePage()}</Content>
    </Wrapper>
  );
};

ViralSharing.propTypes = {
  match: PropTypes.object,
};

export default ViralSharing;
