import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import SimpleText from './SimpleText';
import BoldText from './BoldText';
import Group from './Group';
import Trend from './Trend';
import SimpleBar from 'components/SimpleBar';
import AdvancedBar from 'components/AdvancedBar';
import Benchmark from 'components/Benchmark';
import Dispersion from './Dispersion';
import User from './User';
import Checkbox from 'components/Checkbox';
import Legend from './Legend';

// Translations
import { injectIntl } from 'react-intl';
import messages from '../messages';

import checkboxThemeShape from 'components/Checkbox/checkboxTheme';

// Constants
import * as Columns from '../columns';
import * as Margins from 'constants/margins';
import * as CheckboxStatuses from 'components/Checkbox/selectStatuses';

// Style
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${Margins.REGULAR}px;
  flex: ${props => (props.span > 0 ? props.span : '0 1 auto')};
  margin-right: 16px;
  overflow: hidden;
  min-width: 55px;
`;

function Cell({
  intl,
  span,
  data,
  checkboxTheme,
  onSelect,
  selectStatus,
  expanded,
  onExpand,
  isExtraCell,
}) {
  const renderCell = () => {
    console.log('renderCell', data.type || data.cellType);
    switch (data.type || data.cellType) {
      case Columns.TEXT: {
        if (data.bold) {
          return <BoldText {...data} enablePopover={isExtraCell} />;
        }
        return <SimpleText {...data} enablePopover={isExtraCell} />;
      }

      case Columns.GROUP:
        return <Group {...data} />;

      case Columns.TREND:
        return <Trend {...data} />;

      case Columns.DISPERSION:
        return <Dispersion value={data.value || data.dispersion} />;

      case Columns.BOOLEAN_BAR: {
        const value = data.value ? 100 : 0;
        return <SimpleBar value={value} showLabels={false} />;
      }

      case Columns.ENERGY_BAR: {
        const width = data.total ? (data.total / data.maxLength) * 100 : 0;
        const value =
          data.partial && data.total ? (data.partial / data.total) * 100 : 0;
        return <SimpleBar width={width} value={value} showLabels={false} />;
      }

      case Columns.PROGRESS_BAR:
        return (
          <AdvancedBar bars={data.values} max={data.max} showLabels={false} />
        );

      case Columns.BENCHMARK: {
        const label = intl.formatMessage(
          data.benchmark >= 0 ? messages.over : messages.under,
        );
        return <Benchmark {...data} label={label} />;
      }

      case Columns.USER:
        return <User {...data} expanded={expanded} onExpand={onExpand} />;

      case Columns.SELECT:
        return (
          <Checkbox
            checkboxTheme={checkboxTheme}
            onChange={onSelect}
            selectStatus={selectStatus}
            {...data}
          />
        );

      case Columns.LEGEND:
        return <Legend {...data} />;

      case Columns.ACTION:
        return data.icon;

      default:
        return null;
    }
  };

  return <Wrapper span={span}>{renderCell()}</Wrapper>;
}

Cell.propTypes = {
  intl: PropTypes.object,
  span: PropTypes.number,
  data: PropTypes.object,
  checkboxTheme: PropTypes.shape(checkboxThemeShape),
  onSelect: PropTypes.func,
  selectStatus: PropTypes.oneOf([
    false,
    CheckboxStatuses.SINGLESELECTION,
    CheckboxStatuses.PARTIALSELECTION,
    CheckboxStatuses.COMPLETESELECTION,
  ]),
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  isExtraCell: PropTypes.bool,
};

Cell.defaultProps = {
  span: 1,
};

export default injectIntl(Cell);
