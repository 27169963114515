/**
 *
 * FloatingInput
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import * as Colors from 'constants/colors';

// Style
const Wrapper = styled.div`
  ${props => (props.width ? `width: ${props.width}px` : 'width: 100%')};
  margin: 8px auto;
  background-color: ${props => props.backgroundColor};
  border: 2px solid ${props => (props.error ? Colors.ERROR_RED : 'transparent')};
  border-radius: 12px;
  position: relative;
  transition: 0.2s ease-out all;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'default' : 'text')};
  border: 2px solid #f5f5f5;
`;

const FloatingLabel = styled.label`
  position: absolute;
  color: ${props => (props.color ? props.color : '#f5f5f5')};
  opacity: 0.5;
  font-weight: normal;
  font-style: normal;
  transition: all 0.3s ease-out;
  top: 14px;
  left: 8px;

  &.active {
    font-size: 12.2px;
    font-weight: bold;
    opacity: 1;
    top: 8px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 24px 16px 8px 8px;
  background-color: transparent;
  border: none;
  color: ${props => (props.color ? props.color : '#f5f5f5')};
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.15px;

  :focus {
    outline: none;
  }

  :disabled {
    cursor: default;
  }

  &:-webkit-autofill {
    -webkit-transition-delay: 9999999s;
  }
`;

function FloatingInput({
  id,
  type,
  placeholder,
  onChange,
  value,
  error,
  disabled,
  width,
  color,
  backgroundColor,
}) {
  // State
  const [floating, setFloating] = useState(false);

  useEffect(() => {
    if (value !== '') setFloating(true);
  }, [value]);

  const handleBlur = e => {
    if (e.target.value === '') setFloating(false);
  };

  return (
    <Wrapper
      data-testid="floatingInput-wrapper"
      error={error}
      disabled={disabled}
      width={width}
      backgroundColor={backgroundColor}
    >
      <FloatingLabel
        className={floating && 'active'}
        htmlFor={id}
        data-testid="floatingInput-label"
        color={color}
      >
        {placeholder}
      </FloatingLabel>
      <Input
        id={id}
        type={type}
        onChange={onChange}
        value={value}
        onFocus={() => setFloating(true)}
        onBlur={e => handleBlur(e)}
        disabled={disabled}
        color={color}
        data-testid="floatingInput-input"
      />
    </Wrapper>
  );
}

FloatingInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

FloatingInput.defaultProps = {
  type: 'text',
  color: '#ffffff',
  backgroundColor: Colors.SAPPHIRE,
};

export default FloatingInput;
