/**
 *
 *
 * SubsectionTitle
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import * as Margins from 'constants/margins';
import { BATTLESHIP_GREY } from 'constants/colors';

// Style
const Wrapper = styled.div`
  min-width: 270px;
  width: 270px;
  display: flex;
  flex-direction: column;
  user-select: none;
  margin-left: 50px;
`;

const Info = styled.div`
  width: 100%;
  min-height: 212px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: bold;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.3px;
  margin-top: ${Margins.MEDIUM}px;
`;

const Body = styled.span`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.11px;
  color: ${BATTLESHIP_GREY};
  margin-top: ${Margins.MEDIUM}px;
`;

function SubsectionTitle({ icon, title, body, children }) {
  return (
    <Wrapper>
      <Info>
        {icon && icon}
        {<Title>{title}</Title>}
        {body && <Body>{body}</Body>}
      </Info>
      {children}
    </Wrapper>
  );
}

SubsectionTitle.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default SubsectionTitle;
