import React from 'react';

// Authentication
import Amplify from 'aws-amplify';
import awsConfig from '../../aws-exports';

function AmplifyProvider({ children }) {
  React.useEffect(() => {
    Amplify.configure(awsConfig);
  }, []);

  return children;
}

export default AmplifyProvider;
