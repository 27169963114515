import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Title, Subtitle, Paragraph, Link } from '../commonStyles';
import Button from 'components/Button';

// Translations
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';

// Constants
import * as Margins from 'constants/margins';
import * as Colors from 'constants/colors';

// Styles
const RetryButton = styled(Button)`
  text-transform: uppercase;
  color: #FFFFF;
  border: 1px solid white;
  align-self: flex-start;
  padding: ${Margins.SMALL}px ${Margins.MEDIUM}px;
  height: auto;
  display: block;
  margin-top: ${Margins.LARGE}px;
  font-weight: 300;

  &:hover {
    background-color: #ffffff;

    > span {
      color: ${Colors.LIGHT_NAVY};
    }
  }
`;

const InviteFailed = ({ email, handleReset }) => {
  const intl = useIntl();
  return (
    <>
      <Title>
        <FormattedMessage {...messages.inviteFailedTitle} />
      </Title>
      <Subtitle>
        <FormattedMessage
          {...messages.inviteFailedSubtitle}
          values={{ email }}
        />
      </Subtitle>
      <RetryButton
        type="accent"
        text={intl.formatMessage(messages.inviteFailedRetry)}
        onClick={handleReset}
      />
      <Paragraph>
        <FormattedMessage {...messages.inviteFailedSupport} />
        <Link target="_blank" href="mailto:supporto@feedbackloop.eu">
          supporto@feedbackloop.eu
        </Link>
      </Paragraph>
    </>
  );
};

InviteFailed.propTypes = {
  email: PropTypes.string,
  handleReset: PropTypes.func,
};

export default InviteFailed;
