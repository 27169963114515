/**
 *
 * Button
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import { BLACK, LIGHT_GREY_BLUE, LIGHT_NAVY } from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.div`
  height: 36px;
  text-transform: uppercase;
  border-radius: ${Margins.REGULAR}px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.type === 'default' ? LIGHT_GREY_BLUE : LIGHT_NAVY};
  background-color: ${props =>
    props.type === 'default' ? 'white' : LIGHT_NAVY};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Margins.REGULAR}px;
  cursor: pointer;

  span {
    font-weight: bold;
    font-size: 14.2px;
    line-height: 20px;
    letter-spacing: 1px;
    color: ${props => (props.type === 'default' ? BLACK : 'white')};
    user-select: none;
  }
`;

function Button({ className, type, text, onClick }) {
  return (
    <Wrapper
      data-testid="button-wrapper"
      className={className}
      type={type}
      onClick={onClick}
    >
      <span data-testid="button-text">{text}</span>
    </Wrapper>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'accent']),
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  type: 'default',
};

export default Button;
