// Constants
import * as Columns from '../columns';

const ASC = 'asc';
const DESC = 'desc';

export const getSortedByType = (
  data,
  type = null,
  sortOrder = null,
  index = null,
) => {
  switch (type) {
    case Columns.TEXT:
      return data.sort(compare(index, 'label', sortOrder));

    case Columns.GROUP:
    case Columns.USER:
      return data.sort(compareNumber(index, 'count', sortOrder));

    case Columns.TREND:
      return data.sort(compareNumber(index, 'trend', sortOrder));

    case Columns.DISPERSION:
      return data.sort(compareNumber(index, 'dispersion', sortOrder));

    case Columns.BOOLEAN_BAR:
    case Columns.BENCHMARK:
      return data.sort(compare(index, 'value', sortOrder));

    case Columns.ENERGY_BAR:
      return data.sort(compareNumber(index, 'partial', sortOrder));

    case Columns.PROGRESS_BAR:
      return data.sort(compareProgressBar(index, 'values', sortOrder));

    default:
      return data;
  }
};

const compare = (index, key, sortOrder) => (a, b) => {
  switch (sortOrder) {
    case ASC: {
      if (a.cells[index][key] < b.cells[index][key]) {
        return -1;
      }
      if (a.cells[index][key] > b.cells[index][key]) {
        return 1;
      }
      return 0;
    }

    case DESC:
      if (a.cells[index][key] > b.cells[index][key]) {
        return -1;
      }
      if (a.cells[index][key] < b.cells[index][key]) {
        return 1;
      }
      return 0;

    default:
      return 0;
  }
};

const compareNumber = (index, key, sortOrder) => (a, b) => {
  switch (sortOrder) {
    case ASC:
      return a.cells[index][key] - b.cells[index][key];

    case DESC:
      return b.cells[index][key] - a.cells[index][key];

    default:
      return 0;
  }
};

const compareProgressBar = (index, key, sortOrder) => (a, b) => {
  switch (sortOrder) {
    case ASC:
      return a.cells[index][key] - b.cells[index][key];

    case DESC:
      return b.cells[index][key][0].value - a.cells[index][key][0].value;

    default:
      return 0;
  }
};
