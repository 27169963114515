import { Storage } from 'aws-amplify';

export const getImageURI = async source => {
  const key = source.trim().replace(/^\//g, '');

  const options = {
    level: 'public',
    customPrefix: { public: '' },
  };
  const presignedURL = await Storage.get(key, options);

  return presignedURL;
};
