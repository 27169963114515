import React from 'react';

// Components
// import Kpis from './Kpis';
import Trends from './Trends';
import DynamicTable from './DynamicTable';

function Organization() {
  return (
    <>
      {/* <Kpis /> */}
      <Trends />
      <DynamicTable type="emotion-type0" />
      <DynamicTable type="emotion-type1" />
      {/* <DynamicTable type="context" />
      <DynamicTable type="activity" /> */}
      {/* <DynamicTable type="unit" /> */}
    </>
  );
}

export default Organization;
