export const hexToRGBA = (hex, alpha = 1) => {
  const hexCopy = hex.startsWith('#') ? hex.substr(1) : hex;
  if (hexCopy.length !== 6) return undefined;

  const hexArray = hexCopy
    .toLowerCase()
    .match(/.{2}/g)
    .map(digit => parseInt(digit, 16));

  return `rgba(${hexArray.join(', ')}, ${alpha})`;
};
