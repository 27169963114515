import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import ReactTooltip from 'react-tooltip';

// Constants
import { PALE_PEACH } from 'constants/colors';
import { getColors } from '../bubbleColors';

// Style
const Wrapper = styled.div`
  opacity: ${props => props.opacity};
  border: 1px solid #222222;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  position: absolute;
  bottom: ${props => props.bottom}px;
  left: ${props => props.left}px;
  z-index: ${props => props.zIndex};
  background: ${props => getColors(props.color)};
  color: ${props => props.textColor};
  border-radius: 100%;
  transition: opacity 1.5s ease, bottom 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%) translateY(50%);
  cursor: ${props => (props.showTooltip ? 'pointer' : 'default')};
  transition: all 0.5s ease;

  .name {
    font-weight: bold;
    font-size: 14.2px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
`;

const Benchmark = styled.div`
  position: absolute;
  width: ${props => props.size + 5}px;
  height: ${props => props.size + 5}px;
  border: 2px solid ${PALE_PEACH};
  border-radius: 100%;
`;

const Tooltip = styled(ReactTooltip)`
  &.custom-theme {
    border: 1px solid #262626;
    padding: 0;
  }
`;

const TooltipContent = styled.div`
  background-color: rba(38, 38, 38, 0.2);
  padding: 16px;
`;

function Bubble(props) {
  const [opacity, setOpacity] = useState(0);
  const [bottom, setBottom] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpacity(0.8);
      setBottom(props.bottom);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Wrapper
        {...props}
        opacity={opacity}
        bottom={bottom}
        data-tip
        data-for={props.title}
        data-event="click focus"
        onMouseEnter={props.handleEnter}
      >
        {props.benchmark > 0 && <Benchmark size={props.size + 10} />}
        <span className="name">{props.title.substr(0, 2)}</span>
      </Wrapper>
      <Tooltip
        globalEventOff="click"
        id={props.title}
        effect="solid"
        className="custom-theme"
      >
        <TooltipContent>
          {props.title} ({props.value})
        </TooltipContent>
      </Tooltip>
    </>
  );
}

Bubble.propTypes = {
  value: PropTypes.number,
  size: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  zIndex: PropTypes.number,
  color: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  benchmark: PropTypes.bool,
  showTooltip: PropTypes.bool,
  handleEnter: PropTypes.func,
};

Bubble.defaultProps = {
  textColor: 'white',
  showTooltip: true,
};

export default Bubble;
