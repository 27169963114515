export const getColors = color => {
  switch (color) {
    case '#1abc9c':
      return 'linear-gradient(120deg, #ff88e3, #cc31ff)';
    case '#2ecc71':
      return 'linear-gradient(120deg, #ff88e3, #19f4ab)';
    case '#3498db':
      return 'linear-gradient(120deg, #ff88e3, #61d2ff)';
    case '#9b59b6':
      return 'linear-gradient(120deg, #ff88e3, #f9f032)';
    case '#34495e':
      return 'linear-gradient(120deg, #ff88e3, #0FA3B1)';
    case '#16a085':
      return 'linear-gradient(120deg, #ff88e3, #FFC09F)';
    case '#27ae60':
      return 'linear-gradient(120deg, #ff88e3, #FF8552)';
    case '#2980b9':
      return 'linear-gradient(120deg, #ff88e3, #AFD2E9)';
    case '#8e44ad':
      return 'linear-gradient(120deg, #ff88e3, #048A81)';
    case '#2c3e50':
      return 'linear-gradient(120deg, #cc31ff, #19f4ab)';
    case '#f1c40f':
      return 'linear-gradient(120deg, #cc31ff, #61d2ff)';
    case '#e67e22':
      return 'linear-gradient(120deg, #cc31ff, #f9f032)';
    case '#e74c3c':
      return 'linear-gradient(120deg, #cc31ff, #0FA3B1)';
    case '#ecf0f1':
      return 'linear-gradient(120deg, #cc31ff, #FFC09F)';
    case '#95a5a6':
      return 'linear-gradient(120deg, #cc31ff, #FF8552)';
    case '#f39c12':
      return 'linear-gradient(120deg, #cc31ff, #AFD2E9)';
    case '#d35400':
      return 'linear-gradient(120deg, #cc31ff, #048A81)';
    case '#c0392b':
      return 'linear-gradient(120deg, #19f4ab, #61d2ff)';
    case '#bdc3c7':
      return 'linear-gradient(120deg, #19f4ab, #f9f032)';
    case '#7f8c8d':
      return 'linear-gradient(120deg, #19f4ab, #0FA3B1)';
    default:
      return 'linear-gradient(120deg, #61d2ff, #f9f032)';
  }
};
