import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import SubsectionTitle from 'components/SubsectionTitle';
import { ClipLoader } from 'react-spinners';
import Error from 'components/Error';

// Translations
import { injectIntl } from 'react-intl';
import messages from '../messages';

// APIs
import { fetchOrganizationUnit } from 'containers/AreaSelector/api';
import { fetchTrends } from '../api';

// Constants
import periods from 'utils/periodGenerator';
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Images
import { ReactComponent as Comparison } from 'images/sections/comparison.svg';
import MultiLineChart from 'components/MultiLineChart';

// Style
const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 50px;
  margin-left: ${Margins.XXLARGE}px;
  display: flex;
  justify-content: center;
`;

const ComparisonIcon = styled(Comparison)`
  width: 50px;
  height: 50px;
`;

function Trends({ intl }) {
  const {
    unitId,
    period,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  } = useContext(AuthContext);

  const { start, end } = periods[period];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    let canceled = false;
    const fetchData = async () => {
      setLoading(true);
      setData([]);
      try {
        const dataJson = await fetchOrganizationUnit(unitId);
        const oUnit = await dataJson.json();
        const response = await fetchTrends(
          oUnit.id,
          start,
          end,
          benchmarkOrganizationUnitId,
          benchmarkStart,
          benchmarkEnd,
        );
        const json = await response.json();
        if (!canceled) {
          setLoading(false);
          setData(json);
        }
      } catch {
        if (!canceled) {
          setLoading(false);
          setError(true);
        }
      }
    };

    fetchData();

    return () => {
      canceled = true;
    };
  }, [
    unitId,
    start,
    end,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  ]);

  const renderLoading = () => {
    if (loading) {
      return <ClipLoader size={20} color={Colors.LIGHT_NAVY} />;
    }
    return null;
  };

  const renderBubbles = () => {
    if (!loading && !error && data) {
      return <MultiLineChart unparsedData={data} start={start} end={end} />;
    }
    return null;
  };

  const renderError = () => {
    if (!loading && error) {
      return <Error />;
    }
    return null;
  };

  return (
    <Wrapper>
      <SubsectionTitle
        icon={<ComparisonIcon />}
        title={intl.formatMessage(messages.bubblesTitle)}
        body={intl.formatMessage(messages.bubblesSubtitle)}
      />

      <Content>
        {renderLoading()}
        {renderBubbles()}
        {renderError()}
      </Content>
    </Wrapper>
  );
}

Trends.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(Trends);
