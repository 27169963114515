import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Title, Subtitle, Paragraph } from '../commonStyles';

// Assets
import PlayStoreLogo from 'images/shared/playstore.png';
import AppStoreLogo from 'images/shared/appstore.png';

// Translations
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

// Constants
import * as Margins from 'constants/margins';
import { APPSTORE_URL, PLAYSTORE_URL } from 'utils/globals';

// Styles
const Stores = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: ${Margins.MEDIUM}px;
`;

const StoreLogo = styled.a`
  width: 48%;

  > img {
    width: 100%;
    height: auto;
  }
`;

const InviteSuccess = ({ email, successData }) => {
  const { name, members, tenantName } = successData;
  return (
    <>
      <Title>
        <FormattedMessage
          {...messages.inviteSuccessTitle}
          values={{ name, members, tenantName }}
        />
      </Title>
      <Subtitle>
        <FormattedMessage
          {...messages.inviteSuccessSubtitle}
          values={{ email }}
        />
      </Subtitle>
      <Paragraph>
        <FormattedMessage {...messages.inviteSuccessDownload} />
      </Paragraph>
      <Stores>
        <StoreLogo href={APPSTORE_URL}>
          <img src={AppStoreLogo} alt="" />
        </StoreLogo>
        <StoreLogo href={PLAYSTORE_URL}>
          <img src={PlayStoreLogo} alt="" />
        </StoreLogo>
      </Stores>
    </>
  );
};

InviteSuccess.propTypes = {
  email: PropTypes.string,
  members: PropTypes.number,
  name: PropTypes.string,
  successData: PropTypes.object,
};

export default InviteSuccess;
