import messages from './messages';

import { OBSERVED, SUGGESTED } from 'constants/tabs';

export default [
  {
    key: OBSERVED,
    name: messages.observedTab,
  },
  {
    key: SUGGESTED,
    name: messages.suggestedTab,
  },
  // {
  //   key: GROUPS,
  //   name: messages.groupsTab,
  // },
  // {
  //   key: HEATMAP,
  //   name: messages.heatmapTab,
  // },
];
