/**
 *
 * AdvancedBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortid from 'shortid';

// Components
import ReactTooltip from 'react-tooltip';

// Constants
import * as Margins from 'constants/margins';
// import * as Colors from 'constants/colors';
import { simpleBar, advancedBar } from './barOptions';

// Style
const Wrapper = styled.div`
  width: 100%;
  height: ${props => props.height}px;
  border-radius: ${Margins.REGULAR}px;
  overflow: hidden;
  position: relative;
  display: flex;

  > div:last-child .bar {
    border-right: none;
  }
`;

const Bar = styled.div`
  height: 100%;
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  border-right: ${Margins.XSMALL}px solid #ffffff;
  position: absolute;
  width: ${props => props.width}%;
  ${props => props.rounded && `border-radius: ${Margins.REGULAR}px`}
  z-index: ${props => props.zIndex};
  top: 0;
  left: 0;
`;

const Label = styled.span`
  position: absolute;
  left: calc(${props => props.left}% + 10px);
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 12px;
  line-height: 1.33;
  user-select: none;
  z-index: 5;
`;

function AdvancedBar({ height, showLabels, bars, roundedBars, max }) {
  const barOptions = bars.length === 2 ? simpleBar : advancedBar;
  let reachedWidth = 0;
  let reachedValue = 0;

  const renderBars = barIndex => (
    <Bar
      key={shortid.generate()}
      data-testid="Bar-bar"
      color={barOptions[barIndex].color}
      rounded={roundedBars}
      zIndex={barOptions[barIndex].zIndex}
      width={reachedWidth}
      className="bar"
    />
  );

  const renderLabel = (value, left, label) => {
    const labelUniqueId = shortid.generate();
    if (showLabels) {
      return (
        <>
          <ReactTooltip
            key={shortid.generate()}
            id={`bar-${labelUniqueId}`}
            effect="solid"
            data-testid="Bar-tooltip"
          >
            <span>{label}</span>
          </ReactTooltip>
          <Label
            key={labelUniqueId}
            data-tip
            data-for={`bar-${labelUniqueId}`}
            left={left}
            data-testid="Bar-label"
          >
            {value}
          </Label>
        </>
      );
    }
    return null;
  };

  return (
    <Wrapper data-testid="Bar-wrapper" height={height}>
      {bars.map((bar, i) => {
        const width = (bar.value / max) * 100;
        reachedWidth += width;
        reachedValue += bar.value;
        if (bar.value > 0) {
          return (
            <div key={shortid.generate()}>
              {renderBars(i)}
              {renderLabel(bar.value, reachedValue - bar.value, bar.label)}
            </div>
          );
        }
        return null;
      })}
    </Wrapper>
  );
}

AdvancedBar.propTypes = {
  height: PropTypes.number,
  showLabels: PropTypes.bool,
  bars: PropTypes.array,
  max: PropTypes.number,
  roundedBars: PropTypes.bool,
};

AdvancedBar.defaultProps = {
  height: 12,
  showLabels: true,
  bars: [],
  max: 100,
  roundedBars: false,
};

export default AdvancedBar;
