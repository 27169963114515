import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Accounts';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Benvenuto!',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage:
      'Prima di poter visualizzare i dati generati da Yumi devi invitare degli utenti!',
  },
  uploadCorrect: {
    id: `${scope}.uploadCorrect`,
    defaultMessage: 'Il file è stato caricato correttamente!',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Non sai come formattare il file?',
  },
  template: {
    id: `${scope}.template`,
    defaultMessage: ' Scarica qui il nostro template',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Carica la lista degli utenti',
  },
  howTo: {
    id: `${scope}.howTo`,
    defaultMessage:
      'Trascina qui il documento oppure fai click per selezionarlo dal tuo PC!',
  },
  reupload: {
    id: `${scope}.reupload`,
    defaultMessage: 'Clicca qui per ricaricare il file',
  },
  upload: {
    id: `${scope}.upload`,
    defaultMessage: 'Carica il documento',
  },
  nextButton: {
    id: `${scope}.nextButton`,
    defaultMessage: 'Prosegui',
  },
  tableHeader: {
    id: `${scope}.tableHeader`,
    defaultMessage: 'Prima di caricare il file...',
  },
  tableDownload: {
    id: `${scope}.tableDownload`,
    defaultMessage: 'Scarica il nostro file di esempio',
  },
  tableDownloadDesc: {
    id: `${scope}.tableDownloadDesc`,
    defaultMessage:
      "Una volta scaricato il nostro file d'esempio potrai compilarlo aprendo in Excel",
  },
  tableData: {
    id: `${scope}.tableData`,
    defaultMessage: 'Inserisci i dati richiesti',
  },
  tableDataDesc: {
    id: `${scope}.tableDataDesc`,
    defaultMessage:
      'Compila tutti i dati obbligatori inserendo nelle colonne i dati richiesti per ogni persona da invitare a Yumi',
  },
  tableExport: {
    id: `${scope}.tableExport`,
    defaultMessage: 'Esporta il file e caricalo',
  },
  tableExportDesc: {
    id: `${scope}.tableExportDesc`,
    defaultMessage:
      'Esporta il tutto salvando il file come CSV separato da virgole',
  },
  compileCSV: {
    id: `${scope}.compileCSV`,
    defaultMessage: 'E se volessi compilare il CSV?',
  },
  compileCSVDesc: {
    id: `${scope}.compileCSVDesc`,
    defaultMessage:
      'Il CSV va formattato utilizzando le virgole e inserendo i seguenti campi:',
  },
  okButton: {
    id: `${scope}.okButton`,
    defaultMessage: 'CAPITO',
  },
});
