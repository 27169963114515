import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';

// Context
import Context from '../context';

// Constants
import { BATTLESHIP_GREY } from 'constants/colors';

// Style
const XAxisWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
`;

const AxisLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.11px;
  color: ${BATTLESHIP_GREY};
  position: absolute;
  width: 34px;
`;

const XAxisLabel = styled(AxisLabel)`
  left: ${props => props.left || 0}%;
  transform: translateX(-50%);
  text-align: center;
`;

const YAxisWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -40px;
`;

const YAxisLabel = styled(AxisLabel)`
  top: ${props => props.top || 0}%;
  transform: translateY(-50%);
  text-align: right;
`;

function AxisLabels() {
  const { showXLabels, xAxis, showYLabels, yAxis } = React.useContext(Context);

  const renderXAxisLabels = () => {
    if (showXLabels) {
      return (
        <XAxisWrapper>
          {xAxis.map((x, xIndex, axis) => (
            <XAxisLabel
              key={shortid.generate()}
              left={Math.floor(100 / (axis.length - 1)) * xIndex}
            >
              {`${x}%`}
            </XAxisLabel>
          ))}
        </XAxisWrapper>
      );
    }
    return null;
  };

  const renderYAxisLabels = () => {
    if (showYLabels) {
      return (
        <YAxisWrapper>
          {yAxis
            .slice()
            .reverse()
            .map((y, yIndex, axis) => (
              <YAxisLabel
                key={shortid.generate()}
                top={Math.floor(100 / (axis.length - 1)) * yIndex}
              >
                {`${y}%`}
              </YAxisLabel>
            ))}
        </YAxisWrapper>
      );
    }
    return null;
  };

  return (
    <>
      {renderXAxisLabels()}
      {renderYAxisLabels()}
    </>
  );
}

export default AxisLabels;
