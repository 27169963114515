import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import OriginalAvatar from 'components/Avatar';

// Constants
import * as Colors from 'constants/colors';
import * as Margins from 'constants/margins';

// Style
const Wrapper = styled.div`
  height: 52px;
  padding: ${Margins.MEDIUM}px ${Margins.REGULAR}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 14.2px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${Colors.BLACK};
  border-bottom: 1px solid rgba(38, 38, 38, 0.12);
  cursor: pointer;
  background-color: white;
  transition: all 0.2s;
  user-select: none;

  &:hover,
  &.selected {
    background-color: rgba(18, 46, 118, 0.1);
  }

  &.add-new {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.11px;
    color: ${Colors.BATTLESHIP_GREY};
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 18px);
  overflow: hidden;
`;

const Avatar = styled(OriginalAvatar)`
  margin-right: ${Margins.REGULAR}px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > .leaders {
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.11px;
    color: ${Colors.BATTLESHIP_GREY};
  }
`;

function Row({ className, name, leaders, icon, onClick, showAvatar }) {
  const testid =
    className.indexOf('add-new') > -1
      ? 'facetnavigation-extrarow'
      : 'facetnavigation-row';

  return (
    <Wrapper className={className} onClick={onClick} data-testid={testid}>
      <Content>
        {showAvatar && (
          <Avatar
            className="custom-avatar"
            maxInitials={2}
            name={name}
            round
            size={24}
            textSizeRatio={2}
          />
        )}
        <Info>
          <div className="area-label" title={name}>
            {name}
          </div>
          <div className="leaders" title={leaders}>
            {leaders}
          </div>
        </Info>
      </Content>
      {icon}
    </Wrapper>
  );
}

Row.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  leaders: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  showAvatar: PropTypes.bool,
};

Row.defaultProps = {
  showAvatar: true,
};

export default Row;
