import styled, { css } from 'styled-components';

// Images
import { ReactComponent as Share } from 'images/header/share.svg';
import { ReactComponent as Export } from 'images/header/export.svg';
import { ReactComponent as Import } from 'images/header/import.svg';
import { ReactComponent as Plus } from 'images/shared/plus.svg';
import { ReactComponent as Search } from 'images/shared/search.svg';

// Constants
import { LIGHT_GREY, DARK_90 } from 'constants/colors';
import { XXLARGE } from 'constants/margins';

export const CommonWrapper = styled.header`
  height: 80px;
  background-color: ${LIGHT_GREY};
  display: flex;
  align-items: center;
  padding: 0 ${XXLARGE}px;
`;

export const icon = css`
  width: 24px;
  height: 24px;
  color: ${DARK_90};
`;

export const ShareIcon = styled(Share)`
  ${icon};
`;
export const ExportIcon = styled(Export)`
  ${icon};
`;

export const ImportIcon = styled(Import)`
  ${icon};
`;

export const PlusIcon = styled(Plus)`
  ${icon};
`;

export const SearchIcon = styled(Search)`
  cursor: pointer;
`;
