import * as Columns from 'components/Table/columns';

export const categoriesColumns = [
  {
    key: 'category',
    label: 'Skill',
    span: 1,
    type: Columns.GROUP,
    visible: true,
  },
  {
    key: 'coverage',
    label: 'Coverage',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'distribution',
    label: 'Distribuzione',
    span: 1,
    type: Columns.PROGRESS_BAR,
    visible: false,
  },
  {
    key: 'benchmark',
    label: 'Benchmark',
    span: 1,
    type: Columns.BENCHMARK,
    visible: true,
  },
];

export const behaviorColumns = [
  {
    key: 'behavior',
    label: 'Behavior',
    span: 1,
    type: Columns.GROUP,
    visible: true,
  },
  {
    key: 'category',
    label: 'Category',
    span: 1,
    type: Columns.CATEGORY,
    visible: true,
  },
  {
    key: 'occurance',
    label: 'Occurance',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'distribution',
    label: 'Distribuzione',
    span: 1,
    type: Columns.PROGRESS_BAR,
    visible: false,
  },
  {
    key: 'benchmark',
    label: 'Benchmark',
    span: 1,
    type: Columns.BENCHMARK,
    visible: true,
  },
];

export const unitsColumns = [
  {
    key: 'unit',
    label: 'Unità',
    span: 1,
    type: Columns.GROUP,
    visible: true,
  },
  {
    key: 'coverage',
    label: 'Copertura',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'manager',
    label: 'Manager',
    span: 1,
    type: Columns.TEXT,
    visible: true,
  },
  {
    key: 'dispersion',
    label: 'Dispersion',
    span: 1,
    type: Columns.DISPERSION,
    visible: true,
  },
];

export const peopleColumns = [
  {
    key: 'group',
    label: 'Gruppo',
    span: 1,
    type: Columns.GROUP,
    visible: true,
  },
  {
    key: 'brilliance',
    label: 'Brilliance',
    span: 1,
    type: Columns.TREND,
    visible: true,
  },
  {
    key: 'dispersion',
    label: 'Dispersion',
    span: 1,
    type: Columns.DISPERSION,
    visible: true,
  },
];
