/**
 *
 * Card
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import * as Margins from 'constants/margins';

// Utils
export const getBoxShadow = heavyShadow =>
  `0 ${heavyShadow ? Margins.SMALL : 1}px ${
    heavyShadow ? Margins.REGULAR : Margins.SMALL
  }px 0 rgba(0,0,0,${heavyShadow ? 0.2 : 0.15})`;

// Style
const Wrapper = styled.div`
  width: 32%;
  background-color: white;
  border-radius: ${Margins.REGULAR}px;
  box-shadow: ${props => getBoxShadow(props.heavyShadow)};
  position: relative;
`;

function Card({ children, ...props }) {
  return (
    <Wrapper {...props} data-testid="card-wrapper">
      {children}
    </Wrapper>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  heavyShadow: PropTypes.bool,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  heavyShadow: false,
};

export default Card;
