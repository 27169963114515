import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import './style.css';
import Legend from './Legend';
// Constants
// import * as Colors from 'constants/colors';

/* Imports */
import * as d3 from 'd3';
import { HEIGHT, WIDTH } from './constants';
import { getColors } from './multiLineColors';

// Style
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MultiLineChart = props => {
  const { unparsedData, start, end } = props;

  useEffect(() => {
    const width = WIDTH;
    const height = HEIGHT;
    const margin = 20;
    const duration = 250;

    const lineOpacity = '0.7';
    const lineOpacityHover = '1';
    const otherLinesOpacityHover = '0.15';
    const lineStroke = '1.5px';
    const lineStrokeHover = '2.5px';

    const circleOpacity = '1';
    const circleOpacityOnLineHover = '0.25';
    const circleRadius = 3;
    const circleRadiusHover = 6;

    /* Format Data */

    const parseDate = d3.timeParse('%Y-%m-%d');
    const data = unparsedData.map(item => ({
      title: item.title,
      color: getColors(item.color),
      dailyEnergy: item.dailyEnergy.map(d => ({
        date: parseDate(moment(d.date).format('YYYY-MM-DD')),
        value: parseFloat(d.value).toFixed(2),
      })),
    }));

    if (!data.length) {
      return;
    }
    /* Scale */
    const xScale = d3
      .scaleTime()
      .domain([
        parseDate(moment(start).format('YYYY-MM-DD')),
        parseDate(moment(end).format('YYYY-MM-DD')),
      ])
      .range([0, width - margin]);

    const yScale = d3
      .scaleLinear()
      .domain([-17, +17])
      .range([height - margin, 0]);

    /* Add SVG */
    const svg = d3
      .select('#chart')
      .append('svg')
      .attr('width', `${width + margin}px`)
      .attr('height', `${height + margin}px`)
      .append('g')
      .attr('transform', `translate(${margin}, ${margin})`);

    /* Add line into SVG */
    const line = d3
      .line()
      .x(d => xScale(d.date))
      .y(d => yScale(d.value))
      .curve(d3.curveMonotoneX);

    const lines = svg.append('g').attr('class', 'lines');

    function makeXGridlines() {
      return d3.axisBottom(xScale).ticks(8);
    }
    function makeYGridlines() {
      return d3.axisLeft(yScale).ticks(5);
    }

    lines
      .selectAll('.line-group')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'line-group')
      .append('path')
      .attr('class', 'line')
      .attr('d', d => line(d.dailyEnergy))
      .style('stroke', d => d.color)
      .style('opacity', lineOpacity)
      .on('mouseover', event => {
        d3.selectAll('.line').style('opacity', otherLinesOpacityHover);
        d3.selectAll('.circle').style('opacity', circleOpacityOnLineHover);
        d3.select(event.toElement)
          .style('opacity', lineOpacityHover)
          .style('stroke-width', lineStrokeHover)
          .style('cursor', 'pointer');
      })
      .on('mouseout', () => {
        d3.selectAll('.line').style('opacity', lineOpacity);
        d3.selectAll('.circle').style('opacity', circleOpacity);
        d3.selectAll('.line').style('stroke-width', lineStroke);
      });

    /* Add circles in the line */
    lines
      .selectAll('circle-group')
      .data(data)
      .enter()
      .append('g')
      .style('fill', d => d.color)
      .selectAll('circle')
      .data(d => d.dailyEnergy)
      .enter()
      .append('g')
      .attr('class', 'circle')
      .on('mouseover', (event, day) => {
        d3.select(event.path[1])
          .style('cursor', 'pointer')
          .append('text')
          .attr('class', 'itemValue')
          .text(
            `day: ${moment(day.date).format('YYYY-MM-DD')}  value: ${
              day.value
            }`,
          )
          .attr('x', 500)
          .attr('y', 0)
          .attr('fill', '#000');
      })
      .on('mouseout', () => {
        d3.selectAll('.itemValue').remove();
      })
      .append('circle')
      .attr('cx', d => xScale(d.date))
      .attr('cy', d => yScale(d.value))
      .attr('r', circleRadius)
      .style('opacity', circleOpacity)
      .on('mouseover', event => {
        d3.select(event.toElement)
          .transition()
          .duration(duration)
          .attr('r', circleRadiusHover);
      })
      .on('mouseout', () => {
        d3.selectAll('circle')
          .transition()
          .duration(duration)
          .attr('r', circleRadius);
      });

    /* Add Axis into SVG */
    const xAxis = d3.axisBottom(xScale).ticks(8);
    const yAxis = d3.axisLeft(yScale).ticks(5);

    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${height - margin})`)
      .style('font-family', 'Muli-Regular')
      .call(xAxis);

    svg
      .append('g')
      .attr('class', 'y axis')
      .style('font-family', 'Muli-Regular')
      .call(yAxis)
      .append('text')
      .attr('class', 'text')
      .attr('y', -30)
      .attr('x', -120)
      .attr('transform', 'rotate(-90)')
      .attr('fill', '#000')
      .text('Energy');

    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,${height})`)
      .style('stroke-dasharray', '3,3')
      .call(
        makeXGridlines()
          .tickSize(-height)
          .tickFormat(''),
      );
    svg
      .append('g')
      .attr('class', 'grid')
      .style('stroke-dasharray', '3,3')
      .call(
        makeYGridlines()
          .tickSize(-width)
          .tickFormat(''),
      );
  }, [unparsedData]);

  return (
    <Container>
      <div id="chart" />
      <Legend data={unparsedData} />
    </Container>
  );
};
MultiLineChart.propTypes = {
  unparsedData: PropTypes.array,
  start: PropTypes.string,
  end: PropTypes.string,
};

export default MultiLineChart;
