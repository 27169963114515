/*
 * Mood Messages
 *
 * This contains all the text for the Mood container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Mood';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Energy',
  },
  organizationTab: {
    id: `global.tabs.organization`,
    defaultMessage: 'Organizzazione',
  },
  groupsTab: {
    id: `global.tabs.groups`,
    defaultMessage: 'Gruppi',
  },
  heatmapTab: {
    id: `global.tabs.heatmap`,
    defaultMessage: 'Heatmap',
  },
  kpisTitle: {
    id: `${scope}.kpisTitle`,
    defaultMessage: 'Energy snapshot',
  },
  kpisSubtitle: {
    id: `${scope}.kpisSubtitle`,
    defaultMessage:
      "Qui in sintesi i dati di energia dell'Area aziendale che hai selezionato.",
  },
  bubblesTitle: {
    id: `${scope}.bubblesTitle`,
    defaultMessage: 'Energy trend line',
  },
  bubblesSubtitle: {
    id: `${scope}.bubblesSubtitle`,
    defaultMessage:
      'Il livello di energia giorno per giorno. Il grafico è basato sulle registrazioni “Review your day” dei membri di ciascun team: giornata Top 16 punti; Buona 4, Così così -4 e Pessima -16',
  },
  emotionZeroTableTitle: {
    id: `${scope}.emotionZeroTableTitle`,
    defaultMessage: 'Main mood factors',
  },
  emotionZeroTableSubtitle: {
    id: `${scope}.emotionZeroTableSubtitle`,
    defaultMessage:
      "Qui si trova il livello dei quattro principali driver dei mood factor che influenzano in maniera macroscopica l'andamento della giornata",
  },
  emotionOneTableTitle: {
    id: `${scope}.emotionOneTableTitle`,
    defaultMessage: 'Mood factors',
  },
  emotionOneTableSubtitle: {
    id: `${scope}.emotionOneTableSubtitle`,
    defaultMessage:
      'Qui la classifica dei fattori più rilevanti per il livello di energia. La percentuale (Ripartizione) esprime la frequenza con cui ciascun fattore compare nei “Review your day” del periodo.',
  },
  contextTableTitle: {
    id: `${scope}.contextTableTitle`,
    defaultMessage: 'Contesto',
  },
  contextTableSubtitle: {
    id: `${scope}.contextTableSubtitle`,
    defaultMessage:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores',
  },
  activityTableTitle: {
    id: `${scope}.activityTableTitle`,
    defaultMessage: 'Attività',
  },
  activityTableSubtitle: {
    id: `${scope}.activityTableSubtitle`,
    defaultMessage:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores',
  },
  unitTableTitle: {
    id: `${scope}.unitTableTitle`,
    defaultMessage: 'Dettaglio per unità',
  },
  unitTableSubtitle: {
    id: `${scope}.unitTableSubtitle`,
    defaultMessage:
      'La lista presenta alcune caratteristiche di ogni unità: la percentuale di energia positiva, il trend e la percentuale di variazione, il grado di varianza (dispersione) interno all’unità.',
  },
  groupsBubblesTitle: {
    id: `${scope}.groupsBubblesTitle`,
    defaultMessage: 'Lorem ipsum',
  },
  groupsBubblesSubtitle: {
    id: `${scope}.groupsBubblesSubtitle`,
    defaultMessage:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores',
  },
  peopleTableTitle: {
    id: `${scope}.peopleTableTitle`,
    defaultMessage: 'Lorem ipsum',
  },
  peopleTableSubtitle: {
    id: `${scope}.peopleTableSubtitle`,
    defaultMessage:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores',
  },
});
