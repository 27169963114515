/*
 * MagicLink Messages
 *
 * This contains all the text for the MagicLink container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MargicLink';

export default defineMessages({
  openWithApp: {
    id: `${scope}.openWithApp`,
    defaultMessage:
      "Per proseguire, apri questo link dal tuo smartphone con l'app di Yumi",
  },
  download: {
    id: `${scope}.download`,
    defaultMessage: "Scarica adesso l'app di Yumi",
  },
  openButton: {
    id: `${scope}.openButton`,
    defaultMessage: 'Apri Yumi',
  },
});
