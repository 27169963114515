const unit = 2;

export const XSMALL = unit * 1;
export const SMALL = unit * 2;
export const REGULAR = unit * 4;
export const MEDIUM = unit * 6;
export const LARGE = unit * 8;
export const XLARGE = unit * 10;
export const XXLARGE = unit * 12;
export const XXXLARGE = unit * 14;
export const XXXXLARGE = unit * 16;
export const XXXXXLARGE = unit * 18;
export const XXXXXXLARGE = unit * 20;
