import styled from 'styled-components';

// Constants
import * as Margins from 'constants/margins';

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: ${Margins.XXLARGE}px;
  line-height: 32px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: 'Muli-Bold';
`;

export const Paragraph = styled.div`
  font-size: 16px;
  margin-bottom: ${Margins.SMALL}px;
  margin-top: 56px;
`;

export const Link = styled.a`
  color: #ffffff;
  text-decoration: underline;
`;
