/*
 * Skills Messages
 *
 * This contains all the text for the Skills container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Skills';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Skills',
  },
  organizationTab: {
    id: `global.tabs.organization`,
    defaultMessage: 'Organizzazione',
  },
  groupsTab: {
    id: `global.tabs.groups`,
    defaultMessage: 'Gruppi',
  },
  heatmapTab: {
    id: `global.tabs.heatmap`,
    defaultMessage: 'Heatmap',
  },
  observedTab: {
    id: `global.tabs.observed`,
    defaultMessage: 'Osservati',
  },
  suggestedTab: {
    id: `global.tabs.suggested`,
    defaultMessage: 'Suggeriti',
  },
  kpisTitle: {
    id: `${scope}.kpisTitle`,
    defaultMessage: 'Skills snapshot',
  },
  kpisSubtitle: {
    id: `${scope}.kpisSubtitle`,
    defaultMessage:
      "Qui in sintesi i dati di comportamenti e competenze dell'Area aziendale che hai selezionato. ",
  },
  bubblesTitle: {
    id: `${scope}.bubblesTitle`,
    defaultMessage: 'Skills matrix',
  },
  bubblesSubtitle: {
    id: `${scope}.bubblesSubtitle`,
    defaultMessage:
      'La matrice posiziona le unità presenti nell’area aziendale che hai selezionato rispetto ai comportamenti osservati. A sinistra le unità che raccolgono meno osservazioni e a destra quelle che ne raccolgono di più.',
  },
  bubblesSuggestedTitle: {
    id: `${scope}.bubblesSuggestedTitle`,
    defaultMessage: 'Skills matrix',
  },
  bubblesSuggestedSubtitle: {
    id: `${scope}.bubblesSuggestedSubtitle`,
    defaultMessage:
      'La matrice posiziona le unità presenti nell’area aziendale che hai selezionato rispetto ai comportamenti suggeriti. A sinistra le unità che raccolgono meno suggerimenti e a destra quelle che ne raccolgono di più.',
  },
  categoriesTableTitle: {
    id: `${scope}.categoriesTableTitle`,
    defaultMessage: 'Dettaglio Skills',
  },
  categoriesTableSubtitle: {
    id: `${scope}.categoriesTableSubtitle`,
    defaultMessage:
      'La tabella riporta per ogni Skill la percentuale di osservazioni registrate nell’area aziendale selezionata.',
  },

  categoriesTableSuggestedTitle: {
    id: `${scope}.categoriesTableSuggestedTitle`,
    defaultMessage: 'Dettaglio Skills',
  },
  categoriesTableSuggestedSubtitle: {
    id: `${scope}.categoriesTableSuggestedSubtitle`,
    defaultMessage:
      'La tabella riporta per ogni Skill la percentuale di suggerimenti registrati nell’area aziendale selezionata.',
  },

  behaviorTableTitle: {
    id: `${scope}.behaviorTableSuggestedTitle`,
    defaultMessage: 'Dettaglio Comportamenti',
  },
  behaviorTableSubtitle: {
    id: `${scope}.behaviorTableSuggestedSubtitle`,
    defaultMessage: 'Qui a fianco i comportamenti osservati.',
  },

  behaviorTableSuggestedTitle: {
    id: `${scope}.behaviorTableSuggestedTitle`,
    defaultMessage: 'Dettaglio Comportamenti',
  },
  behaviorTableSuggestedSubtitle: {
    id: `${scope}.behaviorTableSuggestedSubtitle`,
    defaultMessage: 'Qui a fianco i comportamenti suggeriti.',
  },

  unitsTableTitle: {
    id: `${scope}.unitsTableTitle`,
    defaultMessage: 'Dettaglio per unità',
  },
  unitsTableSubtitle: {
    id: `${scope}.unitsTableSubtitle`,
    defaultMessage:
      'La tabella fornisce il dettaglio delle Skill osservate di ogni unità presente nell’area aziendale selezionata.',
  },

  unitsTableSuggestedTitle: {
    id: `${scope}.unitsTableSuggestedTitle`,
    defaultMessage: 'Dettaglio per unità',
  },
  unitsTableSuggestedSubtitle: {
    id: `${scope}.unitsTableSuggestedSubtitle`,
    defaultMessage:
      'La tabella fornisce il dettaglio delle Skill suggerite di ogni unità presente nell’area aziendale selezionata.',
  },

  groupsBubblesTitle: {
    id: `${scope}.groupsBubblesTitle`,
    defaultMessage: 'Confronto con gli altri team',
  },
  groupsBubblesSubtitle: {
    id: `${scope}.groupsBubblesSubtitle`,
    defaultMessage:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores',
  },
  peopleTableTitle: {
    id: `${scope}.peopleTableTitle`,
    defaultMessage: 'Popolazione in Corporate and branding',
  },
  peopleTableSubtitle: {
    id: `${scope}.peopleTableSubtitle`,
    defaultMessage:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores',
  },
});
