import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { CommonWrapper, ImportIcon, ExportIcon } from './styles';
import HeaderIcon from 'components/HeaderIcon';

// Translations
import messages from '../messages';

// Style
const IconsWrapper = styled(CommonWrapper)`
  justify-content: flex-end;
`;

function OrganizationHeader({ pathname }) {
  return (
    <IconsWrapper>
      <HeaderIcon
        icon={<ImportIcon />}
        label={messages.import}
        onClick={() => console.log('import', pathname)}
      />
      <HeaderIcon
        icon={<ExportIcon />}
        label={messages.export}
        onClick={() => console.log('export', pathname)}
      />
    </IconsWrapper>
  );
}

OrganizationHeader.propTypes = {
  pathname: PropTypes.string,
};

export default OrganizationHeader;
