/*
 * ViralSharing Messages
 *
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ViralSharing';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: ' vuole collaborare con te su Yumi',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage:
      "Yumi è l'app che ti aiuta a capire meglio il tuo impatto al lavoro",
  },
  verifyText: {
    id: `${scope}.verifyText`,
    defaultMessage:
      'Verifica il tuo indirizzo email per cominciare ad usare Yumi',
  },
  verifyAddress: {
    id: `${scope}.verifyAddress`,
    defaultMessage: 'Verifica indirizzo',
  },
  disclaimer: {
    id: `${scope}.disclaimer`,
    defaultMessage:
      'Procedendo autorizzi Feedback Loop Srl al trattamento dei tuoi dati come indicato nella {privacyLink}',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'informativa sulla privacy',
  },
  inviteSuccessTitle: {
    id: `${scope}.inviteSuccessTitle`,
    defaultMessage:
      'Unisciti a {name} ed altri {members} colleghi {tenantName} su Yumi',
  },
  inviteSuccessSubtitle: {
    id: `${scope}.inviteSuccessSubtitle`,
    defaultMessage:
      "Abbiamo inviato un'email di attivazione all'indirizzo {email}",
  },
  inviteSuccessDownload: {
    id: `${scope}.inviteSuccessDownload`,
    defaultMessage: "Scarica adesso l'app di Yumi per cominciare",
  },
  inviteFailedTitle: {
    id: `${scope}.inviteFailedTitle`,
    defaultMessage: 'Ci dispiace il tuo indirizzo non è su Yumi.',
  },
  inviteFailedSubtitle: {
    id: `${scope}.inviteFailedSubtitle`,
    defaultMessage:
      "Verifica che l'indirizzo {email} sia corretto e che sia la tua email di lavoro",
  },
  inviteFailedRetry: {
    id: `${scope}.inviteFailedRetry`,
    defaultMessage: 'Prova un altro indirizzo',
  },
  inviteFailedSupport: {
    id: `${scope}.inviteFailedSupport`,
    defaultMessage: 'Se continui a on riuscire ad accedere contattaci a ',
  },
  genericError: {
    id: `${scope}.genericError`,
    defaultMessage: 'Errore generico',
  },
  inviteNotFoundError: {
    id: `${scope}.notFoundError`,
    defaultMessage: 'Invito non trovato',
  },
  conflictError: {
    id: `${scope}.conflictError`,
    defaultMessage: 'Utente già verificato',
  },
});
