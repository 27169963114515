import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

// Context
import AuthContext from 'containers/Authenticated/context';

// Components
import HeatmapTable from 'components/HeatmapTable';
import { ClipLoader } from 'react-spinners';
import Error from 'components/Error';

// APIs
import { fetchHeatmap } from '../api';

// Constants
import * as Colors from 'constants/colors';
import periods from 'utils/periodGenerator';

// Styles
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${props => (props.loading ? 'center' : 'flex-start')};
  min-height: 200px;
`;

function Heatmap() {
  const {
    unitId,
    period,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  } = useContext(AuthContext);

  const { start, end } = periods[period];

  // State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  // Effects
  useEffect(() => {
    let canceled = false;
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await fetchHeatmap(
          unitId,
          start,
          end,
          benchmarkOrganizationUnitId,
          benchmarkStart,
          benchmarkEnd,
        );
        const json = await response.json();
        if (!canceled) {
          setData(json);
          setLoading(false);
        }
      } catch {
        if (!canceled) {
          setLoading(false);
          setError(true);
        }
      }
    };

    fetchData();

    return () => {
      canceled = true;
    };
  }, [
    unitId,
    period,
    benchmarkOrganizationUnitId,
    benchmarkStart,
    benchmarkEnd,
  ]);

  const renderLoading = () => {
    if (loading) {
      return (
        <Wrapper loading={loading ? 'true' : undefined}>
          <ClipLoader size={20} color={Colors.LIGHT_NAVY} />
        </Wrapper>
      );
    }
    return null;
  };

  const renderError = () => {
    if (!loading && error) {
      return <Error />;
    }
    return null;
  };

  const renderHeatmap = () => {
    if (!error && !loading && data) {
      return <HeatmapTable data={data} cellWidth={90} />;
    }
    return null;
  };

  return (
    <Wrapper>
      {renderLoading()}
      {renderError()}
      {renderHeatmap()}
    </Wrapper>
  );
}

export default Heatmap;
