/**
 *
 * LineChart
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import * as Colors from 'constants/colors';

/* Imports */
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
// import am4langIT from '@amcharts/amcharts4/lang/it_IT';
import am4langEN from '@amcharts/amcharts4/lang/en';

// Style
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  & .amcharts-AmChartsLogo-group {
    visibility: hidden;
  }
`;
export default class LineChart extends Component {
  componentDidMount() {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themesAnimated);
    // Themes end

    const chart = am4core.create(
      `chartlinediv-${this.props.uuid}`,
      am4charts.XYChart,
    );

    const data = this.props.data.map(day => {
      const date = new Date(day.date);
      const { value } = day;
      return { date, value };
    });
    chart.data = data;
    chart.dateFormatter.language.locale = am4langEN;
    chart.dateFormatter.dateFormat = 'd MMMM';

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.labels.template.verticalCenter = 'top';
    dateAxis.renderer.labels.template.horizontalCenter = 'left';
    dateAxis.renderer.labels.template.fontSize = 12;
    dateAxis.renderer.labels.template.fill = Colors.BATTLESHIP_GREY;
    dateAxis.renderer.minLabelPosition = 0.05;
    dateAxis.dateFormats.setKey('day', 'd MMMM');
    dateAxis.dateFormats.setKey('month', 'MMMM');

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minGridDistance = 140;
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.labels.template.fill = Colors.BATTLESHIP_GREY;

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'date';
    series.tooltipText = '{value}%';
    series.tooltip.pointerOrientation = 'vertical';
    series.stroke = this.props.color ? this.props.color : Colors.PURPLE;
    series.tensionX = 0.8;
    series.tensionY = 1;
    series.strokeWidth = 2;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.snapToSeries = series;
    chart.cursor.xAxis = dateAxis;

    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <Container>
        <div
          id={`chartlinediv-${this.props.uuid}`}
          style={{ width: '100%', height: '500px' }}
        />
      </Container>
    );
  }
}

LineChart.propTypes = {
  data: PropTypes.array,
  color: PropTypes.string,
  uuid: PropTypes.string,
};
