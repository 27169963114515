/*
 * Table Messages
 *
 * This contains all the text for the Table component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Table';
export const sectionKpiScope = 'app.components.sectionKpi';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Table component!',
  },
  loadMore: {
    id: `${scope}.loadMore`,
    defaultMessage: 'Carica altri',
  },
  noOtherResults: {
    id: `${scope}.noOtherResult`,
    defaultMessage: 'Non ci sono altri risultati disponibili',
  },
  noResult: {
    id: `${scope}.noResult`,
    defaultMessage: 'Nessun risultato disponibile',
  },
  over: {
    id: `${sectionKpiScope}.over`,
    defaultMessage: 'Sopra il benchmark',
  },
  under: {
    id: `${sectionKpiScope}.under`,
    defaultMessage: 'Sotto il benchmark',
  },
});
