import styled from 'styled-components';

// Constants
import { DARK_SLATE_BLUE, BATTLESHIP_GREY } from 'constants/colors';

export const CommonWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 24px;
  }
`;

export const FirstRow = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
`;

export const SecondRow = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 34.5px;
  line-height: normal;
  letter-spacing: 0.25px;
  color: ${DARK_SLATE_BLUE};
`;

export const Subtitle = styled.span`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.11px;
  color: ${BATTLESHIP_GREY};
`;
