import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { CommonWrapper, FirstRow, SecondRow, Subtitle } from './CommonStyle';
import OverlappingDots from 'components/OverlappingDots';
import OriginalBenchmark from 'components/Benchmark';

// Translations
import { injectIntl } from 'react-intl';
import messages from './messages';

// Style
const Benchmark = styled(OriginalBenchmark)`
  margin-left: 8px;
`;

function Dispersion({ intl, dispersion, benchmark }) {
  return (
    <CommonWrapper data-testid="SectionKpi-Dispersion-wrapper">
      <FirstRow>
        <OverlappingDots size={32} value={dispersion} />
        {typeof benchmark === 'number' && <Benchmark value={benchmark} />}
      </FirstRow>
      <SecondRow>
        <Subtitle>{`${intl.formatMessage(
          messages.dispersion,
        )} ${dispersion}`}</Subtitle>
      </SecondRow>
    </CommonWrapper>
  );
}

Dispersion.propTypes = {
  intl: PropTypes.object,
  dispersion: PropTypes.number,
  benchmark: PropTypes.number,
};

export default injectIntl(Dispersion);
